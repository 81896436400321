import React from "react";
import { Part1, Part2, Swiper1, Swiper2,Plugin ,Swiper3} from "./ProChild";

export default class Products extends React.Component {
  componentDidMount(){
    document.title = '产品'
  }

  render() {
    return (
      <div style={{ background: "#f0f1f2" }}>
        <Part1 />
        <div style={{maxWidth:1200,margin:'0 auto'}}>
        <Part2 />
        <Swiper1 />
        <Swiper2 />
        <Swiper3/>
        <Plugin/>
        </div>
      </div>
    );
  }
}
