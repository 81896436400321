import React from "react";

const styles = {
  name: {
    fontSize: 32,
    color: "#333333",
    fontWeight: "bold",
  },
  intro: {
    fontSize: 22,
    color: "#333333",
    fontWeight: "bold",
  },
  content: {
    fontSize: 16,
    color: "#666666",
    fontWeight: 500,
  },
  card: {
    width: 366.667,
    marginRight: 30,
    background: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  btn: {
    height: 40,
    width: 150,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  pro: {
    fontSize: 28,
    color: "#333333",
    fontWeight: "bold",
    marginTop: 30,
    marginBottom: 30,
  },
};

export const Part1 = () => (
  <div
    style={{
      background: "#ffdcb5",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <div>
      <p className="title2">STEAM教育套件、灵活拓展包、创客零部件</p>
      <p className="content6">
        不同搭配组合，提供多种选择，满足每个人的无限可能
      </p>
    </div>

    <img src="/uploads/img/product-detail-head.png" style={{ width: "40%" }} />
  </div>
);
const Title = ({ img, title }) => (
  <div
    style={{
      marginTop: 100,
      display: "flex",
      alignItems: "center",
    }}
  >
    <img src={img} style={{ height: 37, width: "auto", marginRight: 20 }} />
    <p className="title1" style={{ marginTop: 10 }}>
      {title}
    </p>
  </div>
);
export const Part2 = () => (
  <div
    style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
  >
    <a id="steam-suite" name="steam-suite"></a>
    <Title img="uploads/img/product-suit-icon.png" title="STEAM教育套件" />

    <CardP
      href="83007"
      name="83007"
      intro="入门级编程教育机器人"
      content={
        <p style={styles.content}>
          易入门/一体化车架/双TT电机/APP操控/Scratch&Python双
          <br />
          语言编程/多种有趣玩法/配置课程资源/丰富拓展口
        </p>
      }
      img="/images/tiny.png"
      style={{ height: "330px" }}
    />
    <CardP
      href="83005"
      name="83005"
      intro="机器人编程教育套装"
      content={
        <p style={styles.content}>
          易搭建/功能模块化/易于教学互动/图形化编程/蓝牙遥控/拓展性强
        </p>
      }
      img="/images/pp83004.jpeg"
    />
    <CardP
      href="product"
      name="83001"
      intro="7合1超级战车套件"
      content={
        <p style={styles.content}>
          7种官方形态/14种炫酷构型/指定角度控制/丰富音频表情资源
          <br />
          图形化编程/APP操控/配套教程资源
        </p>
      }
      img="/uploads/img/suite-01.png"
    />
    {/* <CardP
      href="productdetail"
      name="83002"
      intro="简易小车"
      content={
        <p style={styles.content}>
          图形化编程/简易搭建/强劲电机/丰富扩展口/百变拓展包
        </p>
      }
      img="/uploads/img/suite-02.png"
    />
    <CardP
      href="aliendetail"
      name="83003"
      intro="4合1外星球超级战车套件"
      content={
        <p style={styles.content}>
          4种官方形态/8种炫酷构型/多种有趣玩法/图形化编程
          <br />
          APP操控/集成多种传感器
        </p>
      }
      img="/uploads/img/suite-03.png"
    /> */}
  </div>
);

const CardP = ({ name, intro, content, href, img }) => (
  <a
    href={href}
    className="box-t-shadow"
    style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      background: "#fff",
      width: "80%",
      marginTop: 20,
      cursor: "pointer",
      paddingLeft: 90,
    }}
  >
    <div style={{ textAlign: "left", paddingTop: 50 }}>
      <p style={styles.name}>{name}</p>
      <p style={styles.intro}>{intro}</p>
      {content}

      <div
        className="btn-more"
        style={{
          ...styles.btn,
          marginTop: 30,
          marginBottom: 60,
        }}
      >
        <span style={{ color: "#fff" }}>了解更多</span>
      </div>
    </div>
    <img src={img} style={{ width: "50%" }} />
  </a>
);

export class Swiper1 extends React.Component {
  componentDidMount() {
    var swiper = new window.Swiper(".swiper1", {
      slidesPerView: 3,
      spaceBetween: 30,
      slidesPerGroup: 3,
      loop: true,
      loopFillGroupWithBlank: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".next1",
        prevEl: ".prev1",
      },
    });
  }

  render() {
    return (
      <div
        style={{
          position: "relative",
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <a id="expand" name="expand"></a>
        <div
          className="modal fade"
          id="myModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <img src="uploads/img/0808方梁类/套件展示案例01.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <Title img="uploads/img/product-expand-icon.png" title="拓展包及配件" />
        <div
          className="swiper-container swiper-container-initialized swiper-container-horizontal swiper1"
          style={{ marginTop: 20, width: "85%", height: 400 }}
        >
          <div
            className="swiper-wrapper"
            style={{
              transitionDuration: "0ms",
              transform: "translate3d(-1190px, 0px, 0px)",
            }}
          >
            <div
              className="swiper-slide swiper-slide-duplicate"
              data-swiper-slide-index="3"
              style={styles.card}
            >
              Slide 10
            </div>
            <div
              className="swiper-slide swiper-slide-invisible-blank swiper-slide-duplicate"
              data-swiper-slide-index="4"
              style={styles.card}
            ></div>
            <div
              className="swiper-slide swiper-slide-invisible-blank swiper-slide-duplicate swiper-slide-prev"
              data-swiper-slide-index="5"
              style={styles.card}
            ></div>
            <div
              className="swiper-slide swiper-slide-active box-t-shadow"
              data-swiper-slide-index="0"
              style={styles.card}
              data-toggle="modal"
              data-target="#myModal"
              onClick={() => {
                window
                  .$("#myModal img")
                  .attr("src", "uploads/img/suite-04-show.png");
              }}
            >
              <CardL
                name="61001拓展包"
                img="/images/products/icon_d_61001.png"
              />
            </div>
            <div
              className="swiper-slide swiper-slide-next box-t-shadow"
              data-swiper-slide-index="1"
              style={styles.card}
              data-toggle="modal"
              data-target="#myModal"
              onClick={() => {
                window
                  .$("#myModal img")
                  .attr("src", "uploads/img/suite-05-show.png");
              }}
            >
              <CardL
                name="61002拓展包"
                img="/images/products/icon_d_61002.png"
              />
            </div>
            <a
              className="swiper-slide box-t-shadow"
              data-swiper-slide-index="2"
              style={styles.card}
              href="61004"
            >
              <CardL
                name="61004拓展包"
                img="/images/products/icon_d_61004.png"
              />
            </a>
            <a
              href="tc001"
              className="swiper-slide box-t-shadow"
              data-swiper-slide-index="3"
              style={styles.card}
            >
              <CardL name="63001蓝牙遥控手柄" img="images/pp_tc001.png" />
            </a>

            <div
              className="swiper-slide swiper-slide-invisible-blank"
              data-swiper-slide-index="4"
              style={styles.card}
            ></div>
            <div
              className="swiper-slide swiper-slide-invisible-blank swiper-slide-duplicate-prev"
              data-swiper-slide-index="5"
              style={styles.card}
            ></div>
            <div
              className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
              data-swiper-slide-index="0"
              style={styles.card}
            >
              Slide 1
            </div>
            <div
              className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next"
              data-swiper-slide-index="1"
              style={styles.card}
            >
              Slide 2
            </div>
            <div
              className="swiper-slide swiper-slide-duplicate"
              data-swiper-slide-index="2"
              style={styles.card}
            >
              Slide 3
            </div>
          </div>

          {/* <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets">
            <span
              className="swiper-pagination-bullet swiper-pagination-bullet-active"
              tabIndex="0"
              role="button"
              aria-label="Go to slide 1"
            ></span>
            <span
              className="swiper-pagination-bullet"
              tabIndex="0"
              role="button"
              aria-label="Go to slide 2"
            ></span>
            <span
              className="swiper-pagination-bullet"
              tabIndex="0"
              role="button"
              aria-label="Go to slide 3"
            ></span>
            <span
              className="swiper-pagination-bullet"
              tabIndex="0"
              role="button"
              aria-label="Go to slide 4"
            ></span>
          </div>

          <span
            className="swiper-notification"
            aria-live="assertive"
            aria-atomic="true"
          ></span> */}
        </div>

        <div
          style={{
            background: "url(/images/dog/swiper_right.png)",
            backgroundSize: "100%",
            height: 86,
            width: 54,
            top: "60%",
          }}
          className="swiper-button-next next1"
          tabIndex="0"
          role="button"
          aria-label="Next slide"
        ></div>
        <div
          style={{
            background: "url(/images/dog/swiper_left.png)",
            backgroundSize: "100%",
            height: 86,
            width: 54,
            top: "60%",
          }}
          className="swiper-button-prev prev1"
          tabIndex="0"
          role="button"
          aria-label="Previous slide"
        ></div>
      </div>
    );
  }
}

export class Swiper2 extends React.Component {
  componentDidMount() {
    var swiper = new window.Swiper(".swiper2", {
      slidesPerView: 3,
      spaceBetween: 30,
      slidesPerGroup: 3,
      loop: true,
      loopFillGroupWithBlank: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".next2",
        prevEl: ".prev2",
      },
    });
  }

  render() {
    return (
      <div
        style={{
          position: "relative",
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <a id="danping" name="danping"></a>
        <Title img="images/products/ic_product.png" title="特色单品" />
        <div
          className="swiper-container swiper-container-initialized swiper-container-horizontal swiper2"
          style={{ marginTop: 20, width: "85%", height: 400 }}
        >
          <div
            className="swiper-wrapper"
            style={{
              transitionDuration: "0ms",
              transform: "translate3d(-1190px, 0px, 0px)",
            }}
          >
            <div
              className="swiper-slide swiper-slide-duplicate"
              data-swiper-slide-index="3"
              style={styles.card}
            >
              Slide 10
            </div>
            <div
              className="swiper-slide swiper-slide-invisible-blank swiper-slide-duplicate"
              data-swiper-slide-index="4"
              style={styles.card}
            ></div>
            <div
              className="swiper-slide swiper-slide-invisible-blank swiper-slide-duplicate swiper-slide-prev"
              data-swiper-slide-index="5"
              style={styles.card}
            ></div>
            <a
              href="dog"
              className="swiper-slide swiper-slide-active box-t-shadow"
              data-swiper-slide-index="0"
              style={styles.card}
            >
              <CardA
                no="240016"
                name="宠物狗贝旺"
                img="/images/products/animal1.png"
              />
            </a>
            <a
              href="lizard"
              className="swiper-slide swiper-slide-next box-t-shadow"
              data-swiper-slide-index="1"
              style={styles.card}
            >
              <CardA
                no="240017"
                name="短跑健将蜥蜴"
                img="/images/products/animal2.png"
              />
            </a>
            <a
              href="bat"
              className="swiper-slide box-t-shadow"
              data-swiper-slide-index="2"
              style={styles.card}
            >
              <CardA
                no="240018"
                name="振翅蝙蝠"
                img="/images/products/animal3.png"
              />
            </a>
            <a
              href="motor"
              className="swiper-slide box-t-shadow"
              data-swiper-slide-index="3"
              style={styles.card}
            >
              <CardA
                no="270001"
                name="金属拼装机车"
                img="/images/products/animal4.png"
              />
            </a>

            <div
              className="swiper-slide swiper-slide-invisible-blank"
              data-swiper-slide-index="4"
              style={styles.card}
            ></div>
            <div
              className="swiper-slide swiper-slide-invisible-blank swiper-slide-duplicate-prev"
              data-swiper-slide-index="5"
              style={styles.card}
            ></div>
            <div
              className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
              data-swiper-slide-index="0"
              style={styles.card}
            >
              Slide 1
            </div>
            <div
              className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next"
              data-swiper-slide-index="1"
              style={styles.card}
            >
              Slide 2
            </div>
            <div
              className="swiper-slide swiper-slide-duplicate"
              data-swiper-slide-index="2"
              style={styles.card}
            >
              Slide 3
            </div>
          </div>
        </div>

        <div
          style={{
            background: "url(/images/dog/swiper_right.png)",
            backgroundSize: "100%",
            height: 86,
            width: 54,
            top: "60%",
          }}
          className="swiper-button-next next2"
          tabIndex="0"
          role="button"
          aria-label="Next slide"
        ></div>
        <div
          style={{
            background: "url(/images/dog/swiper_left.png)",
            backgroundSize: "100%",
            height: 86,
            width: 54,
            top: "60%",
          }}
          className="swiper-button-prev prev2"
          tabIndex="0"
          role="button"
          aria-label="Previous slide"
        ></div>
      </div>
    );
  }
}

export class Swiper3 extends React.Component {
  componentDidMount() {
    var swiper = new window.Swiper(".swiper3", {
      slidesPerView: 3,
      spaceBetween: 30,
      slidesPerGroup: 3,
      loop: true,
      loopFillGroupWithBlank: false,
      allowTouchMove: false,
      pagination: {
        el: ".swiper-pagination",
        clickable: false,
      },
      navigation: {
        nextEl: ".next3",
        prevEl: ".prev3",
      },
    });
  }

  render() {
    return (
      <div
        style={{
          position: "relative",
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <a id="raceing" name="raceing"></a>
        <Title img="images/products/ic_product.png" title="比赛产品" />
        <div
          className="swiper-container swiper-container-initialized swiper-container-horizontal swiper3"
          style={{ marginTop: 20, width: "85%", height: 400 }}
        >
          <div
            className="swiper-wrapper"
            style={{
              transitionDuration: "0ms",
              transform: "translate3d(-1190px, 0px, 0px)",
            }}
          >
            <a
              href="62007"
              className="swiper-slide swiper-slide-active box-t-shadow"
              data-swiper-slide-index="0"
              style={styles.card}
            >
              <CardA
                no="62007场地包"
                name="RA-极限冰壶挑战赛"
                img="/images/place/ic_race3_replace.png"
              />
            </a>
            <a
              href="85001"
              className="swiper-slide swiper-slide-next box-t-shadow"
              data-swiper-slide-index="1"
              style={styles.card}
            >
              <CardA
                no="85001器材包"
                name="RA-极限冰壶挑战赛"
                img="/images/place/ic_race2.png"
              />
            </a>
            <a
              href="62004"
              className="swiper-slide box-t-shadow"
              data-swiper-slide-index="2"
              style={styles.card}
            >
              <CardA
                no="62004场地包"
                name="“RA - 拆弹专家”挑战赛"
                title="了解更多"
                img="/images/place/62004.png"
              />
            </a>
            <a
              href="85003"
              className="swiper-slide box-t-shadow"
              data-swiper-slide-index="3"
              style={styles.card}
            >
              <CardA
                no="85003器材包"
                name="“RA - 拆弹专家”挑战赛"
                title="了解更多"
                img="/images/place/85003.png"
              />
            </a>
            <a
              href="62005"
              className="swiper-slide box-t-shadow"
              data-swiper-slide-index="4"
              style={styles.card}
            >
              <CardA
                no="62005场地包"
                name="“RA - 极智速滑”挑战赛"
                title="了解更多"
                img="/images/place/62005.png"
              />
            </a>
            <a
              href="85004"
              className="swiper-slide box-t-shadow"
              data-swiper-slide-index="5"
              style={styles.card}
            >
              <CardA
                no="85004器材包"
                name="“RA - 极智速滑”挑战赛"
                title="了解更多"
                img="/images/place/85004.png"
              />
            </a>

          </div>
        </div>

        <div
          style={{
            background: "url(/images/dog/swiper_right.png)",
            backgroundSize: "100%",
            height: 86,
            width: 54,
            top: "60%",
          }}
          className="swiper-button-next next3"
          tabIndex="0"
          role="button"
          aria-label="Next slide"
        ></div>
        <div
          style={{
            background: "url(/images/dog/swiper_left.png)",
            backgroundSize: "100%",
            height: 86,
            width: 54,
            top: "60%",
          }}
          className="swiper-button-prev prev3"
          tabIndex="0"
          role="button"
          aria-label="Previous slide"
        ></div>
      </div>
    );
  }
}

const CardL = ({ name, img }) => (
  <div style={{ width: "80%", cursor: "pointer" }}>
    <img src={img} />
    <p style={styles.pro}>{name}</p>
    <div className="btn-more" style={styles.btn}>
      <span style={{ color: "#FFF" }}>了解更多</span>
    </div>
  </div>
);

const CardA = ({ name, img, no, title }) => {
  return (
    <div
      style={{
        width: "80%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <img src={img} style={{ height: 160, width: "auto" }} />
      <div>
        <div style={styles.pro}>
          {no}
          <br />
          {name ? <p style={styles.intro}>{name}</p> : null}
        </div>
        <div className="btn-more" style={styles.btn}>
          <span style={{ color: "#FFF" }}>{title ? title : "了解更多"}</span>
        </div>
      </div>
    </div>
  );
};

export const Plugin = () => (
  <div
    style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
  >
    <a id="spare-parts" name="spare-parts"></a>
    <Title img="images/products/ic_fitting.png" title="创客零部件" />
    <p
      style={{
        fontSize: 20,
        fontWeight: "bold",
        color: "#333333",
        marginLeft: 60,
      }}
    >
      创意不设限 做你所想
    </p>
    <a
      href="parts"
      style={{
        width: "70%",
        marginTop: 50,
        marginBottom: 50,
        cursor: "pointer",
      }}
      className="box-t-shadow"
    >
      <img src="images/products/pro_plu.png"></img>
    </a>
    <p
      style={{
        fontSize: 20,
        fontWeight: "bold",
        color: "#333333",
        marginBottom: 100,
      }}
    >
      系列化分类 高品质铝合金材质 标准化、通用化设计
    </p>
  </div>
);
