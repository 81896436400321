import React from "react";
import { Modal, Form, Input, Button, Checkbox, Cascader } from "antd";
import cities from "./cities";
const tailLayout = {
  wrapperCol: { offset: 10, span: 16 },
};

const Feedback = ({
  visible,
  onFinish,
  title = "填写信息，零件清单会自动发送给客服，获取专业服务",
}) => {
  let addr = "";
  return (
    <Modal
      centered
      maskClosable={false}
      visible={visible}
      footer={null}
      onCancel={onFinish}
      title={title}
    >
      <Form
        name="basic"
        layout={"vertical"}
        initialValues={{ remember: true }}
        onFinish={(data) => {
          data.address = `${addr}-${data.address}`;
          onFinish(data);
        }}
      >
        <Form.Item
          label="联系人姓名"
          name="name"
          rules={[{ required: true, message: "请输入姓名！" }]}
        >
          <Input className="feedback-input" />
        </Form.Item>

        <Form.Item
          label="联系电话"
          name="phone"
          rules={[{ required: true, message: "请输入手机号！" }]}
        >
          <Input className="feedback-input" type="number" />
        </Form.Item>
        <Form.Item label="地址">
          <Cascader
            className="feedback-ca"
            placeholder="请选择地址"
            onChange={(city) => {
              addr = city.join("-");
            }}
            options={cities}
          />
        </Form.Item>
        <Form.Item name="address" rules={[{ message: "请输入地址！" }]}>
          <Input className="feedback-input" />
        </Form.Item>

        <Form.Item label="合作意向" name="remember" valuePropName="checked">
          <Checkbox className="ant-check" checked={true}>
            获取报价
          </Checkbox>
        </Form.Item>
        <Form.Item label="其他" name="other">
          <Input className="feedback-input" />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button
            className={"btn-white"}
            type="primary"
            htmlType="submit"
            size="large"
            style={{ background: "#FE9D00", borderWidth: 0 }}
          >
            提交
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Feedback;
