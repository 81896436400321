import React, { Component } from "react";
import { BtnShaw } from "../dog/DogChild";

const styles = {
  title: {
    color: "#2A2A2A",
    fontSize: 36,
  },
  text: {
    color: "#2A2A2A",
    fontSize: 24,
  },
  titleW: {
    color: "#fff",
    fontSize: 36,
  },
  textW: {
    color: "#fff",
    fontSize: 24,
  },
};
export default class RacePlace extends Component {
  render() {
    return (
      <div>
        <div className="parter">
          <div className="partC" style={{ justifyContent: "center" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "10%",
              }}
            >
              <img
                src="/images/place/85001banner.png"
                style={{ width: "40%" }}
              />
              <div
                style={{
                  width: 33,
                  height: 4,
                  background: "#F3981B",
                  marginBottom: 20,
                  marginTop: 20,
                }}
              ></div>

              <p
                style={{
                  fontSize: 24,
                  color: "white",
                  width: "40%",
                }}
              >
                85001器材包支持每支参赛队伍搭建比赛所需的2台自由投掷机器人；其拥有种类丰富的的零部件，数量充足，孩子们可创造出多种搭建方案的机器人。
              </p>
            </div>
          </div>
          <img src="/images/place/bg_qicai1.png"></img>
        </div>
        <div
          className="parter"
          style={{
            backgroundSize: "100% 100%",
            background: "url(images/place/bg_place2.png)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p style={{ ...styles.title, marginTop: 80 }}>85001器材包有什么？</p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={"/images/place/qicai1.png"} style={{ width: "45%" }} />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p style={styles.text}>
                900+个涉及梁类、齿轮类、紧固类、功能类、轴类等thunbot零件；
              </p>
              <p style={styles.text}> 2个蓝牙遥控手柄；</p>
              <p style={styles.text}>
                2个TBot-I主控、1个彩色传感器、1个触碰传感器、1个显示屏；
              </p>
              <p style={styles.text}> 4个电机、4个舵机；</p>
              <p style={styles.text}>2把六角扳手和3个不同规格的L型扳手；</p>

              <a
                target="_blank"
                href="https://docs.thunder.bellrobot.com/thunderInternetResource/document/85001《RA-极限冰壶》器材包零件清单.pdf"
                className="btn-h"
                style={{
                  height: 57,
                  paddingLeft: 33,
                  paddingRight: 33,
                  background: "#FF9C00",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "60%",
                  marginTop: 30,
                }}
              >
                <span style={styles.textW}>85001器材包零件清单</span>
              </a>
            </div>
          </div>
        </div>
        <div className="parter">
          <div
            className="partC"
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <p style={{ ...styles.titleW, marginTop: 80 }}>
              85001器材包能做什么？
            </p>
            <div
              style={{
                width: 370,
                height: 2,
                background: "#6D6F73",
                marginTop: 30,
                marginBottom: 30,
              }}
            ></div>
            <p style={{ ...styles.textW, width: 375 }}>
              85001 器材包支持每支参赛队伍搭建比赛所需的 2
              台自由投掷机器人；其拥有种类丰富的零
              部件，数量充足，孩子们可创造出多种搭建方案的机器人。
            </p>
          </div>
          <img src="/images/place/bg850012.png"></img>
        </div>
        <div className="parter">
          <div className="partC" style={{ alignItems: "center" }}>
            <span style={{ marginTop: 80, ...styles.titleW }}>课程服务</span>
            <img
              style={{ width: "60%", marginTop: 50 }}
              src="/images/place/img2.png"
            ></img>
            <img
              style={{ width: "60%", marginTop: 70 }}
              src="/images/place/img1.png"
            ></img>
          </div>
          <img src="/images/place/bg850013.png"></img>
        </div>

        <div style={{ textAlign: "center", paddingTop: 80, paddingBottom: 80 }}>
          <p style={styles.title}>相关推荐</p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <BtnShaw
              src="/images/place/ic1.png"
              imgStyle={{ height: 176, width: "auto" }}
              name="62007"
              desc={<div>“RA-极限冰壶” 挑战赛<br/>场地包</div>}
              href="62007"
            />
            {/* <div style={{ width: 80 }}></div>
            <BtnShaw
              src="/images/place/85002.png"
              imgStyle={{ height: 176, width: "auto" }}
              name="85002"
              href="85002"
              desc="2020 WRC AI探索器材包"
              down_text="了解详情"
            /> */}
          </div>
        </div>
      </div>
    );
  }
}
