import React, { Component } from "react";
import {
  Part1,
  Part2,
  Part3,
  Part4,
  Part5,
  Part6,
  Part7,
  Part8,
  Part9,
  Part10,
  Part11
} from "./DogChild";

export default class BellDog extends Component {
  componentDidMount(){
    document.title = '240016贝旺'
  }
  render() {
    return (
      <div
        style={{
          position: "relative",
          overflow: "hidden"
        }}
      >
        <Part1 />
        <Part2 />
        <Part3 />
        <Part4 />
        <Part5 />
        <Part6 />
        <Part7 />
        <Part8 />
        <Part9 type="dog"/>
        <Part10 />
        <Part11 type="dog" />
      </div>
    );
  }
}
