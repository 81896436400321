import React from "react";
import DialogContainer from "./DialogContainer";

const styles = {
  bat1: {
    position: "absolute",
    right: "15%",
    width: "30%",
    bottom: 20
  },
  title: {
    fontSize: 36,
    color: "#333",
    fontWeight: 500
  },
  title2: {
    fontSize: 24,
    color: "#333"
  },
  content: {
    fontSize: 18,
    color: "#666"
  },
  enter: {
    height: 60,
    width: 100,
    cursor: "pointer"
  }
};

const Bat1 = () => (
  <div style={{ position: "relative" }}>
    <img src="/images/bat/bg_bat1.png"></img>
    <img src="/images/bat/img_bat1.png" style={styles.bat1} />
  </div>
);
const Bat2 = () => (
  <div
    style={{
      background: "url(images/bat/bg_bat2.png) center no-repeat",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: 60
    }}
  >
    <img src="/images/bat/chenbao.png" style={{ width: "30%" }}></img>
    <div style={{ display: "flex", flexDirection: "column", width: "30%" }}>
      <p style={{ ...styles.title, color: "#FFF" }}>人们对自然界蝙蝠的偏见</p>
      <p
        style={{
          ...styles.content,
          color: "#fff",
          textAlign: "justify"
        }}
      >
        对于蝙蝠，各国的人们有不同的传说，有人说它代表“福分”，也有人说它是恶魔的化身，然而
        对于蝙蝠来说，这些外来的评价并不重要，它真正的生活，还需要我们走近之后才能探知。
      </p>
    </div>
  </div>
);

const Bat3 = () => (
  <div
    style={{
      position: "relative",
      height: 650,
      maxWidth: 1200,
      margin: "0 auto"
    }}
  >
    <img src="/images/bat/bg_bat3.png" style={{ width: "30%" }}></img>
    <div style={{ position: "absolute", top: 150, left: 150 }}>
      <p style={styles.title}>来自一只机械蝙蝠的自我陈述</p>
      <div style={{ marginTop: 150, marginLeft: 100 }}>
        <p style={styles.title}>自我介绍</p>
        <p style={styles.content}>
          名称：振翅蝙蝠
          <br></br>
          身高：26cm
          <br></br> 体重：0.7kg
          <br></br>特点：萌
          <br></br>
          功能：超声波定位
        </p>
      </div>
    </div>

    <div style={{ width: "35%", position: "absolute", right: 0, top: 0 }}>
      <img src="/images/bat/img_bat31.png" style={{ marginTop: 20 }}></img>
      <img
        src="/images/bat/img_bat32.png"
        style={{ height: 600, width: "auto", position: "absolute", right: 100 }}
      ></img>
    </div>
  </div>
);

class Bat4 extends React.Component {
  state = {
    tab: 0
  };

  leave = () => {
    this.setState({ tab: 0 });
  };

  enter = tab => {
    this.setState({ tab });
  };
  render() {
    const { tab } = this.state;
    return (
      <div
        style={{
          background: "url(images/bat/bat_bg4.png) center no-repeat",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          paddingTop: 60,
          paddingBottom: 60
        }}
      >
        <p style={{ ...styles.title, color: "#fff" }}>自我剖析</p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "relative"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between"
            }}
          >
            <img
              src="/images/dog/plugin1.png"
              style={{
                width: 130,
                visibility: tab === 1 ? "visible" : "hidden"
              }}
            />
            <img
              src="/images/dog/plugin8.png"
              style={{
                width: 130,
                visibility: tab === 2 ? "visible" : "hidden"
              }}
            />
            <img
              src="/images/dog/plugin1.png"
              style={{
                width: 130,
                visibility: tab === 3 ? "visible" : "hidden"
              }}
            />
            <img
              src="/images/dog/plugin10.png"
              style={{
                width: 130,
                visibility: tab === 4 ? "visible" : "hidden"
              }}
            />
          </div>
          <div
            style={{
              position: "absolute",
              width: 100,
              height: "100%",
              left: "20%"
            }}
          >
            <div
              style={{ ...styles.enter, marginTop: "140%" }}
              onMouseLeave={this.leave}
              onMouseEnter={() => this.enter(2)}
            ></div>

            <div
              style={{ ...styles.enter, marginTop: "90%" }}
              onMouseLeave={this.leave}
              onMouseEnter={() => this.enter(3)}
            ></div>
            <div
              style={{ ...styles.enter, marginTop: "125%" }}
              onMouseLeave={this.leave}
              onMouseEnter={() => this.enter(4)}
            ></div>
          </div>

         <div style={{ width: "60%" }}>
         <img src="/images/bat/bat_dot.png"></img>
         </div>
          <div
            style={{
              position: "absolute",
              width: 100,
              height: "100%",
              right: "18%"
            }}
          >
            <div
              style={{ ...styles.enter }}
              onMouseLeave={this.leave}
              onMouseEnter={() => this.enter(5)}
            ></div>

            <div
              style={{ ...styles.enter, marginTop: "320%" }}
              onMouseLeave={this.leave}
              onMouseEnter={() => this.enter(8)}
            ></div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between"
            }}
          >
            <img
              src="/images/dog/plugin11.png"
              style={{
                width: 130,
                visibility: tab === 5 ? "visible" : "hidden"
              }}
            />
            <img
              src="/images/dog/plugin1.png"
              style={{
                width: 130,
                visibility: tab === 6 ? "visible" : "hidden"
              }}
            />
            <img
              src="/images/dog/plugin3.png"
              style={{
                width: 130,
                visibility: tab === 7 ? "visible" : "hidden"
              }}
            />
            <img
              src="/images/dog/plugin4.png"
              style={{
                width: 130,
                visibility: tab === 8 ? "visible" : "hidden"
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const Bat5 = () => (
  <div style={{ background: "#ECEBE9", paddingTop: 80, paddingBottom: 80 }}>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        maxWidth: 1200,
        margin: "0 auto"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          marginRight: 20,
          flex:1
        }}
      >
        <img
          className="btn-big"
          src="/images/bat/img_bat41.png"
          style={{ width: "60%",flexShrink:0}}
        ></img>
    <img
          className="btn-big"
          src="/images/bat/img_bat42.png"
          style={{ width: "50%", marginTop: 20,flexShrink:0 }}
        ></img>
      
      </div>
      <div style={{flex:1,display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
        <p style={styles.title}>自我展示</p>
        <img
          className="btn-big"
          src="/images/bat/img_bat43.png"
          style={{ width: "60%",flexShrink:0 }}
        ></img>
        <div style={{ display: "flex", marginTop: 20 }}>
          <div   style={{ width: "45%"}}>
          <img
            className="btn-big"
            src="/images/bat/img_bat44.png"
          
          ></img>
          </div>
          <div  style={{ width: "30%", marginLeft: 20 }}>
            <img
              className="btn-big"
              src="/images/bat/img_bat45.png"
             
            ></img>
          </div>
        </div>
      </div>
    </div>
  </div>
);

class Bat6 extends React.Component {
  state = {
    visible: false
  };

  close = () => {
    this.setState({
      visible: false
    });
  };
  open = () => {
    this.setState({
      visible: true
    });
  };

  render() {
    const { visible } = this.state;
    return (
      <div
        style={{
          background: "url(images/bat/bg_bat6.png) center no-repeat",
          height: 600,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div onClick={this.open} className="btn-h">
          <img src={"/images/bat/bat_play.png"}></img>
        </div>
        <DialogContainer visible={visible} onDissmiss={this.close}>
          {visible?<video
            autoPlay
            controls="controls"
            width="900"
            src="//asset.bellrobot.com/thunder/document/video/240018.mp4"
          >
            your browser does not support the video tag
          </video>:null}
        </DialogContainer>
      </div>
    );
  }
}
const Bat7 = () => (
  <div style={{ background: "#ECEBE9" }}>
    <div style={{ display: "flex", maxWidth: 1200, margin: "0 auto" }}>
      <div style={{ flex: 1, textAlign: "center", paddingTop: "13%" }}>
        <p style={styles.title}>超萌外观，极富动感</p>
        <br></br>

        <p style={styles.content}>
          做为蝙蝠家族的“机械蝙蝠”一员，拥有不同于其它蝙蝠的萌萌大眼睛，
          <br></br>
          形态轻盈，极富动感，能帮助孩子克服对蝙蝠的认知偏见，促使孩子理性的
          <br></br>
          认识大自然的蝙蝠，探索自然奥秘。
        </p>
      </div>
      <img src="/images/bat/img_bat7.png" style={{ width: "40%" }}></img>
    </div>
  </div>
);

const Bat8 = () => (
  <div
    style={{
      display: "flex",
      height: 500,
      justifyContent: "space-around",
      alignItems: "center",
      maxWidth: 1200,
      margin: "0 auto"
    }}
  >
    <img src="/images/bat/img_bat8.png" style={{ width: "30%" }}></img>

    <div style={{ textAlign: "center" }}>
      <p style={styles.title}>模拟“回声定位”，助于理解科学知识与应用</p>
      <br />
      <p style={{ ...styles.content }}>
        仿生蝙蝠“回声定位”的习性特点，通过超声波传感器来探
        <br></br>
        测前方是否有障碍物，能使孩子形象并深刻的认识、理解
        <br></br>
        蝙蝠的习性特点，培养孩子的逻辑思维能力。
      </p>
    </div>
  </div>
);

const Bat9 = () => (
  <div
    style={{
      background: "#ECEBE9"
    }}
  >
    <div
      style={{
        display: "flex",
        height: 500,
        alignItems: "center",
        maxWidth: 1200,
        margin: "0 auto"
      }}
    >
      <div style={{ flex: 1, textAlign: "center" }}>
        <p style={styles.title}>搭建与设计，培养艺术素养</p>
        <br />
        <p style={{ ...styles.content }}>
          通过让孩子自己动手搭建模型，学习电子、机械知识，同时培养
          <br></br>孩子的动手能力及艺术素养。
        </p>
      </div>

      <img
        src="/images/bat/img_bat9.png"
        style={{ width: "30%", marginRight: 30 }}
      ></img>
    </div>
  </div>
);

const Bat10 = () => (
  <div
    style={{
      height: 500,
      maxWidth: 1200,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "0 auto"
    }}
  >
    <div style={{ width: "50%", marginLeft: 20 }}>
      <img src="/images/bat/img_bat10.png"></img>
    </div>
    <div
      style={{
        textAlign: "center"
      }}
    >
      <p style={styles.title}>编程让艺术更具表现力</p>
      <br />
      <p style={{ ...styles.content }}>
        除了让孩子自由发挥想象创造外，结合雷霆Thunder IDE图像
        <br></br>化编程软件，让孩子自主编程，实现心中理想的动作效果，让
        <br></br>孩子的想象力、创造力、编程能力协同进步。
      </p>
    </div>
  </div>
);

export { Bat1, Bat2, Bat3, Bat5, Bat6, Bat7, Bat8, Bat9, Bat10, Bat4 };
