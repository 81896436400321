import React, { Component } from "react";
import { BtnShaw } from "../dog/DogChild";

const styles = {
  title: {
    color: "#2A2A2A",
    fontSize: 36,
  },
  text: {
    color: "#2A2A2A",
    fontSize: 18,
  },
  titleW: {
    color: "#fff",
    fontSize: 36,
  },
  textW: {
    color: "#fff",
    fontSize: 18,
  },
};
export default class RacePlace extends Component {
  render() {
    return (
      <div>
        <div className="parter">
          <div className="partC" style={{ justifyContent: "center" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "10%",
              }}
            >
              <img
                src="/images/place/62005-banner-text.png"
                style={{ width: "40%" }}
              />
              <p
                style={{
                  fontSize: 24,
                  color: "white",
                  width: "40%",
                  marginTop: "20px",
                }}
              >
                62005比赛场地包包含2021“RA -
                极智速滑”挑战赛项目使用的比赛地图、场地道具、总计760+个雷霆零部件，可以搭建球场指示器、球门、帐篷、积雪放置器、旗台、雪橇场地策略物。
              </p>
            </div>
          </div>
          <img src="/images/place/62005-banner-bg.png"></img>
        </div>
        <div
          className="parter"
          style={{
            background: "url(images/place/85003-part2-bg.png)",
            backgroundSize: "cover",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={"/images/place/62005-part2-box.png"}
              style={{ width: "45%" }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "70px",
              }}
            >
              <p style={styles.title}>器材包有什么</p>
              <p style={styles.text}>
                760+个铝合金结构件、主要包括梁类、方梁、片材、紧固件等Thunbot零部件
              </p>
              <p style={styles.text}>10余款单排型材梁、4款双排型材梁</p>
              <p style={styles.text}>4把L型工具和1把M4扳手</p>
              <p style={styles.text}>1块EVA道具</p>
              <p style={styles.text}>1张比赛场地地图</p>

              <a
                target="_blank"
                href="http://docs.thunder.bellrobot.com/thunderInternetResource/document/62005《RA挑战赛-极智速滑》场地包零件清单.png"
                className="btn-h"
                style={{
                  height: 57,
                  paddingLeft: 33,
                  paddingRight: 33,
                  background: "#FF9C00",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "60%",
                  marginTop: 30,
                }}
              >
                <span style={styles.textW}>62005场地包零件清单</span>
              </a>
            </div>
          </div>
        </div>
        <div className="parter">
          <div className="partC" style={{ alignItems: "center" }}>
            <span style={{ margin: "80px 0", ...styles.titleW }}>
              场地包能做什么？
            </span>
            <p style={{ ...styles.textW, width: 960 }}>
              62005场地包零件主要用来搭建“RA-极智速滑”赛事专用的场地道具，包括：旗台1个、球门4个、帐篷1个、雪橇1个、球场指示器1个、积雪放置器1个。
            </p>
            <p style={{ ...styles.textW, width: 960 }}>
              借助这些道具，以及比赛规则和任务，参赛选手通过完成不同任务获得分数，最终赢得比赛。
            </p>
            <a
              target="_blank"
              href="http://docs.thunder.bellrobot.com/thunderInternetResource/document/62005《RA挑战赛-极智速滑》场地策略物搭建说明.rar"
              className="btn-h"
              style={{
                height: 57,
                paddingLeft: 33,
                paddingRight: 33,
                background: "#FF9C00",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 430,
                marginTop: 30,
              }}
            >
              <span style={styles.textW}>62005道具搭建说明</span>
            </a>
            <img
              style={{ width: "60%", marginTop: 50 }}
              src="/images/place/62005-part3-components.png"
            ></img>
            <span style={{ marginTop: "80px", ...styles.titleW }}>
              62005场地包使用场景
            </span>
            <img
              style={{ width: "60%", marginTop: 70 }}
              src="/images/place/62005-part3-playground.png"
            ></img>
          </div>
          <img src="/images/place/62005-part3-bg.png"></img>
        </div>

        <div style={{ textAlign: "center", paddingTop: 80, paddingBottom: 80 }}>
          <p style={styles.title}>相关推荐</p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <BtnShaw
              src="/images/place/62005-recommend.png"
              imgStyle={{ height: 176, width: "auto" }}
              desc={
                <div>
                  85004场地包
                  <br />
                  “RA - 极智速滑”挑战赛
                </div>
              }
              href="85004"
            />
          </div>
        </div>
      </div>
    );
  }
}
