import React, { Component } from "react";
import {
  Lizard1,
  Lizard2,
  Lizard3,
  Lizard4,
  Lizard5,
  Lizard6,
  Lizard7,
  Lizard8,
  Lizard9,
  Lizard10
} from "./LizardChild";
import { Part10, Part11, Part9 } from './DogChild'

export default class Lizard extends Component {
  componentDidMount(){
    document.title = '240017蜥蜴'
  }
  render() {
    return (
      <>
        <Lizard1 />
        <Lizard2 />
        <Lizard3 />
        <Lizard4 />
        <Lizard5 />
        <Lizard6 />
        <Lizard7 />
        <Lizard8 />
        <Lizard9 />
        <Lizard10/>
        <Part9 
        type='lizard'
        style={{background:'#ECEBE9'}}/>
        <Part10  type='lizard'/>
        <Part11 type='lizard'/>
      </>
    );
  }
}
