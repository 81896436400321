/**
 *作者：lorne
 *时间：2019/9/9
 *功能：
 */
import React from 'react'
import Head from "../83004/head";
import {TC1, TC2, TC3, TC4, TC5, TC6, TC7, TC8} from "./components";
import Base from "../Base";

class index extends Base{

    static defaultProps = {
        title:'tc001'
    }

    renderPage(){
        return <>
            <Head type={'tc001'}/>
            <TC1/>
            <TC2/>
            <TC3/>
            <TC4/>
            <TC5/>
            <TC6/>
            <TC7/>
            <TC8/>
        </>
    }
}

export default index
