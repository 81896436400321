import React, { useCallback, useState, useEffect } from "react";
import Avatar from "antd/lib/avatar";
import { Empty } from "antd";

/**
 * 头像资源加载回退处理组件。
 * 支持同步URL和异步URL数据获取方式
 * @param param0 
 * @example
 * <FallbackAvatar
    className={cs.avatar}
    src={avatar}
    // 使用Webpack的异步资源加载，使用异步的方式能避免Webpack将图片资源编译为base64数据附着在节点上
    fallbackSrc={() =>
      import(`@landings/holographicFiles/client/images/docPlaceAvatar/pic_${docType}_li_${docClass}.svg`)
    }
  />
* @example
 * <FallbackAvatar
    className={cs.avatar}
    src={avatar}
    // 同步资源加载
    fallbackSrc={require(`@landings/holographicFiles/client/images/docPlaceAvatar/pic_${docType}_li_${docClass}.svg`)}
  />
 */
export function FallbackAvatar({ fallbackSrc, src, ...props }) {
  const [fsrc, setFSrc] = useState(src);
  const [showEmpty, setShowEmpty] = useState(false);
  // 当外部数据变化时，传入的src也会变化，此时应该监听src进行设置
  useEffect(() => {
    setFSrc(src);
  }, [src]);
  const onError = useCallback(() => {
    if (!fallbackSrc) {
      setShowEmpty(true);
      return;
    }
    if (typeof fallbackSrc === "object") {
      if (fallbackSrc.__esModule) {
        setFSrc(fallbackSrc.default);
      }
    } else if (typeof fallbackSrc === "function") {
      const fsc = fallbackSrc();
      if (fsc && typeof fsc.then === "function") {
        fsc.then((res) => {
          if (typeof res === "object" && res.default) setFSrc(res.default);
          else setFSrc(res);
        });
      } else setFSrc(fsc);
    } else setFSrc(fallbackSrc);
    return false;
  }, [fallbackSrc]);
  return showEmpty ? (
    <Empty description="暂无图片" />
  ) : (
    <Avatar {...props} src={fsrc} onError={onError} />
  );
}
