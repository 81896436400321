import React from "react";
import { Menus, Content } from "./Chilid";

export default class Support extends React.Component {
  componentDidMount(){
    document.title = '支持'
  }

  render() {
    const pos = document.location.hash;
    return (
      <div style={{ background: "#f0f1f2" }}>
        <div className="support-head">
          <div className="support-head-title">
            <p>贴心、用心、专心的服务</p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: 80,
            maxWidth:1200,
            margin:'0 auto',
            marginTop: 80,
          }}
        >
          <Menus />
          <Content pos={pos} />
        </div>
      </div>
    );
  }
}
