import React,{Component} from 'react'
import { Bat1 ,Bat2, Bat3,Bat5, Bat6,Bat7, Bat8,Bat9,Bat10,Bat4} from './BatChild'
import { Part10, Part11, Part9 } from './DogChild'

export default class BatBot extends Component{
  componentDidMount(){
    document.title = '240018蝙蝠'
  }

  render(){
    return <div>
      <Bat1/>
      <Bat2/>
      <Bat3/>
      <Bat4/>
      <Bat5/>
      <Bat6/>
      <Bat7/>
      <Bat8/>
      <Bat9/>
      <Bat10/>
      <Part9 style={{background:'#ECEBE9'}}
      type='bat'/>
      <Part10   type='bat'/>
      <Part11 type='bat'/>
    </div>
  }
}