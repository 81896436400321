/**
 *作者：lorne
 *时间：2019/9/6
 *功能：
 */
import React from 'react'

const Coding = ()=>(
    <>
        <div className="thunder-title">
            <h2>图形化编程</h2>
            <p>图形化编程，降低学习难度，让低龄儿童也能轻松学习；<br/>多种声＼光＼形的编程模块自由组合，让孩子发挥想像，培养创新能力和逻辑思维能力。</p>
        </div>
        <div><img src="uploads/img/con27.png"/></div>
    </>
)

export default Coding
