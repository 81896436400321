import Excel from "exceljs";

export function exportExcel(dataSource, columns = []) {
  const workbook = new Excel.Workbook();
  workbook.creator = "bell";
  workbook.lastModifiedBy = "bell";
  workbook.created = new Date();
  workbook.modified = new Date();
  let sheet = workbook.addWorksheet("雷霆零部件清单", {
    views: [{ state: "frozen", ySplit: 1 }],
  });

  const TableHeader = columns.map((item) => {
    let width = 20;
    if (item.key === "image") {
      width = 50;
    }
    return {
      header: item.title,
      key: item.dataIndex,
      width: width,
      style: {
        alignment: { horizontal: "center", vertical: "middle" },
      },
    };
  });
  sheet.columns = TableHeader;

  const images = [];
  const promiseArray = [];
  dataSource.forEach((item, i) => {
    const src = item.image;
    if (src !== "" && src !== undefined) {
      const promise = urlToBase64(src, i);
      promiseArray.push(promise);
    }
    item.image = "";
  });

  Promise.allSettled(promiseArray)
    .then((datas) => {
      datas.forEach((data) => {
        if (data.status === "fulfilled") {
          const imageId = workbook.addImage({
            base64: data.value.base64,
            extension: "png",
          });
          images.push({ imageId, index: data.value.i });
        }
      });
    })
    .finally(() => {
      sheet.addRows(dataSource);
      images.forEach((item, i) => {
        const cell = `D${i + 2}: D${i + 2}`;
        sheet.addImage(item.imageId, cell);
      });
      // 遍历工作表中具有值的所有行
      sheet.eachRow(function (row, rowNumber) {
        if (rowNumber === 1) {
          row.height = 40;
        } else {
          row.height = 100;
          // 将单元格设置为自动换行
          sheet.getCell(`E${rowNumber}`).alignment.wrapText = true;
        }
      });
      // 通过ID获取列
      const descColum = sheet.getColumn("desc");
      descColum.width = 60;

      workbook.xlsx.writeBuffer("attachement/雷霆零部件.xlsx").then(
        (data) => {
          var blob = new Blob([data], {
            type: "application/octet-stream",
          });
          openDownloadDialog(blob, "雷霆零部件.xlsx");
        },
        function (err) {
          console.log(err);
        }
      );
    });
}

function openDownloadDialog(url, saveName) {
  if (typeof url == "object" && url instanceof Blob) {
    url = URL.createObjectURL(url); // 创建blob地址
  }
  var aLink = document.createElement("a");
  aLink.href = url;
  aLink.download = saveName || ""; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
  var event;
  if (window.MouseEvent) event = new MouseEvent("click");
  else {
    event = document.createEvent("MouseEvents");
    event.initMouseEvent(
      "click",
      true,
      false,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    );
  }
  aLink.dispatchEvent(event);
}

function urlToBase64(imgLink, i) {
  return new Promise((resolve, reject) => {
    const tempImage = new Image();
    tempImage.crossOrigin = "*";
    tempImage.onload = function () {
      const base64 = getBase64Image(tempImage);
      resolve({ base64, i });
    };
    tempImage.onerror = reject;
    tempImage.src = imgLink;
  });
}

function getBase64Image(img) {
  var canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  var ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, img.width, img.height);
  var ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
  var dataURL = canvas.toDataURL("image/" + ext);
  return dataURL;
}
