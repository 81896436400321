/**
 *作者：lorne
 *时间：2019/9/7
 *功能：
 */
import React, {Fragment} from 'react'
import Head from "../83004/head";
import {Product631, Product632, Product633,Product634,Product635} from "./components";
import Carousel from "../83004/Carousel";
import Base from "../Base";

export default class index extends Base{

    static defaultProps = {
        title:'61004套装'
    }

    renderPage(){
        return <Fragment>
            <Head type={'61004'}/>
            <Product631/>
            <Carousel type={'cars_61004'}/>
            <Product632/>
            <Product633/>
            <Product634/>
            <Product635/>

        </Fragment>
    }
}
