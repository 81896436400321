/**
 *作者：lorne
 *时间：2019/9/6
 *功能：
 */
import React, { Component } from "react";
import "../../css/home.css";

const Feature = ({
  items = [
    {
      title: "易用性",
      label: `过于碎片化的螺丝、螺母类零件较少，降低搭建难度；涉及零件种类多，能满足不同场景、不同应用的需求。`,
    },
    {
      title: "扩展性",
      label: `兼容雷霆系列零件平台的机械、电子模块；组合不同功能模块，可扩展搭建更多产品形态。`,
    },
    {
      title: "教育性",
      label: `配备丰富的教学资源，让教师的”教学“更易开展，从而让机器人更好的融入课堂；创新项目式教学，让学生在学习中更好的发现问题-探究问题-解决问题。`,
    },
    {
      title: "编程简单",
      label: `基于scratch的图形化编程，编程学习就像搭积木一样简单。`,
    },
    {
      title: "蓝牙操控",
      label: `支持无线蓝牙连接雷霆蓝牙遥控手柄，通过编程自定义手柄的按键功能，实现对机器人的操控。`,
    },
    {
      title: "性价比高",
      label:
        "声光互动机器人经济实惠，但教学内容丰富，支持拓展各种构型，是一款性价比较高的产品。",
    },
  ],
}) => (
  <div className="container paddingT50">
    <div className="row">
      <div className="col-sm-offset-1 col-sm-10">
        {items.map((item, i) => (
          <div className="col-sm-4" key={`feature${i}`}>
            <div className={"flex-col-h-center"}>
              <div className="card-f">
                <div>
                  <img src={`images/ic_${i + 1}.png`} />
                </div>

                <span className="s-title46">{item.title}</span>
              </div>

              <p className="p-txt46">{item.label}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

class Module extends Component {
  componentDidMount() {
    let $ = window.$;

    let mySwiper = new window.Swiper(".swiper-container", {
      autoplay: true,
      autoHeight: true,
    });

    mySwiper.on("slideChange", function (e) {
      let activeI = this.activeIndex + 1;

      for (let i = 1; i < 5; i++) {
        if (i === activeI) {
          $(`.float_${i}`).addClass("gif_active");
        } else {
          $(`.float_${i}`).removeClass("gif_active");
        }
      }
    });

    $(".float_1").hover(function () {
      mySwiper.slideTo(0);
    });
    $(".float_2").hover(function () {
      mySwiper.slideTo(1);
    });
    $(".float_3").hover(function () {
      mySwiper.slideTo(2);
    });
    $(".float_4").hover(function () {
      mySwiper.slideTo(3);
    });
  }

  render() {
    return (
      <div className="module">
        <span className="module_h">功能模块化</span>
        <span className="module_title">更多想法，快速实现</span>
        <p className="module_content" style={{ textAlign: "center" }}>
          机器人编程教育套装所搭建机器人整体形态由“车身”和“车头”两部分构成，将复杂的机械、电子零件组合封装成具有特定功能的
          “车头”，在“车身”不变的情况下，你可以随意变化模块组合的方式和数量，快速创造出各种功能各异的机器人。{" "}
          <br />
          <br />
          机器人编程教育套装既让你享受动手搭建的乐趣，又让你不会被繁杂的零件所困扰，从而让你有更多时间进行产品
          互动和教学。
        </p>

        <AnimateRent
          index={1}
          pos_car={"float_left_check"}
          img={"images/car_m_1.png"}
          name="寻光机器人"
          img_c={"car_m_1"}
        />
        <AnimateRent
          index={2}
          name={"避障机器人"}
          pos_car={"float_left_check"}
          img={"images/car_m_2.png"}
          img_c={"car_m_2"}
        />

        <AnimateRent
          index={3}
          name={"探路机器人"}
          pos_car={"float_right_check"}
          img={"images/car_m_3.png"}
          img_c={"car_m_3"}
        />
        <AnimateRent
          index={4}
          name={"触碰机器人"}
          pos_car={"float_right_check"}
          img={"images/car_m_4.png"}
          img_c={"car_m_4"}
        />
        <div className="swiper-container module_img">
          <div className="swiper-wrapper ani-rent">
            <div className="swiper-slide">
              <img src="images/83004/屏显机器人.png" />
            </div>
            <div className="swiper-slide">
              <img src="images/83004/避障机器人.png" />
            </div>
            <div className="swiper-slide">
              <img src="images/83004/探路机器人.png" />
            </div>
            <div className="swiper-slide">
              <img src="images/83004/触碰机器人.png" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const AnimateRent = (props) => (
  <div className={`pos float_${props.index} ani-rent`}>
    <div className={props.pos_car}>
      <img className={props.img_c} src={props.img} />
      <div className="space" />
      <span className="rent_lb">{props.name}</span>
    </div>
  </div>
);

const Module2 = () => (
  <div className="container module2">
    <span className="module_h">情感显示器</span>
    <span className="module_title">让孩子自由表达喜怒哀乐，与孩子心灵相通</span>
    <p className="module_content" style={{ textAlign: "center" }}>
      寻光机器人利用科技技术，不仅能理解孩子的心情，还能通过模块化的情感显示器随时表达自己的各种情绪，
      此外随心情或音乐等变换各种图案、字符、霓虹灯等精彩瞬间。
    </p>
    <img style={{width: 450, padding: 20}} src="images/83004/4-寻光机器人-带表情.png" />
  </div>
);

const Module3 = ({
  items = [
    "机器人初体验",
    "逻辑思维养成",
    "动手能力培养",
    "综合创造能力",
    "电子传感知识",
    "扩展机械工程",
  ],
}) => (
  <div className="container-fluid module3">
    <span className="module_h" style={{ color: "#333" }}>
      STEAM教育
    </span>
    <span className="module_title" style={{ color: "#333" }}>
      在玩中探索，构建自己的知识体系
    </span>
    <p className="module_content" style={{ textAlign: "center" }}>
      机器人原理、编程思维、机械工程、物理数学、电子乐理等抽象的知识融入到产品设计中，让学生通过搭建和编程，接触多学科多领域的知识。
      <br />
      学生可以按照配套教材来学习，也可以选择感兴趣的方向自由探索，在一个个好玩的应用或尝试中，逐步构建自己的知识体系，应对动态变化的未来。
    </p>
    <div className="row">
      <div className="col-sm-offset-1 col-sm-10">
        {items.map((x, i) => (
          <div key={`83_3_${i}`} className="col-sm-4 grid_steam">
            <img src={`images/steam_${i + 1}.png`} />
            <span>{x}</span>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const Module5 = () => (
  <div className="module5 hidden-xs">
    <span>项目式特色教学 系统性学习</span>
    <span>
      机器人编程教育套装配有人工智能特色的相关课程与应用，采用项目式特色教学，
      <br />{" "}
      课堂以项目作为基础开展时间，项目贴近生活，在协作实践中获得更多见解，拓展学生的知识面。
    </span>
    <img src="images/study.png" />
  </div>
);

const Module6 = () => (
  <div className="module6 container-fluid">
    <div className="row">
      <div className="col-sm-offset-8 col-sm-4 col-xs12 block_2">
        <span className="block_title">
          深入学习
          <br />
          —机械工程扩展包
        </span>
        <span className="block_content">{`
                             “搬运车”、“叉车”和“机械手”三种形态，
让你足够玩转工程车操控的乐趣，
体验工程任务的乐趣与意义。
                        `}</span>
        <a href="61004" className="btn_3">
          <span>了解更多</span>
        </a>
      </div>
    </div>
  </div>
);

const Module7 = () => (
  <div className="bg_bottom container-fluid">
    <div className="row alien-hand">
      <div className="col-sm-4 col-sm-offset-2 col-xs-12 block_1">
        <span className="block_title">
          智能配件
          <br />
          —雷霆蓝牙遥控手柄
        </span>
        <span className="block_content">
          雷霆蓝牙遥控手柄，让孩子使用 Thunder
          IDE编程软件自定义遥控手柄的按键功能，通过蓝牙连接
          TBot控制器，对雷霆机器人进行遥控操作。
          手柄具有16个按键和2个摇杆，让操作更加得心应手。
        </span>
        <a href="tc001" className="btn_3">
          <span>了解更多</span>
        </a>
      </div>

      <div className="col-sm-5 hidden-xs">
        <img src="images/home_hand.png" />
      </div>
    </div>
  </div>
);

const VideoModal = () => (
  <div
    className="modal fade"
    id="videoModal"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div
          style={{
            height: 60,
            width: "100%",
            paddingLeft: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h3>83004介绍视频</h3>
          <button
            onClick={() => {
              window.$("#videoModal").modal("hide");
            }}
            style={{
              height: 60,
              width: 60,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <video
          id="myvideo"
          controls="controls"
          width="100%"
          src="//asset.bellrobot.com/thunder/document/video/雷霆83004宣传视频.mp4"
          poster="uploads/img/videoimg.png"
        >
          your browser does not support the video tag
        </video>
      </div>
    </div>
  </div>
);

export {
  Feature,
  Module,
  Module2,
  Module3,
  Module5,
  Module6,
  Module7,
  VideoModal,
};
