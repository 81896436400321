import React, { Component, useState } from "react";
import { Card, List, Button, Empty, Modal } from "antd";
import { MenuData } from "./PartsData";
import { connect } from "react-redux";
import { addParts, substractParts } from "../../redux/reducers/parts";
import { FallbackAvatar } from "../FallbackAvatar";

function PartList({ onAddParts, onSubstractParts, selectedParts }) {
  const [imageInfo, setIamgeInfo] = useState({});
  const [previewVisible, setPreviewVisible] = useState(false);
  const onImagePreview = (url, name) => {
    setIamgeInfo({ url, name });
    setPreviewVisible(true);
  };
  return (
    <div style={{ overflowY: "auto", height: "80vh", overflowX: "hidden" }}>
      <Modal
        visible={previewVisible}
        title={imageInfo.name || ""}
        footer={null}
        width="50vw"
        onCancel={() => {
          setPreviewVisible(false);
        }}
        closable={false}
        maskClosable
      >
        <img
          alt={imageInfo.name || ""}
          style={{ width: "100%" }}
          src={imageInfo.url || ""}
        />
      </Modal>
      {MenuData.map((data, n) => {
        let title = data.title;
        const listView = data.children.map((parts, j) => {
          return (
            <div key={parts.id} style={{ marginBottom: 36 }}>
              <h3
                style={{ color: "#606060" }}
                id={parts.id}
              >{`${title}-${parts.title}`}</h3>
              <List
                className="parts-list"
                grid={{ gutter: 12, column: 4 }}
                dataSource={parts.nums}
                renderItem={(item, i) => (
                  <List.Item>
                    <div className="card_item">
                      <Card className="card_item1">
                        <div className="sprite1">
                          <FallbackAvatar
                            shape="square"
                            className="parts-avatar"
                            src={item.image}
                          />
                          <p className="imageTitle">{item.name}</p>
                        </div>
                      </Card>
                      <Card
                        className="card_item0"
                        onClick={() => onImagePreview(item.image, item.name)}
                      >
                        <p>{item.name}</p>
                        <p>{item.desc}</p>
                        <p>最小起订数量: {item.minCount}</p>
                      </Card>
                    </div>
                    <AddNums
                      part={{
                        ...item,
                        category: parts.title,
                        count:
                          (selectedParts[item.id] &&
                            selectedParts[item.id].count) ||
                          0,
                      }}
                      onAddParts={onAddParts}
                      onSubstractParts={onSubstractParts}
                      selectedParts={selectedParts}
                    />
                  </List.Item>
                )}
              />
            </div>
          );
        });

        return listView;
      })}
    </div>
  );
}

export class AddNums extends Component {
  add = () => {
    const { part, onAddParts } = this.props;
    // console.log(part);
    onAddParts({ ...part, count: parseInt(part.count) + parseInt(part.minCount) });
  };

  did = () => {
    const { part, onSubstractParts, selectedParts } = this.props;
    if (selectedParts[part.id]) {
      let parts = {};
      if (selectedParts[part.id].count - part.minCount <= 0) {
        delete selectedParts[part.id];
        parts = Object.assign(parts, selectedParts);
      } else {
        selectedParts[part.id].count =
          selectedParts[part.id].count - part.minCount;
        parts = Object.assign(parts, selectedParts);
      }
      onSubstractParts(parts, part.minCount);
    }
  };
  render() {
    const { part, className = "", size = "large" } = this.props;
    const count = part.count || 0;
    return (
      <div className={`addDiv ${className}`}>
        {count ? (
          <>
            <Button
              size={size}
              type="primary"
              className="addbtn"
              onClick={this.did}
            >
              <img src="images/parts/div1.png" />
            </Button>
            <span>{count}</span>
            <Button
              size={size}
              type="primary"
              className="addbtn"
              onClick={this.add}
            >
              <img src="images/parts/add2.png" />
            </Button>
          </>
        ) : (
          <Button className="plus-btn" onClick={this.add}>
            <img
              src="images/parts/add1.png"
              style={{ height: 16, width: 16 }}
            />
          </Button>
        )}
      </div>
    );
  }
}

const mapStatesToProps = (state) => ({
  selectedParts: state.parts.selectedParts,
  totalCount: state.parts.totalCount,
});

const mapDispatchToProps = (dispatch) => ({
  onAddParts: (adddPart) => dispatch(addParts(adddPart)),
  onSubstractParts: (substractPartID, minCount) =>
    dispatch(substractParts(substractPartID, minCount)),
});

export default connect(mapStatesToProps, mapDispatchToProps)(PartList);
