import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import App from "../component/App";
import Product83004 from "../component/83004";
import Product61004 from "../component/61004";
import ProductTC001 from "../component/tc001";
import Home from "../component/main/Home";
import Pages from "../component/pages";
import Dog from "../component/dog/BellDog";
import BatBot from "../component/dog/BatBot";
import Lizard from "../component/dog/Lizard";
import Motor from "../component/dog/Motor";
import Products from "../component/products";
import Support from "../component/support";
import R62003 from "../component/place/62003";
import q85001 from "../component/place/85001";
import T85002 from "../pages/85002";
import TinyDetail from "../component/tinyDetail/index2";
import Parts from "../component/parts";

import q85003 from "../component/place/85003";
import q85004 from "../component/place/85004";
import q62005 from "../component/place/62005";
import q62004 from "../component/place/62004";

const BasicRoute = () => (
  <Router>
    <App>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/index.html" component={Home} />
        <Route path="/83005" component={Product83004} />
        <Route path="/61004" component={Product61004} />
        <Route path="/tc001" component={ProductTC001} />
        <Route path="/dog" component={Dog} />
        <Route path="/bat" component={BatBot} />
        <Route path="/lizard" component={Lizard} />
        <Route path="/motor" component={Motor} />
        <Route path="/productpage" component={Products} />
        <Route path="/support" component={Support} />
        <Route path="/62007" component={R62003} />
        <Route path="/85001" component={q85001} />
        <Route path="/85002" component={T85002} />
        <Route path="/83007" component={TinyDetail} />
        <Route path="/parts" component={Parts} />
        <Route path="/85003" component={q85003} />
        <Route path="/85004" component={q85004} />
        <Route path="/62005" component={q62005} />
        <Route path="/62004" component={q62004} />
        <Pages />
      </Switch>
    </App>
  </Router>
);

export default BasicRoute;
