/**
 *作者：lorne
 *时间：2019/9/17
 *功能：
 */
import React, { Component } from "react";
import "../../css/support.css";
import { MAC_URL_1_2_1, WIN_URL_1_2_1 } from "../../config/utils";

const ListDoc = [
  {
    id: "830041",
    name: "83004使用说明",
    loadUrl: "//asset.bellrobot.com/thunder/document/pdf/83004使用说明.pdf"
  },
  {
    id: "830042",
    name: "83004搭建指南",
    loadUrl: "//asset.bellrobot.com/thunder/document/pdf/83004搭建指南.pdf"
  }
];

export default class Support extends Component {
  componentDidMount() {
    document.title = "技术支持";
  }

  render() {
    return (
      <div className="Support">
        <div className="support-head">
          <div className="support-head-title">
            <p>贴心、用心、专心的服务</p>
          </div>
        </div>

        <div className="container support-container">
          <div className="support-left pull-left">
            <ul className="support-list1">
              <li>
                <a href="#common-problem">常见问题</a>
              </li>
              <li>
                <a href="#software-download">软件下载</a>
              </li>
              <li>
                <a href="#document-download">文档下载</a>
                <ul className="support-list2">
                  <li>
                    <a href="#common-document">用户手册</a>
                  </li>
                  <li>
                    <a href="#product-document">产品文档</a>
                    <ul className="support-list3">
                      <li>
                        <a href="#product-83001">83001产品</a>
                      </li>
                      <li>
                        <a href="#product-83002">83002产品</a>
                      </li>
                      <li>
                        <a href="#product-83003">83003产品</a>
                      </li>
                      <li>
                        <a href="#830041">83004产品</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#product-document">机器人等级考试文档</a>
                    <ul className="support-list3">
                      <li>
                        <a href="#product-83001">三、四级器材使用手册</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="support-right pull-right">
            <div className="common-problem-container">
              <h2>
                <a id="common-problem" name="common-problem"></a>
                <img src="uploads/img/support-icon-01.png" alt="" />
                常见问题
              </h2>
              <div className="common-problem">
                <div className="question">
                  <p>
                    <img src="uploads/img/support-icon-06.png" alt="" />
                    舵机安装和使用时需要注意哪些事项？
                  </p>
                </div>
                <ul className="answer">
                  <li>
                    <p>
                      1.
                      安装舵机前,必须先将舵机连接至雷霆TBot控制器任意一个舵机接口，打开TBot电源开关，舵机将自动重置至其行程中心位置，避免其安装后TBot开机，舵机行程超出机器人结构限位。
                    </p>
                  </li>
                  <li>
                    <p>
                      2.
                      使用《ThunderGo》App操控有舵机的雷霆机器人时，必须先在操控界面设置舵机的上下限位置，设置的活动范围必须小于机械结构实际活动范围，避免舵机行程超出机器人结构限位。
                    </p>
                  </li>
                  <li>
                    <p>
                      3. 使用《Thunder
                      IDE》软件对舵机进行编程时，必须先在线调试得到舵机的实际上下限位置和中位位置（舵机微调）数值，IDE舵机语句设置的活动范围必须小于机械结构实际活动范围，避免舵机行程超出机器人结构限位。
                    </p>
                  </li>
                  <li>
                    <p>
                      4.
                      禁止舵机行程超出机器人结构限位，以避免舵机长时间堵转。舵机长时间堵转可能会导致TBot死机、舵机发热或损坏、电池发热、电量快速耗尽等情况发生。
                    </p>
                  </li>
                </ul>
              </div>
              <div className="common-problem">
                <div className="question">
                  <p>
                    <img src="uploads/img/support-icon-06.png" alt="" />
                    防松螺母安装使用需要注意哪些事项？
                  </p>
                </div>
                <ul className="answer">
                  <li>
                    <p>
                      1.
                      转动构件安装防松螺母必须保持其松紧适度，锁的太紧影响构件正常转动，锁的太松会使安装好的构件松松垮垮，被锁的相对转动构件间活动间隙一般推荐小于1mm。
                    </p>
                  </li>
                </ul>
              </div>
              <div className="common-problem">
                <div className="question">
                  <p>
                    <img src="uploads/img/support-icon-06.png" alt="" />
                    履带版机器人在运动过程中履带容易掉，是怎么回事？
                  </p>
                </div>
                <ul className="answer">
                  <li>
                    <p>
                      1.
                      装配问题,前后两个轮毂没有装配在一条线上，通过调节使前后轮毂对齐解决此问题。
                    </p>
                  </li>
                </ul>
              </div>
              <div className="common-problem">
                <div className="question">
                  <p>
                    <img src="uploads/img/support-icon-06.png" alt="" />
                    为什么手机或平板无法通过蓝牙连接机器人?
                  </p>
                </div>
                <ul className="answer">
                  <li>
                    <p>
                      1.
                      TBot开机后，需快速按两下多功能按键，TBot运行《ThunderGo》APP对应的程序，手机APP才能与TBot连接，蓝牙待连接时TBot屏幕会显示蓝牙图标，连上后TBot会响三声。
                    </p>
                  </li>
                  <li>
                    <p>2. 机器人电池电量过低，请更换电池后尝试。</p>
                  </li>
                  <li>
                    <p>3. 请尝试重启APP或TBot。</p>
                  </li>
                </ul>
              </div>
              <div className="common-problem">
                <div className="question">
                  <p>
                    <img src="uploads/img/support-icon-06.png" alt="" />
                    为什么机器人无法正常开机（屏幕/电源指示灯/状态指示灯无反应）?
                  </p>
                </div>
                <ul className="answer">
                  <li>
                    <p>
                      1. 可能是电池电压不足导致机器人无法开机，请尝试更换电池。
                    </p>
                  </li>
                  <li>
                    <p>2. 请检查电池是否正确安装，电池盒是否松动。</p>
                  </li>
                </ul>
              </div>
              <div className="common-problem">
                <div className="question">
                  <p>
                    <img src="uploads/img/support-icon-06.png" alt="" />
                    机器人无法正常工作或TBot控制器发出“滴~滴~滴”告警声的原因有哪些?
                  </p>
                </div>
                <ul className="answer">
                  <li>
                    <p>1. TBot电池电压过低导致。</p>
                  </li>
                  <li>
                    <p>2. 机器人电机或者舵机长时间堵转导致。</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="software-download-container clearfix">
              <h2>
                <a id="software-download" name="software-download"></a>
                <img src="uploads/img/support-icon-03.png" alt="" />
                软件下载
              </h2>
              <div className="pull-left software-download">
                <div className="software-icon pull-left">
                  <img src="uploads/img/support-icon-05.png" alt="" />
                </div>
                <div class="pull-right">
                  <p class="software-name">Thunder IDE</p>
                  <p class="software-date">2019-02-12</p>
                  <p class="software-link">
                    <a href={WIN_URL_1_2_1}>
                      下载Windows版(1.0.1)
                    </a>
                    <br />
                    <a href={MAC_URL_1_2_1}>
                      下载Mac版(1.0.1)
                    </a>
                  </p>
                  <p class="software-date">2019-09-7</p>
                  <p class="software-link">
                    <a href="//asset.bellrobot.com/thunder/document/software/Thunder_IDE_Setup_1.0.6.exe">
                      下载Windows版(1.0.6)
                    </a>
                    <br />
                    <a href="//asset.bellrobot.com/thunder/document/software/Thunder_IDE_Setup_1.0.6.dmg">
                      下载Mac版(1.0.6)
                    </a>
                  </p>
                </div>
              </div>
              <div className="pull-right software-download">
                <div className="software-icon pull-left">
                  <img src="uploads/img/support-icon-04.png" alt="" />
                </div>
                <div className="pull-right">
                  <p className="software-name">Thunder Go</p>
                  <p className="software-date">2019-02-12</p>
                  <p className="software-link">
                    <a>
                      <img src="uploads/img/QR_code2.png" />
                      下载iOS版
                    </a>
                    <a>
                      <img src="uploads/img/QR_code1.png" />
                      下载Android版
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="document-download-container">
              <h2>
                <a id="document-download" name="document-download"></a>
                <img src="uploads/img/support-icon-02.png" alt="" />
                文档下载
              </h2>
              <div className="document-type">
                <h3>
                  <a id="common-document" name="common-document"></a>用户手册
                </h3>
                <ul>
                  <li>
                    <div className="document-file">
                      <p>
                        <img src="uploads/img/support-icon-06.png" alt="" />
                        雷霆系列产品用户指南
                        <a
                          href="//asset.bellrobot.com/thunder/document/pdf/雷霆系列产品用户指南V1.3.pdf"
                          target="_blank"
                          className="pull-right"
                        >
                          下载PDF
                        </a>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="document-file">
                      <p>
                        <img src="uploads/img/support-icon-06.png" alt="" />
                        《Thunder IDE》编程使用指南
                        <a
                          href="//asset.bellrobot.com/thunder/document/pdf/《Thunder IDE》编程指南V1.3.pdf"
                          target="_blank"
                          className="pull-right"
                        >
                          下载PDF
                        </a>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="document-file">
                      <p>
                        <img src="uploads/img/support-icon-06.png" alt="" />
                        《Thunder GO》 APP使用指南
                        <a
                          href="//asset.bellrobot.com/thunder/document/pdf/《Thunder Go》APP使用指南V1.2.pdf"
                          target="_blank"
                          className="pull-right"
                        >
                          下载PDF
                        </a>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="document-file">
                      <p>
                        <img src="uploads/img/support-icon-06.png" alt="" />
                        雷霆系列产品售后服务条款
                        <a
                          href="//asset.bellrobot.com/thunder/document/pdf/雷霆系列产品售后服务条款.pdf"
                          target="_blank"
                          className="pull-right"
                        >
                          下载PDF
                        </a>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="document-type">
                <h3>
                  <a id="product-document" name="product-document"></a>产品文档
                </h3>
                <ul>
                  <li>
                    <div className="document-file">
                      <a id="product-83001" name="product-83001"></a>
                      <p>
                        <img src="uploads/img/support-icon-06.png" alt="" />
                        83001使用说明
                        <a
                          href="//asset.bellrobot.com/thunder/document/pdf/83001使用说明.pdf"
                          target="_blank"
                          className="pull-right"
                        >
                          下载PDF
                        </a>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="document-file">
                      <p>
                        <a id="product-83002" name="product-83002"></a>
                        <img src="uploads/img/support-icon-06.png" alt="" />
                        83001搭建指南
                        <a
                          href="//asset.bellrobot.com/thunder/document/dajian/83001搭建指南.rar"
                          className="pull-right"
                        >
                          下载PDF
                        </a>
                      </p>
                    </div>
                  </li>
                  <li>
                    <li>
                      <div className="document-file">
                        <a></a>
                        <p>
                          <img src="uploads/img/support-icon-06.png" alt="" />
                          83002使用说明
                          <a
                            href="//asset.bellrobot.com/thunder/document/pdf/83002使用说明.pdf"
                            target="_blank"
                            className="pull-right"
                          >
                            下载PDF
                          </a>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="document-file">
                        <p>
                          <a></a>
                          <img src="uploads/img/support-icon-06.png" alt="" />
                          83002搭建指南
                          <a
                            href="//asset.bellrobot.com/thunder/document/dajian/83002搭建指南.pdf"
                            className="pull-right"
                          >
                            下载PDF
                          </a>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="document-file">
                        <a id="product-83003" name="product-83003"></a>
                        <p>
                          <img src="uploads/img/support-icon-06.png" alt="" />
                          83003使用说明
                          <a
                            href="//asset.bellrobot.com/thunder/document/pdf/83003使用说明.pdf"
                            target="_blank"
                            className="pull-right"
                          >
                            下载PDF
                          </a>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="document-file">
                        <p>
                          <a></a>
                          <img src="uploads/img/support-icon-06.png" alt="" />
                          83003搭建指南
                          <a
                            href="//asset.bellrobot.com/thunder/document/dajian/83003搭建指南.rar"
                            className="pull-right"
                          >
                            下载PDF
                          </a>
                        </p>
                      </div>
                    </li>
                    {ListDoc.map((item, i) => (
                      <ProductDoc item={item} key={`doc_${i}`} />
                    ))}
                  </li>
                </ul>
              </div>

              <div className="document-type">
                <h3>
                  <a id="common-document" name="common-document"></a>
                  机器人等级考试文档
                </h3>
                <ul>
                  <li>
                    <div className="document-file">
                      <p>
                        <img src="uploads/img/support-icon-06.png" alt="" />
                        全国青少年机器人技术等级考试三、四级器材套件使用手册
                        <a
                          href="//asset.bellrobot.com/thunder/document/pdf/全国青少年机器人技术等级考试三、四级器材套件使用手册.pdf"
                          target="_blank"
                          className="pull-right"
                        >
                          下载PDF
                        </a>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: 60 }}></div>
      </div>
    );
  }
}

const ProductDoc = ({ item }) => (
  <li>
    <div className="document-file">
      <p>
        <a id={item.id} name={item.id}></a>
        <img src="uploads/img/support-icon-06.png" alt="" />
        {item.name}
        <a href={item.loadUrl} className="pull-right">
          下载PDF
        </a>
      </p>
    </div>
  </li>
);
