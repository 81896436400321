import React from "react";
import { TreeSelect, Menu } from "antd";
import { MenuData } from "./PartsData";
import SearchOutlined from "@ant-design/icons/SearchOutlined";
const { SubMenu } = Menu;

class SelectBox extends React.Component {
  state = {
    current: "mail",
    selectedKeys: []
  };

  onChange = (obj) => {
    if (obj.key) {
      this.setState({
        selectedKeys: [obj.key]
      })
      document.getElementById(obj.key).scrollIntoView({ block: "center" });
    }
  };

  render() {
    const { selectedKeys } = this.state;

    return (
      <div style={{ width: 256, marginRight: 24 }}>
        <TreeSelect
          style={{
            width: 256,
            marginBottom: 10,
            borderRadius: 10,
            backgroundColor: "white",
          }}
          className="search-tree-select"
          placeholder="请输入"
          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
          treeData={MenuData}
          treeDefaultExpandAll
          showSearch
          bordered={false}
          size={"large"}
          onSelect={(v, item) => {
            this.onChange({ key: item.id });
          }}
          suffixIcon={<SearchOutlined />}
        />

        <div
          style={{
            background: "#F8F8F8",
            height: 48,
            display: "flex",
            alignItems: "center",
            paddingLeft: 24,
            fontSize: 18,
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
          }}
        >
          雷霆零部件分类
        </div>
        <Menu
          onClick={this.onChange}
          mode="inline"
          style={{
            borderBottomRightRadius: 10,
            borderBottomLeftRadius: 10,
            height: "calc(80vh - 50px - 48px)",
            overflowX: "hidden",
            overflowY: "auto",
          }}
          className="parts-menu"
          selectedKeys={selectedKeys}
        >
          {MenuData.map((sub, i) => {
            return (
              <SubMenu key={`sub${i}`} title={<span>{sub.title}</span>}>
                {sub.children.map((child, k) => (
                  <Menu.Item style={{ color: "#606060" }} key={child.id}>
                    {child.title}
                  </Menu.Item>
                ))}
              </SubMenu>
            );
          })}
        </Menu>
      </div>
    );
  }
}

export default SelectBox;
