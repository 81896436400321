import React from "react";
import './index.css'

/**
 *作者：lorne
 *时间：2019/9/6
 *功能：
 */

const BtnVideo = ()=>(<button type='button' className='btn_video'
                              data-toggle="modal" data-target="#videoModal">
    <img src='images/video_paly.png'/>
    <span>播放视频</span>
</button>)

const typeList = {
    '83004':{
        left_label: `机器人编程教育套装是雷霆从易用性、可玩性、教育性等多方面进行升级的一款教育机器人；其易用性特点，降低适用年龄，8岁儿童也能使用。通过图形化编程让机器人以动作、声音、灯光等直观方式生动的实现最终目标。通过教学实践，
        教授孩子机械、电子、物理、数学等多学科知识，培养逻辑思维能力，
        编程能力和创造能力，为孩子打开科学世界的大门。`,
        left_img:'images/83004.png',
        right_img:'images/car_83004.png',
        children:<BtnVideo/>,
        background:'images/home_bg_top.png'
    },
    '61004':{
        left_label:'机械工程扩展包由185个包含齿轮、梁类，轴类、紧固类等5种专业级铝合金机械零件组成。标准化、通用化的结构设计，使扩展包零件完美兼容声光互动机器人，可扩展搭建出三种不同的形态。',
        left_img:'images/61004/61004.png',
        background:'images/61004/bg_61004.png'
    },
    'tc001':{
        left_label:<span>编程自定义手柄按键功能<br/>LED多功能指示灯<br/>蓝牙自动配对<br/>自动睡眠<br/>低电提醒</span>,
        left_img:'images/tc001/tc001.png',
        right_img:'images/home_hand.png',
        background:'images/home_bg_bottom.png'
    }
}



const Head = ({type='83004'}) => (
    <div
      style={{background:`url(/${typeList[type].background}) no-repeat top center`}}>
        <div className="container">
            <div className="row alien-content">
                <div className="col-sm-5 col-xs-12 detail-banner-animate1">
                    <div className="ailen-banner-font"><img src={typeList[type].left_img}/></div>
                    {/* <div className='alien-line'/> */}
                    <div>
                        <p className="ailen-banner-intro1">{typeList[type].left_label}
                            {typeList[type].children && typeList[type].children}
                        </p>

                    </div>
                </div>
                <div className="col-sm-7 hidden-xs ailen-banner-img1">
                  {typeList[type].right_img?<img
                    src={typeList[type].right_img}/>:null}
                  </div>
            </div>
        </div>
    </div>
)
export default Head
