/**
 *作者：lorne
 *时间：2019/9/6
 *功能：
 */
import React, {Component,Fragment} from 'react'
import Head from "./head";
import Coding from "./coding";
import Download from "./download";
import Carousel from "./Carousel";
import {Feature, Module, Module2, Module3, Module5, Module6, Module7, VideoModal} from "./components";
import Base from "../Base";

export default class index extends Base {

    static defaultProps={
        title:'83005套装'
    }

    componentReady() {
        let $ = window.$
        let video = document.getElementById('myvideo')
        $('#videoModal').on('hidden.bs.modal',function () {
            video.pause()
        })
        $('#videoModal').on('shown.bs.modal',function () {
            video.play()
        })
    }

    renderPage() {
        return <Fragment>
            <Head/>
            <div className='flex-col-h-center'>
                <span className='thunder-h'>更多灵感，搭建梦想</span>
                <p className='thunder-p'>可拼搭避障机器人、探路机器人、寻光机器人、触碰机器人四种官方形态，更可发挥想象DIY,打造专属机器人。</p>
            </div>
            <Carousel/>
            <Feature/>
            <Module/>
            <Module2/>
            <Module3/>
            <Coding/>
            <Download/>
            <Module5/>
            <Module6/>
            <Module7/>
            <VideoModal/>
        </Fragment>
    }

}
