/**
 *作者：lorne
 *时间：2019/9/5
 *功能：
 */
import React, { Fragment } from "react";
import "./carousel.css";

const cars_83004 = [
  { name: "避障机器人", icon: "images/83004/car_img_2.png" },
  { name: "探路机器人", icon: "images/83004/car_img_6.png" },
  { name: "寻光机器人", icon: "images/83004/car_img_5.png" },
  { name: "触碰机器人", icon: "images/83004/car_img_1.png" },
];

const cars_61004 = [
  { name: "叉车机器人", icon: "images/83004/car_img_3.png" },
  { name: "搬运机器人", icon: "images/83004/car_img_0.png" },
  { name: "机械手机器人", icon: "images/83004/car_img_4.png" },
];

const listObj = {
  cars_83004: cars_83004,
  cars_61004: cars_61004,
};
const Carousel = ({ type = "cars_83004" }) => (
  <Fragment>
    <div
      id="carousel-example-generic"
      className="carousel slide mine"
      data-ride="carousel"
    >
      <ol className="carousel-indicators hidden-xs">
        {listObj[type] &&
          listObj[type].map((item, i) => (
            <li
              key={`indicators${i}`}
              data-target="#carousel-example-generic"
              data-slide-to={i}
              className={i === 0 ? "active" : ""}
            >
              <span>{item.name}</span>
            </li>
          ))}
      </ol>

      <div className="carousel-inner" role="listbox">
        {listObj[type] &&
          listObj[type].map((item, i) => (
            <div
              key={`box${i}`}
              className={`item ${i === 0 ? "active" : ""} carouse`}
            >
              <div
                style={{
                  background: `url(/${item.icon}) no-repeat top center`,
                }}
              />
            </div>
          ))}
      </div>

      <a
        className="left carousel-control carousel-btn"
        href="#carousel-example-generic"
        role="button"
        data-slide="prev"
      >
        <span
          className="glyphicon glyphicon-chevron-left"
          aria-hidden="true"
        ></span>
        <span className="sr-only">Previous</span>
      </a>
      <a
        className="right carousel-control carousel-btn"
        href="#carousel-example-generic"
        role="button"
        data-slide="next"
      >
        <span
          className="glyphicon glyphicon-chevron-right"
          aria-hidden="true"
        ></span>
        <span className="sr-only">Next</span>
      </a>
    </div>
  </Fragment>
);

export default Carousel;
