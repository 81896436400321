import React from "react";
import {
  WIN_URL_1_2_1,
  MAC_URL_1_2_1,
  THUNBOT_DOWNLOAD_URL,
  HiCode_DOWNLOAD_URL,
} from "../../config/utils";

const menus = [
  // {
  //   title: "常见问题",
  //   id: "#common-problem",
  // },
  {
    title: "软件下载",
    id: "#software-download",
  },
  // {
  //   title: "用户手册",
  //   id: "#common-document",
  // },
  {
    title: "使用教程",
    id: "#tutorial",
  },
  {
    title: "产品文档",
    list: [
      { name: "套装", id: "#product-document" },
      { name: "单品", id: "#product-sigle" },
      { name: "配件", id: "#product-fitting" },
      { name: "比赛", id: "#product-race" },
    ],
  },
  {
    title: "机器人等级考试",
    id: "#rebot-testing",
  },
  // {
  //   title: "售后服务条款",
  //   id: "#service",
  // },
];
const ListDoc = [
  {
    id: "830041",
    name: "83004使用说明",
    loadUrl: `${global.WWW_COM_DOC}83004使用说明.pdf`,
  },
  {
    id: "830042",
    name: "83004搭建指南",
    loadUrl: `${global.WWW_COM_DOC}83004搭建指南.pdf`,
  },
];

const styles = {
  txt1: { fontSize: 14, color: "#3284FF", fontWeight: 500 },
  btnDown: {
    height: 30,
    width: 115,
    borderRadius: 15,
    background: "#F8B62B",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  txt2: {
    fontSize: 18,
    color: "#333333",
    fontWeight: 800,
    marginTop: 30,
  },
  txt3: {
    fontSize: 18,
    color: "#333333",
  },
  bg_p: { background: "#F7F9FA", padding: 10, marginTop: 30 },
  down: {
    border: "4px solid transparent",
    borderTop: "8px solid #FF9C00",
    borderBottom: "4px solid transparent",
    height: 0,
    width: 0,
    marginLeft: 4,
    marginTop: 4,
  },
  left: {
    border: "4px solid transparent",
    borderBottom: "8px solid #FF9C00",
    height: 0,
    width: 0,
    marginLeft: 4,
    marginTop: 4,
  },
};

export class Menus extends React.Component {
  state = {
    title: "",
    open: false,
  };
  render() {
    return (
      <div
        style={{
          width: "25%",
          height: 1100,
          background: "#fff",
          paddingTop: 50,
        }}
        className="support-menu"
      >
        {menus.map((x, i) => (
          <dl key={`dl${i}`} className="menu-close">
            <dt>
              <a
                href={x.id}
                onClick={(e) => {
                  let oDl = e.target.parentNode.parentNode;
                  if (oDl.className == "menu-close") {
                    oDl.className = "menu-open";
                  } else {
                    oDl.className = "menu-close";
                  }
                  if (x.title === "产品文档") {
                    this.setState({
                      open: !this.state.open,
                    });
                  }
                }}
                style={{ display: "flex", alignItems: "center" }}
              >
                {x.title}
                {x.list ? (
                  <div
                    style={this.state.open ? styles.down : styles.left}
                  ></div>
                ) : null}
              </a>
            </dt>
            {x.list &&
              x.list.map((o, j) => (
                <dd key={`${x}dd${j}`}>
                  <a href={o.id}>{o.name}</a>
                  <div></div>
                </dd>
              ))}
          </dl>
        ))}
      </div>
    );
  }
}

export class Content extends React.Component {
  render() {
    const pos = this.props.pos;
    let el = <div></div>;
    switch (pos) {
      case "#software-download":
        el = <SoftDownload />;
        break;
      case "#common-document":
        el = <UserDocument />;
        break;
      case "#product-document":
        el = <ProDoc />;
        break;
      case "#tutorial":
        el = <Tutorial />;
        break;
      case "#product-sigle":
        el = <ProDoc1 />;
        break;
      case "#product-fitting":
        el = <ProDoc2 />;
        break;
      case "#product-race":
        el = <ProDoc3 />;
        break;
      case "#rebot-testing":
        el = <BotDoc />;
        break;
      case "#service":
        el = <Service />;
        break;

      default:
        el = <Problem />;
        break;
    }
    return (
      <div
        style={{
          width: "60%",
          minHeight: 1100,
          background: "#fff",
          marginLeft: 20,
          padding: 30,
        }}
      >
        {el}
      </div>
    );
  }
}

const Service = () => (
  <div>
    <a className="target-fix" id="service" name="service"></a>
    <IconTitle
      title="雷霆系列产品售后服务条款"
      src="images/products/s_service.png"
      imgStyle={{ height: 35, width: 34 }}
    />
    <div className="question1" style={styles.bg_p}>
      <p style={styles.txt3}>
        <img src="uploads/img/support-icon-06.png" alt="" />
        质保期：
      </p>
      <img src="images/products/pro_detail.png"></img>
    </div>

    <div className="question1" style={styles.bg_p}>
      <p style={styles.txt3}>
        <img src="uploads/img/support-icon-06.png" alt="" />
        三包条款：
      </p>
      <p>
        1. 包退：产品自售出之日起 7
        天内，若包装无拆封不影响二次销售，消费者可以选择退货或换货。
      </p>
      <p>
        2. 包换：产品自售出之日起 15
        日内，若出现质量问题，且不在“非包换”范围的，经我司确认问题，消费者可以换货或修理。
      </p>
      <p>
        3.
        包修：产品自售出之日起在保修期内，若出现质量问题，且不在“非免费维修”范围的产品，经我司确认问题，消费者可以获取免费维修或更换故障件服务。而对于属于“非质保期”和“非免费维修”的产品，消费者可以选择该产品的有偿维修服务，维修所产生人工费，成本费及运费由客户承担。
        注：对于返修/更换后的产品，从我司发货之日起有 3
        个月的质保期，如果原产品剩余的保修期比 3 个月长，则以原质保期为准。
      </p>
    </div>
    <div className="question1" style={styles.bg_p}>
      <p style={styles.txt3}>
        <img src="uploads/img/support-icon-06.png" alt="" />
        下列情况属“非包换”和“非免费维修”范围：
      </p>
      <p>1. 未按使用说明书要求正常使用导致的产品故障或损坏；</p>
      <p>2. 因不可抗拒的自然灾害导致的产品故障或损坏；</p>
      <p>3. 擅自拆解、改造、组装而发生的故障或损坏；</p>
      <p>4. 客户发回返修途中由于运输、装卸所导致的损坏；</p>
      <p>5. 其他不可预估因素且非产品本身质量问题而导致的故障或损坏。</p>
    </div>

    <div className="question1" style={styles.bg_p}>
      <p style={styles.txt3}>
        {" "}
        <img src="uploads/img/support-icon-06.png" alt="" />
        特别声明
      </p>
      <p>1. 本产品配套的软件为免费提供，本公司提供免费更新服务。</p>
      <p>
        2.
        产品的包装、耗材、赠送物品等不在保修范围内，但若在购机后初次使用时有问题，可免费包换。
      </p>
      <p>3. 国外用户购买产品，暂不提供境外保修服务。</p>
      <p>
        4.
        本条款中所述的“质保期”均指自购买产品开具的发票日期开始计算。申请质保服务，需要出示购买发票，请妥善保管好您的发票。
      </p>
      <p>5. 本条款若与相关法律法规有冲突，按法律法规的规定执行。</p>
    </div>
    <p style={{ ...styles.txt3, ...styles.bg_p }}>
      注：本条款内容的最终解释权、修改权归深圳贝尔创意科教有限公司所有。本条款若有更新，请用户关注产品官网最新售后条款，我司不负责通知所有用户，敬请谅解。
    </p>
  </div>
);
const BotDoc = () => (
  <div>
    <a className="target-fix" id="rebot-testing" name="rebot-testing"></a>
    <IconTitle
      title="机器人等级考试"
      src="images/products/s_rebot.png"
      imgStyle={{ height: 33, width: 40 }}
    />
    <ul>
      <p style={styles.txt2}>三、四级考试</p>
      <ProductDoc
        item={{
          name: "全国青少年机器人技术等级考试三、四级器材套件使用手册",
          loadUrl: `${global.WWW_COM_DOC}全国青少年机器人技术等级考试三、四级器材套件使用手册.pdf`,
        }}
      />
      <ProductDoc
        item={{
          name: "全国青少年机器人技术等级考试三级构型搭建手册",
          loadUrl: `${global.WWW_COM_DOC}全国青少年机器人技术等级考试三级构型搭建手册.pdf`,
        }}
      />
      <ProductDoc
        item={{
          name: "全国青少年机器人技术等级考试四级构型搭建手册",
          loadUrl: `${global.WWW_COM_DOC}全国青少年机器人技术等级考试四级构型搭建手册.pdf`,
        }}
      />
      <ProductDoc
        item={{
          name: "全国青少年机器人技术等级考试三、四级器材电机驱动",
          loadUrl: `${global.WWW_COM_DOC}全国青少年机器人技术等级考试三、四级器材电机驱动.rar`,
        }}
      />
    </ul>
  </div>
);
const ProDoc3 = () => (
  <div>
    <a className="target-fix" id="product-fitting" name="product-fitting"></a>
    <IconTitle title="产品文档>>比赛" src="uploads/img/support-icon-02.png" />
    <ul>
      <p style={styles.txt2}>“RA-极限冰壶”赛事</p>
      <ProductDoc
        item={{
          name: "62007《RA-极限冰壶》场地包零件清单",
          loadUrl:
            "https://docs.thunder.bellrobot.com/thunderInternetResource/document/62007《RA-极限冰壶》场地包零件清单.png",
        }}
      />
      <ProductDoc
        item={{
          name: "62007《RA-极限冰壶》场地策略物搭建说明",
          loadUrl:
            "https://docs.thunder.bellrobot.com/thunderInternetResource/document/62007《RA-极限冰壶》场地策略物搭建说明.rar",
        }}
      />
      <ProductDoc
        item={{
          name: "85001《RA-极限冰壶》场地包零件清单",
          loadUrl:
            "https://docs.thunder.bellrobot.com/thunderInternetResource/document/85001《RA-极限冰壶》器材包零件清单.pdf",
        }}
      />
      <p style={styles.txt2}>“RA挑战赛-极智速滑”赛事</p>
      <ProductDoc
        item={{
          name: "62005《RA挑战赛-极智速滑》场地包零件清单",
          loadUrl:
            "http://docs.thunder.bellrobot.com/thunderInternetResource/document/62005《RA挑战赛-极智速滑》场地包零件清单.png",
        }}
      />
      <ProductDoc
        item={{
          name: "62005《RA挑战赛-极智速滑》场地策略物搭建说明",
          loadUrl:
            "http://docs.thunder.bellrobot.com/thunderInternetResource/document/62005《RA挑战赛-极智速滑》场地策略物搭建说明.rar",
        }}
      />
      <ProductDoc
        item={{
          name: "85004《RA挑战赛-极智速滑》器材包零件清单",
          loadUrl:
            "http://docs.thunder.bellrobot.com/thunderInternetResource/document/85004《RA挑战赛-极智速滑》器材包零件清单.png",
        }}
      />
      <p style={styles.txt2}>“RA挑战赛-拆弹专家”赛事</p>
      <ProductDoc
        item={{
          name: "62004《RA挑战赛-拆弹专家》场地包零件清单",
          loadUrl:
            "http://docs.thunder.bellrobot.com/thunderInternetResource/document/62004《RA挑战赛-拆弹专家》场地包零件清单.png",
        }}
      />
      <ProductDoc
        item={{
          name: "62004《RA挑战赛-拆弹专家》场地策略物搭建说明",
          loadUrl:
            "http://docs.thunder.bellrobot.com/thunderInternetResource/document/62004《RA挑战赛-拆弹专家》场地策略物搭建说明.rar",
        }}
      />
      <ProductDoc
        item={{
          name: "85003《RA挑战赛-拆弹专家》器材包零件清单",
          loadUrl:
            "http://docs.thunder.bellrobot.com/thunderInternetResource/document/85003《RA挑战赛-拆弹专家》器材包零件清单.png",
        }}
      />
    </ul>
  </div>
);
const ProDoc2 = () => (
  <div>
    <a className="target-fix" id="product-fitting" name="product-fitting"></a>
    <IconTitle title="产品文档>>配件" src="uploads/img/support-icon-02.png" />
    <ul>
      <p style={styles.txt2}>TC001蓝牙遥控手柄</p>
      <ProductDoc
        item={{
          name: "雷霆蓝牙遥控手柄产品说明书",
          loadUrl: `${global.WWW_COM_DOC}雷霆蓝牙遥控使用说明.pdf`,
        }}
      />
    </ul>
  </div>
);
const ProDoc1 = () => (
  <div>
    <a className="target-fix" id="product-fitting" name="product-fitting"></a>
    <IconTitle title="产品文档>>单品" src="uploads/img/support-icon-02.png" />
    <ul>
      <p style={styles.txt2}>240016</p>
      <ProductDoc
        item={{
          name: "240016贝旺零件清单",
          loadUrl: `${global.WWW_COM_DOC}240016贝旺零件清单.pdf`,
        }}
      />
      <ProductDoc
        item={{
          name: "240016贝旺搭建说明",
          loadUrl: `${global.WWW_COM_DOC}240016贝旺搭建说明.pdf`,
        }}
      />
      <p style={styles.txt2}>240017</p>
      <ProductDoc
        item={{
          name: "240017蜥蜴零件清单",
          loadUrl: `${global.WWW_COM_DOC}240017蜥蜴零件清单.pdf`,
        }}
      />
      <ProductDoc
        item={{
          name: "240017蜥蜴搭建说明",
          loadUrl: `${global.WWW_COM_DOC}240017蜥蜴搭建说明.pdf`,
        }}
      />
      <p style={styles.txt2}>240018</p>
      <ProductDoc
        item={{
          name: "240018蝙蝠零件清单",
          loadUrl: `${global.WWW_COM_DOC}240018蝙蝠零件清单.pdf`,
        }}
      />
      <ProductDoc
        item={{
          name: "240018蝙蝠搭建说明",
          loadUrl: `${global.WWW_COM_DOC}240018蝙蝠搭建说明.pdf`,
        }}
      />
      <p style={styles.txt2}>270001</p>
      <ProductDoc
        item={{
          name: "270001机车零件清单",
          loadUrl: `${global.WWW_COM_DOC}270001机车零件清单.pdf`,
        }}
      />
      <ProductDoc
        item={{
          name: "270001机车搭建说明",
          loadUrl: `${global.WWW_COM_DOC}270001机车搭建说明.pdf`,
        }}
      />
    </ul>
  </div>
);
const ProDoc = () => (
  <div>
    <a className="target-fix" id="product-document" name="product-document"></a>
    <IconTitle title="产品文档>>套装" src="uploads/img/support-icon-02.png" />
    <ul>
      <p style={styles.txt2}>83007</p>
      <ProductDoc
        item={{
          name: "83007使用说明",
          loadUrl: `${global.WWW_COM_DOC}雷霆83007用户手册.pdf`,
        }}
      />
      <p style={styles.txt2}>83005</p>
      <ProductDoc
        item={{
          name: "83005使用说明",
          loadUrl: `${global.WWW_COM_DOC}雷霆83005使用说明.pdf`,
        }}
      />
      <p style={styles.txt2}>83001</p>
      <ProductDoc
        item={{
          name: "83001使用说明",
          loadUrl: `${global.WWW_COM_DOC}83001使用说明.pdf`,
        }}
      />
      <ProductDoc
        item={{
          name: "83001搭建指南",
          loadUrl: `${global.WWW_COM_DOC}83001搭建指南.rar`,
        }}
      />
      {/* <p style={styles.txt2}>83002</p> */}
      {/* <ProductDoc
        item={{
          name: "83002使用说明",
          loadUrl:
            "//asset.bellrobot.com/thunder/document/pdf/83002使用说明.pdf",
        }}
      />
      <ProductDoc
        item={{
          name: "83002搭建指南",
          loadUrl:
            "//asset.bellrobot.com/thunder/document/dajian/83002搭建指南.pdf",
        }}
      /> */}
      {/* <p style={styles.txt2}>83003</p>
      <ProductDoc
        item={{
          name: "83003使用说明",
          loadUrl:
            "//asset.bellrobot.com/thunder/document/pdf/83003使用说明.pdf",
        }}
      />
      <ProductDoc
        item={{
          name: "83003搭建指南",
          loadUrl:
            "//asset.bellrobot.com/thunder/document/dajian/83003搭建指南.rar",
        }}
      /> */}
      <p style={styles.txt2}>83004</p>
      {ListDoc.map((item, i) => (
        <ProductDoc item={item} key={`doc_${i}`} />
      ))}
    </ul>
  </div>
);

const Tutorial = () => (
  <div>
    <a className="target-fix" id="tutorial" name="tutorial"></a>
    <IconTitle
      title="使用教程"
      src="images/products/tutorial.png"
      imgStyle={{ width: 25, marginRight: 20 }}
    />
    <ul>
      <ProductDoc
        item={{
          name: "搭建基础使用",
          loadUrl: `${global.WWW_COM_DOC}搭建基础使用V2.0.pdf`,
        }}
      />
      <ProductDoc
        item={{
          name: "舵机及配件安装技巧",
          loadUrl: `${global.WWW_COM_DOC}舵机及配件安装技巧V2.0.pdf`,
        }}
      />
      <ProductDoc
        item={{
          name: "履带安装技巧",
          loadUrl: `${global.WWW_COM_DOC}履带安装技巧V2.0.pdf`,
        }}
      />
      <ProductDoc
        item={{
          name: "机械手安装技巧",
          loadUrl: `${global.WWW_COM_DOC}机械手安装技巧V2.0.pdf`,
        }}
      />
      <ProductDoc
        item={{
          name: "《Thunder GO》舵机设置",
          loadUrl: `${global.WWW_COM_DOC}《Thunder Go》舵机设置V2.0.pdf`,
        }}
      />
      <ProductDoc
        item={{
          name: "《Thunder IDE》调试模式舵机设置",
          loadUrl: `${global.WWW_COM_DOC}《Thunder IDE》调试模式舵机设置V2.0.pdf`,
        }}
      />
    </ul>
  </div>
);

function getFileExt(path) {
  let last_len = path.lastIndexOf(".") + 1;
  let len = path.length;
  let pathf = path.substring(last_len, len);

  return pathf;
}

const ProductDoc = ({ item }) => (
  <li>
    <div className="document-file">
      <p>
        <img src="uploads/img/support-icon-06.png" alt="" />
        {item.name}
        <a href={item.loadUrl} className="pull-right">
          {getFileExt(item.loadUrl) === "pdf" ? "下载PDF" : "下载文件"}
        </a>
      </p>
    </div>
  </li>
);

const DownTitle = ({ img, name, date }) => (
  <div
    className="support-shadow"
    style={{
      height: 100,
      width: 260,
      borderRadius: 10,
      display: "flex",
      alignItems: "center",
      paddingLeft: 20,
      marginTop: 30,
    }}
  >
    <img src={img} style={{ width: 60 }} />
    <div style={{ marginLeft: 20 }}>
      <span style={{ fontSize: 20, color: "#333" }}>{name}</span>
      <br />
      <span style={{ fontSize: 18, color: "#999" }}>{date}</span>
    </div>
  </div>
);

const UserDocument = () => (
  <div>
    <a className="target-fix" id="common-document" name="common-document"></a>
    <IconTitle title="用户手册" src="uploads/img/support-icon-02.png" />
    <ul>
      <li>
        <div className="document-file">
          <p>
            <img src="uploads/img/support-icon-06.png" alt="" />
            雷霆系列产品用户指南
            <a
              href="http://docs.thunder.bellrobot.com/thunderInternetResource/document/雷霆系列产品用户指南.pdf"
              target="_blank"
              className="pull-right"
            >
              下载PDF
            </a>
          </p>
        </div>
      </li>
      <li>
        <div className="document-file">
          <p>
            <img src="uploads/img/support-icon-06.png" alt="" />
            《Thunder IDE》编程指南
            <a
              href="http://docs.thunder.bellrobot.com/thunderInternetResource/document/《Thunder IDE》编程指南.pdf"
              target="_blank"
              className="pull-right"
            >
              下载PDF
            </a>
          </p>
        </div>
      </li>
      <li>
        <div className="document-file">
          <p>
            <img src="uploads/img/support-icon-06.png" alt="" />
            《Thunder GO》 APP使用指南
            <a
              href="http://docs.thunder.bellrobot.com/thunderInternetResource/document/《Thunder Go》APP使用指南.pdf"
              target="_blank"
              className="pull-right"
            >
              下载PDF
            </a>
          </p>
        </div>
      </li>
    </ul>
  </div>
);

const SoftDownload = () => (
  <div>
    <a
      className="target-fix"
      id="software-download"
      name="software-download"
    ></a>
    <IconTitle title="软件下载" src="uploads/img/support-icon-03.png" />

    <div>
      <DownTitle
        name="HiCode"
        date="2021-12-31"
        img="images/products/HiCode.png"
      />
      <div
        style={{
          padding: 20,
          paddingLeft: 40,
          background: "#F7F9FA",
          marginTop: 20,
        }}
      >
        <p style={{ fontSize: 16, color: "#727272" }}>
          HiCode
          是贝尔集团旗下的少儿编程机器人教育软件，致力于将机器人原理、编程学、人工智能、工程设计、数理学与Steam等学科融合在一起，通过简单而有趣的“搭积木”编程，即使零基础，也能轻松地创造出属于自己的机器人。
        </p>
        <p style={{ fontSize: 16, color: "#727272" }}>
          结合贝尔集团丰富多彩的Mabot系列、雷霆系列产品，通过寓学于乐的方式可不断培养学生的动手能力、创造力和想象力，提升孩子的AIQ，以科技改变教育，培养人工智能时代原住民。
        </p>

        <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
          <img src="images/products/HiCodeCover.png" style={{ width: "45%" }} />
          <div style={{ flex: 1 }}></div>
          <div style={{ display: "flex", textAlign: "center" }}>
            <div>
              <p style={styles.txt1}>下载Windows版</p>

              <a
                href={HiCode_DOWNLOAD_URL.win}
                className="support-shadow"
                style={{ ...styles.btnDown, marginTop: 20 }}
              >
                1.0.0版本
              </a>
            </div>
            <div style={{ marginLeft: 20 }}>
              <p style={styles.txt1}>下载Mac版</p>
              <a
                href={HiCode_DOWNLOAD_URL.mac}
                className="support-shadow"
                style={{ ...styles.btnDown, marginTop: 20 }}
              >
                1.0.0版本
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <DownTitle
        name="Thunder IDE 2.0"
        date="2021-06-20"
        img="images/products/icon256x256.png"
      />
      <div
        style={{
          padding: 20,
          paddingLeft: 40,
          background: "#F7F9FA",
          marginTop: 20,
        }}
      >
        <p style={{ fontSize: 16, color: "#727272" }}>
          《Thunder IDE 2.0》是一款适用于雷霆系列机器人的PC端编程软件，
          该软件专门针对中小学生学习编程而开发，采用Scratch积木语句块和Python语言编程。
          通过Scratch编程基础入门，让孩子轻松进阶到Python语言编程。IDE软件集成了多种声/光/运动等输入输出编程模块，通过自由组合，让孩子发挥想象，培养创造力和逻辑思维能力，帮助孩子从编程小白进阶到高手。
        </p>

        <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
          <img src="images/products/ide2.0.png" style={{ width: "45%" }} />
          <div style={{ flex: 1 }}></div>
          <div style={{ display: "flex", textAlign: "center" }}>
            <div>
              <p style={styles.txt1}>下载Windows版</p>

              <a
                href={THUNBOT_DOWNLOAD_URL.win}
                className="support-shadow"
                style={{ ...styles.btnDown, marginTop: 20 }}
              >
                2.0.1版本
              </a>
            </div>
            <div style={{ marginLeft: 20 }}>
              <p style={styles.txt1}>下载Mac版</p>
              <a
                href={THUNBOT_DOWNLOAD_URL.mac}
                className="support-shadow"
                style={{ ...styles.btnDown, marginTop: 20 }}
              >
                2.0.1版本
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <DownTitle
        name="Thunder IDE 1.0"
        date="2021-04-12"
        img="uploads/img/support-icon-05.png"
      />
      <div
        style={{
          padding: 20,
          paddingLeft: 40,
          background: "#F7F9FA",
          marginTop: 20,
        }}
      >
        <p style={{ fontSize: 16, color: "#727272" }}>
          《Thunder IDE 1.0》是一款适用于雷霆系列机器人的PC端编程软件，
          该软件专门针对中小学生学习编程而开发，采用Scratch积木语句和C++语言编程。
          Scratch编程只需拖动不同积木模块即可生成指令，降低了学习难度，
          让没学习过编程的人或低龄儿童也能轻松学习；多种声/光/形的编程模块自由组合，
          让孩子发挥想象，培养创造力和逻辑思维能力；同时支持积木实时转换C++代码功能，
          也可以直接使用C++语言编程，帮助孩子从编程小白到高手。
        </p>

        <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
          <img src="images/products/ide.png" style={{ width: "45%" }} />
          <div style={{ flex: 1 }}></div>
          <div style={{ display: "flex", textAlign: "center" }}>
            <div>
              <p style={styles.txt1}>下载Windows版</p>

              <a
                href={WIN_URL_1_2_1}
                className="support-shadow"
                style={{ ...styles.btnDown, marginTop: 20 }}
              >
                1.2.6版本
              </a>
            </div>
            <div style={{ marginLeft: 20 }}>
              <p style={styles.txt1}>下载Mac版</p>
              <a
                href={MAC_URL_1_2_1}
                className="support-shadow"
                style={{ ...styles.btnDown, marginTop: 20 }}
              >
                1.2.6版本
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <DownTitle
        name="Thunder GO"
        date="2020-07-30"
        img="uploads/img/support-icon-04.png"
      />
      <div style={{ padding: 20, background: "#F7F9FA", marginTop: 20 }}>
        <p style={{ fontSize: 16, color: "#727272", marginLeft: 20 }}>
          《Thunder
          Go》集构型搭建说明和操控玩法为一体，支持无线蓝牙连接，即下即玩，无需编程，简单上手。孩子直接通过手机或平板，轻松操控属于自己的智能机器人。{" "}
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <a style={{ width: "30%", color: "#3284FF" }}>
            <img src="uploads/img/QR_code2.png" />
            下载iOS版
          </a>
          <a
            href="http://docs.thunder.bellrobot.com/thunderInternetResource/ThunderGo.apk"
            style={{ width: "30%", marginLeft: 40, color: "#3284FF" }}
          >
            <img src="uploads/img/QR_code1.png" />
            下载Android版
          </a>
        </div>
      </div>
    </div>
  </div>
);

const IconTitle = ({ src, title, imgStyle }) => (
  <h2 style={{ fontSize: 24 }}>
    <img
      src={src}
      alt=""
      style={imgStyle ? imgStyle : { width: 35, marginRight: 20 }}
    />
    {title}
  </h2>
);

const Problem = () => (
  <div>
    <a className="target-fix" id="common-problem" name="common-problem"></a>
    <IconTitle title="常见问题" src="uploads/img/support-icon-01.png" />
    <div className="common-problem">
      <div className="question">
        <p>
          <img src="uploads/img/support-icon-06.png" alt="" />
          舵机安装和使用时需要注意哪些事项？
        </p>
      </div>
      <ul className="answer">
        <li>
          <p>
            1、安装舵机前,必须先将舵机连接至雷霆TBot-I控制器任意一个舵机接口，打开TBot-I电源开关，舵机将自动重置至其行程中心位置，避免其安装后TBot-I开机，舵机行程超出机器人结构限位。
          </p>
        </li>
        <li>
          <p>
            2、使用《Thunder
            Go》App操控有舵机的雷霆机器人时，必须先在操控界面设置舵机的上下限位置，设置的活动范围必须小于机械结构实际活动范围，避免舵机行程超出机器人结构限位。
          </p>
        </li>
        <li>
          <p>
            3、使用《Thunder
            IDE》软件对舵机进行编程时，必须先在线调试得到舵机的实际上下限位置和中位位置数值，IDE舵机限位语句设置的活动范围必须小于机械结构实际活动范围，避免舵机行程超出机器人结构限位。
          </p>
        </li>
        <li>
          <p>
            4、禁止舵机行程超出机器人结构限位，以避免舵机长时间堵转。舵机长时间堵转可能会导致TBot-I死机、舵机发热或损坏、电池发热、电量快速耗尽等情况发生。
          </p>
        </li>
      </ul>
    </div>
    <div className="common-problem">
      <div className="question">
        <p>
          <img src="uploads/img/support-icon-06.png" alt="" />
          防松螺母安装使用需要注意哪些事项？
        </p>
      </div>
      <ul className="answer">
        <li>
          <p>
            转动构件安装防松螺母必须保持其松紧适度，锁的太紧影响构件正常转动，锁的太松会使安装好的构件松松垮垮，被锁的相对转动构件间活动间隙一般推荐小于0.2mm。
          </p>
        </li>
      </ul>
    </div>
    <div className="common-problem">
      <div className="question">
        <p>
          <img src="uploads/img/support-icon-06.png" alt="" />
          履带版机器人在运动过程中履带容易掉，是怎么回事？
        </p>
      </div>
      <ul className="answer">
        <li>
          <p>
            装配问题,前后两个轮毂没有装配在一个平面上或者轮毂安装间隙太大会导致履带容易脱落。通过调节前后轮毂可解决此问题。
          </p>
        </li>
      </ul>
    </div>
    <div className="common-problem">
      <div className="question">
        <p>
          <img src="uploads/img/support-icon-06.png" alt="" />
          为什么手机或平板无法通过蓝牙连接机器人?
        </p>
      </div>
      <ul className="answer">
        <li>
          <p>
            1. 初次使用TBot-I控制器，开机后TBot-I默认运行《 Thunder
            Go》APP程序，若机器人有屏幕，蓝牙待连接时屏幕会显示蓝牙图标，连上后TBot-I会响三声。
          </p>
        </li>
        <li>
          <p>
            2.
            Tbot-I下载过用户程序后，若想用APP连接，需要使用IDE软件，点击“程序”菜单中的“恢复出厂固件”，把Tbot固件恢复出厂模式（即APP程序）。
          </p>
        </li>
        <li>
          <p>
            3.
            请检查移动终端任务栏是否有“允许蓝牙配对”的弹窗提示，请选择“允许蓝牙配对”。
          </p>
          <p>4. 机器人电池电量过低，请更换电池后尝试。</p>
          <p>5. 请尝试重启APP或TBot-I。</p>
        </li>
      </ul>
    </div>
    <div className="common-problem">
      <div className="question">
        <p>
          <img src="uploads/img/support-icon-06.png" alt="" />
          为什么机器人无法正常开机（屏幕/电源指示灯/状态指示灯无反应）?
        </p>
      </div>
      <ul className="answer">
        <li>
          <p> 1. 可能是电池电压不足导致机器人无法开机，请尝试更换电池。</p>
        </li>
        <li>
          <p>2. 请检查电池是否正确安装，电池盒是否松动。</p>
        </li>
      </ul>
    </div>
    <div className="common-problem">
      <div className="question">
        <p>
          <img src="uploads/img/support-icon-06.png" alt="" />
          机器人无法正常工作或TBot控制器发出“滴~滴~滴”告警声的原因有哪些?
        </p>
      </div>
      <ul className="answer">
        <li>
          <p>1. TBot-I电池电压过低导致。</p>
        </li>
        <li>
          <p>2. 机器人电机或者舵机长时间堵转导致。</p>
        </li>
      </ul>
    </div>
    <div className="common-problem">
      <div className="question">
        <p>
          <img src="uploads/img/support-icon-06.png" alt="" />
          《Thunder IDE》下载程序失败的原因有哪些？
        </p>
      </div>
      <ul className="answer">
        <li>
          <p>
            1. IDE安装路径建议保持默认C:\Thunder_IDE 或者修改为
            D:\Thunder_IDE。安装路径太长或路径中含有中文字符可能会导致编译下载失败。
          </p>
        </li>
        <li>
          <p>
            2. IDE安装过程中，若杀毒软件或 Windows 防火墙出现修改 DLL
            文件提醒，请选择“允许程序所有操作”。
          </p>
        </li>
        <li>
          <p>
            3. 电脑首次使用《Thunder
            IDE》软件，需要先安装驱动程序，点击软件菜单“帮助”下的“安装驱动”进行安装。驱动安装成功的判断标准：IDE
            软件左上角显示已连接。
          </p>
        </li>
        <li>
          <p>
            4.
            编译下载时，会产生临时文件“python27.dll”，个别杀毒软件（目前发现腾讯电脑管家）误报为木马并查杀，若出现
            IDE
            下载程序不成功时，建议判断是否被查杀，关闭杀毒软件或者将此文件添加信任可解决。
          </p>
        </li>
      </ul>
    </div>
  </div>
);
