import React, { Component } from "react";
import { Modal, Table, Badge, Button, Tooltip } from "antd";
import { connect } from "react-redux";
import {
  addParts,
  substractParts,
  clearParts,
  resetParts,
} from "../../redux/reducers/parts";
import { AddNums } from "./List";
import CloseOutlined from "@ant-design/icons/CloseOutlined";
import { exportExcel } from "./excel";
import { sendEmail } from "./Emal";
import Feedback from "./Feedback";

const initialColumns = [
  {
    title: "序号",
    dataIndex: "num",
    key: "num",
    width: 80,
    align: "center",
  },
  {
    title: "零件编号",
    dataIndex: "id",
    key: "id",
    align: "center",
  },
  {
    title: "名称",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "图片",
    dataIndex: "image",
    key: "image",
    align: "center",
    render: (text, recode) => {
      if (text !== null && text !== "" && text !== undefined) {
        return (
          <img
            src={text}
            style={{ maxHeight: 60, maxWidth: 60, objectFit: "contain" }}
          />
        );
      }
      return "";
    },
  },
  {
    title: "描述",
    dataIndex: "desc",
    key: "desc",
    ellipsis: true,
    width: 250,
    align: "center",
    render: (text, record) => {
      return <Tooltip title={text}>{text}</Tooltip>;
    },
  },
  {
    title: "分类",
    dataIndex: "category",
    key: "category",
    align: "center",
  },
];

class ShopCart extends Component {
  state = {
    visible: false,
    isEdit: false,
    showFeedback: false,
  };

  toggleTable = () => {
    this.setState({ visible: !this.state.visible });
  };

  showFeedback = (dataSource) => {
    this.dataSource = dataSource;
    this.setState({ showFeedback: true, visible: false });
  };

  hideFeed = (data) => {
    if (data.name) {
      const { totalCount, selectedParts } = this.props;
      const list = Object.values(selectedParts).map((item) => {
        return {
          新物料编号: item.id,
          物料名称: item.name,
          用户采购数量: item.count,
          最小起订数: item.minCount,
        };
      });
      let emailInfo = `电话: ${data.phone}; 地址: ${
        data.address === "-undefined" ? "暂无" : data.address
      }; ${data.remember ? "有" : "无"}合作意向的用户: ${
        data.name
      }; 用户备注: ${data.other ? data.other : "暂无"}; 
      总共${totalCount}件;  \n下载了零件清单如下: \n
      ${JSON.stringify(list)}  `;
      exportExcel(this.dataSource, [
        ...initialColumns,
        { title: "订购数量", dataIndex: "count" },
      ]);
      sendEmail(`雷霆官网零部件-${data.name}-${data.phone}`, emailInfo);
      this.props.onClearParts();
    }
    this.setState({ showFeedback: false });
  };

  onCancel = () => {
    this.setState({ isEdit: false });
    const beforeEditParts = localStorage.getItem("BEFORE_EDIT_PORTS");
    this.props.onResetParts(JSON.parse(beforeEditParts));
    localStorage.removeItem("BEFORE_EDIT_PORTS");
  };

  onOk = () => {
    this.setState({ isEdit: false });
    localStorage.removeItem("BEFORE_EDIT_PORTS");
  };

  onEdit = () => {
    this.setState({ isEdit: true });
    localStorage.setItem(
      "BEFORE_EDIT_PORTS",
      JSON.stringify({
        totalCount: this.props.totalCount,
        selectedParts: this.props.selectedParts,
      })
    );
  };

  componentWillUnmount() {
    localStorage.removeItem("BEFORE_EDIT_PORTS");
  }

  render() {
    const { visible, isEdit, showFeedback } = this.state;
    const {
      totalCount,
      selectedParts,
      onAddParts,
      onSubstractParts,
    } = this.props;

    const modalTitle = () => {
      return (
        <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
          {isEdit ? (
            <>
              <div />
              <div style={{ flex: 1 }} />
              <Button className="table-header-cancel" onClick={this.onCancel}>
                取消
              </Button>
              <Button
                className="table-header-ok"
                style={{ marginLeft: 10, backgroundColor: "#ffb640" }}
                onClick={this.onOk}
              >
                确定
              </Button>
            </>
          ) : (
            <>
              <span style={{ color: "black", opacity: 0.8 }}>
                已添加零部件清单
              </span>
              {totalCount > 0 ? (
                <>
                  <div style={{ flex: 1 }} />
                  <div
                    className="parts-table-clear"
                    onClick={this.props.onClearParts}
                  >
                    <img src="images/parts/clear.png" />
                    <span>一键清除</span>
                  </div>
                  <div className="parts-table-eidt" onClick={this.onEdit}>
                    <img src="images/parts/edit.png" />
                    <span>批量修改</span>
                  </div>
                </>
              ) : (
                <div style={{ flex: 1 }} />
              )}
            </>
          )}
          {isEdit ? null : (
            <div style={{ marginLeft: 30 }}>
              <CloseOutlined
                style={{ cursor: "pointer" }}
                onClick={() => this.setState({ visible: false })}
              />
            </div>
          )}
        </div>
      );
    };

    return (
      <div>
        <div
          style={{
            position: "fixed",
            bottom: 80,
            right: 100,
            width: 100,
            cursor: "pointer",
            zIndex: 999,
          }}
          onClick={this.toggleTable}
        >
          <Badge
            count={totalCount}
            className="shopBadge"
            offset={[-10, 10]}
            overflowCount={999}
          >
            <img
              src={`images/parts/${
                totalCount > 0 ? "shop_cart" : "shop_cart_grey"
              }.png`}
              style={{ height: 100 }}
            />
          </Badge>
        </div>
        {/* Modal框 */}
        <Modal
          centered
          maskClosable={false}
          visible={visible}
          footer={null}
          onCancel={this.toggleTable}
          width={1200}
          title={modalTitle()}
          bodyStyle={{ padding: 0 }}
          closable={false}
        >
          <PartTable
            totalCount={totalCount}
            selectedParts={selectedParts}
            onAddParts={onAddParts}
            onSubstractParts={onSubstractParts}
            isEdit={isEdit}
            showFeedback={this.showFeedback}
          />
        </Modal>
        <Feedback visible={showFeedback} onFinish={this.hideFeed} />
      </div>
    );
  }
}

/**
 * 有货的table组件
 */
const PartTable = ({
  totalCount,
  selectedParts,
  onAddParts,
  onSubstractParts,
  isEdit,
  showFeedback,
}) => {
  const dataSource = Object.values(selectedParts).map((item, index) => ({
    ...item,
    num: index + 1,
  }));
  const columns = () => {
    if (totalCount > 0) {
      return [
        ...initialColumns,
        {
          title: "订购数量",
          dataIndex: "count",
          key: "count",
          align: "center",
          render: (text, record) => {
            if (isEdit) {
              return (
                <AddNums
                  className="table-add-btn"
                  part={record}
                  onAddParts={onAddParts}
                  onSubstractParts={onSubstractParts}
                  selectedParts={selectedParts}
                />
              );
            } else {
              return text;
            }
          },
        },
      ];
    }
    return [];
  };
  return totalCount > 0 ? (
    <div>
      <div className="table-container">
        <Table
          rowClassName={(record, index) => {
            let classname = "shop-row";
            if (index % 2) {
              return (classname += " even-row");
            }
            return (classname += " odd-row");
          }}
          dataSource={dataSource}
          columns={columns()}
          rowKey="id"
          pagination={false}
          scroll={{ y: 550 }}
          onHeaderRow={(column) => {
            return {
              className: "shop-table-header",
            };
          }}
        />
      </div>

      <div className="parts-table-footer">
        <div>
          <h4>备注</h4>
          <span style={{ marginTop: 20, color: "#525252" }}>
            1.共计添加{Object.keys(selectedParts).length}种零部件，总计数量
            {totalCount}个。
          </span>
          <br />
          <span style={{ color: "#525252" }}>2.可下载清单发给客服询价。</span>
        </div>

        <Button
          onClick={() => {
            showFeedback && showFeedback(dataSource);
          }}
          className="down-btn"
          disabled={isEdit}
        >
          下载清单
        </Button>
      </div>
    </div>
  ) : (
    <EmptyTable />
  );
};

/**
 * 没货的样式组件
 */
const EmptyTable = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: 500,
      }}
    >
      <img src="images/parts/cart_empty.png" style={{ width: 196 }} />
      <span style={{ fontSize: 16, marginTop: 48 }}>
        赶快添加零部件到清单吧!
      </span>
    </div>
  );
};

const mapStatesToProps = (state) => ({
  selectedParts: state.parts.selectedParts,
  totalCount: state.parts.totalCount,
});

const mapDispatchToProps = (dispatch) => ({
  onAddParts: (adddPart) => dispatch(addParts(adddPart)),
  onSubstractParts: (substractPartID, minCount) =>
    dispatch(substractParts(substractPartID, minCount)),
  onClearParts: () => dispatch(clearParts()),
  onResetParts: (parts) => dispatch(resetParts(parts)),
});

export default connect(mapStatesToProps, mapDispatchToProps)(ShopCart);
