/**
 *作者：lorne
 *时间：2019/9/17
 *功能：
 */
import React,{Component,Fragment} from 'react'
import '../../css/partsshow.css'

export default class PartsShow extends Component {

  componentDidMount() {
    let $ = window.$
    document.title = '零部件'

    $(function () {

      var part01 = $("#part-001");
      var part02 = $("#part-002");
      $.getJSON("uploads/json/partsshow.json",function (data) {
        $.each(data,function (id, cont) {
          var currentPart;
          if(cont["target"]=="001"){
            currentPart = part01;
          }else if(cont["target"]=="002"){
            currentPart = part02;
          }else {
            return;
          }
          $.each(cont["array"], function (arrayid, arrayCont) {
           let tmpDemo = $("<div></div>");
            tmpDemo.addClass(arrayCont["series"]);
            tmpDemo.append("<p>" + arrayCont["name"] + "</p>");
           let r = 0;
            $.each(arrayCont["info"], function (infoid, infoVar) {
             let showCont = $("<div></div>");
              showCont.addClass("thu-show-grid col-lg-2 col-md-prec-25 col-md-prec-25 col-sm-3 col-xs-6");
             let img = $("<div></div>");
              img.attr("width", "172px");
              img.attr("height", "172px");
              img.css("background", "#f7f9f9 url(uploads/" + arrayCont["img"] + ") no-repeat " + (r % 5) * -172 + "px " + Math.trunc(r / 5) * -172 + "px");
              r += 1;
              let imgName = $("<p></p>").text(infoVar);
              showCont.append(img);
              showCont.append(imgName);
              tmpDemo.append(showCont);
            });
            currentPart.after(tmpDemo);
            currentPart = tmpDemo;
          });
        })
      })
    })
  }

  render(){
    return (<div className='Parts'>

      <div className="thu-partsshow-nav">
        <div className="thu-partsshow-nav-title">
          <p>零部件</p>
        </div>
      </div>


      <div className="thu-mechanical-parts">
        <img src="uploads/img/thu-device-icon-01.png"/>机械零件
        <a id="part-001" name="part-001"></a>
      </div>

      <div className="thu-mechanical-parts">
        <img src="uploads/img/thu-device-icon-02.png">
        </img>
        电子零件
        <a id="part-002" name="part-002"></a>
      </div>
      <div style={{height:60}}></div>
    </div>)
  }

}
