import React from 'react';
import './dialog.css';


export default class DialogContainer extends React.Component {
  render() {
    const { visible, onDissmiss } = this.props;
    return (
      <div className="blocklyDialogContainer" style={{ display: visible ? 'block' : 'none' }}>
        <div className="blocklyDialogMask" onClick={onDissmiss} />
        <div className="blocklyDialog">
          {this.props.children}
          <a className="blocklyDialogClose" onClick={onDissmiss}>
            <i></i>
          </a>
        </div>
      </div>
    );
  }
}
