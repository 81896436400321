import React, { Component } from "react";
import { BtnShaw } from "../dog/DogChild";

const styles = {
  title: {
    color: "#2A2A2A",
    fontSize: 36,
  },
  text: {
    color: "#2A2A2A",
    fontSize: 24,
  },
  titleW: {
    color: "#fff",
    fontSize: 36,
  },
  textW: {
    color: "#fff",
    fontSize: 24,
  },
};
export default class RacePlace extends Component {
  render() {
    return (
      <div>
        <div
          style={{
            background: "url(images/place/62003banner.png)",
            backgroundSize: "100% 100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingTop: 200,
            paddingBottom: 180,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "10%",
            }}
          >
            <img src="/images/place/62007header.png" style={{ width: "40%" }} />
            <div
              style={{
                width: 33,
                height: 4,
                background: "#F3981B",
                marginBottom: 20,
                marginTop: 20,
              }}
            ></div>
            <span style={{ fontSize: 24, color: "white" }}>
              62007 赛事场地包包含了比赛地图、冰壶道具等场地元素和 480 多个
            </span>
            <p style={{ fontSize: 24, color: "white" }}>
              Thunbot 零件，可搭建冰壶仓、公共冰壶仓、弹射障碍场地策略物。
            </p>
          </div>
        </div>
        <div
          style={{
            backgroundSize: "100% 100%",
            background: "url(images/place/bg_place2.png)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p style={{ ...styles.title, marginTop: 80 }}>62007 场地包有什么？</p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={"/images/place/place5_replace.png"}
              style={{ width: "45%" }}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p style={styles.text}>12 个蓝色冰壶和 12 个橙色冰壶；</p>
              <p style={styles.text}>1 张比赛场地地图；</p>
              <p style={styles.text}>1 张星星贴纸；</p>
              <p style={styles.text}>2 卷双面胶带；</p>
              <p style={styles.text}>
                1 把六角扳手和 3 个不同规格的 L 型扳手；
              </p>
              <p style={styles.text}>
                480+个铝合金结构件，主要包括梁、方梁、片材、紧固件；
              </p>

              <a
                target="_blank"
                href="https://docs.thunder.bellrobot.com/thunderInternetResource/document/62007《RA-极限冰壶》场地包零件清单.png"
                className="btn-h"
                style={{
                  height: 57,
                  paddingLeft: 33,
                  paddingRight: 33,
                  background: "#FF9C00",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "60%",
                  marginTop: 50,
                }}
              >
                <span style={styles.textW}>62007 场地包零件清单</span>
              </a>
            </div>
          </div>
        </div>
        <div
          className="parter"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background: "url(images/place/bg_place3.png)",
            backgroundSize: "100% 100%",
            paddingBottom: 100,
          }}
        >
          <p style={{ ...styles.titleW, marginTop: 80 }}>
            62007 场地包能做什么？
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 80,
            }}
          >
            <div style={{ width: "20%", marginRight: 20 }}>
              <p style={styles.textW}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;62007
                场地包零件主要用于搭建“RA-极限冰壶”赛事专用的策略物，搭建冰壶仓
                2 个、公 共冰壶仓 2 个、弹射障碍 2 个。
              </p>
              <p style={styles.textW}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;借助这些策略物制定不同规则和任务，参赛选手通过完成不同任务获取分数，最终赢得比赛。
              </p>
              <a
                target="_blank"
                href="https://docs.thunder.bellrobot.com/thunderInternetResource/document/62007《RA-极限冰壶》场地策略物搭建说明.rar"
                className="btn-h"
                style={{
                  height: 57,
                  paddingLeft: 33,
                  paddingRight: 33,
                  background: "#FF9C00",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 40,
                  width: 330,
                }}
              >
                <span style={styles.textW}>62007 策略物搭建说明</span>
              </a>
            </div>
            <img
              src="/images/place/place4_replace.png"
              style={{ width: "40%" }}
            />
          </div>
        </div>
        <div
          style={{
            background: "#000000",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 50,
            paddingBottom: 50,
          }}
        >
          <span style={{ marginBottom: 20, ...styles.titleW }}>
            62007 场地包使用场景
          </span>
          <img style={{ width: "70%" }} src="/images/place/place3.png"></img>
        </div>
        <div style={{ textAlign: "center", paddingTop: 80, paddingBottom: 80 }}>
          <p style={styles.title}>相关推荐</p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <BtnShaw
              src="/images/place/85001.png"
              imgStyle={{ height: 176, width: "auto" }}
              name="85001"
              desc={<div>“RA-极限冰壶” 挑战赛<br/>器材包</div>}
              href="85001"
            />
            {/* <div style={{ width: 80 }}></div> */}
            {/* <BtnShaw
              src="/images/place/85002.png"
              imgStyle={{ height: 176, width: "auto" }}
              name="85002"
              href="85002"
              desc="2020 WRC AI探索器材包"
              down_text="了解详情"
            /> */}
          </div>
        </div>
      </div>
    );
  }
}
