/**
 *作者：lorne
 *时间：2019/9/17
 *功能：
 */
import React, { Component, Fragment } from "react";
import { MAC_URL_1_2_1, WIN_URL_1_2_1 } from "../../config/utils";

export default class Product extends Component {
  componentDidMount() {
    let $ = window.$;
    document.title = "83001套装";
    $(".thunder-war-list").click(function() {
      $(this)
        .addClass("thunder-this")
        .siblings()
        .removeClass("thunder-this");
    });
    var oDiv = document.getElementById("thunder-img_screen");
    var imgWidth = document.getElementById("thunder-img-width");
    var isDown = false;
    var m_move_x, m_down_x, dx, md_x, ndx;

    oDiv.onmousedown = function(event) {
      event.preventDefault();

      //鼠标按下时的坐标与div坐标的偏移量
      isDown = true;

      //获取鼠标按下时的坐标
      m_down_x = event.pageX;
      //获取div的坐标
      dx = oDiv.offsetLeft;
      //获取鼠标与div偏移量
      md_x = m_down_x - dx;
    };
    document.onmousemove = function(event) {
      //实时更新div坐标
      dx = oDiv.offsetLeft;
      //获取鼠标移动实时坐标
      m_move_x = event.pageX;
      //鼠标按下时移动才触发
      if (isDown) {
        //获取新div坐标：鼠标实时坐标-鼠标与div的偏移量
        ndx = m_move_x - md_x;
        if (ndx <= -40) {
          oDiv.style.left = "-40px";
          imgWidth.style.width = "0";
          $(".thunder-war-intro p:first-of-type span").hide();
          $(".thunder-war-intro p:last-of-type span").show();
        } else if (ndx >= 740) {
          oDiv.style.left = "100%";
          imgWidth.style.width = "100%";
          $(".thunder-war-intro p:last-of-type span").hide();
          $(".thunder-war-intro p:first-of-type span").show();
        } else {
          oDiv.style.left = ndx + 17 + "px";
          imgWidth.style.width = ndx + "px";
          $(".thunder-war-intro p:last-of-type span").show();
          $(".thunder-war-intro p:first-of-type span").show();
        }
        //把新div坐标值赋给div对象
      }
    };
    document.onmouseup = function() {
      isDown = false;
    };
    $(function() {
      var i = 0;
      var timer;
      var slidlist = $(".ailen-slide-bg li");
      slidlist
        .eq(0)
        .show()
        .siblings(".ailen-slide-bg li")
        .hide();
      showTime();
      function showTime() {
        timer = setInterval(function() {
          Show();
          i++;
          if (i == 3) {
            i = 0;
          }
        }, 2000);
      }
      function Show() {
        slidlist
          .eq(i)
          .fadeIn(300)
          .siblings(".ailen-slide-bg li")
          .fadeOut(0);
      }

      var myVideo = document.getElementById("myvideo");
      myVideo.addEventListener("ended", function() {
        const videosrc = "//asset.bellrobot.com/thunder/video/video.mp4";
        myVideo.src = "";
        myVideo.src = videosrc;
      });
    });
  }

  render() {
    return (
      <div>
        <div className="thunder-banner">
          <div className="container">
            <div className="row thunder-banner-row">
              <div className="thunder-banner-con">
                <img src="uploads/img/bannercon.png" />
              </div>
              <div className="thunder-banner-text">
                <p>
                  83001是雷霆83003套件产品的进阶升级版。该产品拥有340多个通用化零部件，可搭建出至少14+种创意形态，充分满足孩子的创作需求。
                </p>
                <p>
                  高仿真工程车型，造型生动炫酷，模拟工程场景，让孩子体验工程项目的乐趣，畅想工程科技的未来；结合编程软件和配套课程，引导学生学习编程和多学科知识，培养创造力，锻炼动手思考能力，提高问题解决能力。
                </p>
              </div>
              <div className="thunder-banner-war hidden-xs">
                <img src="uploads/img/bannercon1.png" />
              </div>
              <ul className="thunder-banner-title hidden-xs">
                <li>7合1工程套装</li>
                <li>340+个零件</li>
                <li>14个官方构型</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row hidden-sm hidden-xs">
            {/*标题 */}
            <div className="thunder-title">
              <h2>14个炫酷构型，激发孩子对科学探索的兴趣</h2>
              <p>
                氧化金属材质搭配活力橙色的设计风格，使14个工程车型炫酷、有趣，
                <br />
                符合儿童容易被形象生动的事物所吸引的心理特点，激发儿童对科学探索的兴趣。
              </p>
            </div>

            {/*手风琴 */}
            <ul className="thunder-war hidden-md">
              <li className="thunder-war-list thunder-this">
                <h4>
                  01<span>.</span> —— Bulldozer
                </h4>
                <p>推土车</p>
                <a href="javascript:;">
                  <img src="uploads/img/sfq1.png" />
                </a>
                <span>
                  <img src="uploads/img/addsfq.png" />
                </span>
              </li>
              <li className="thunder-war-list">
                <h4>
                  02<span>.</span> —— Loader
                </h4>
                <p>装载车</p>
                <a href="javascript:;">
                  <img src="uploads/img/sfq2.png" />
                </a>
                <span>
                  <img src="uploads/img/addsfq.png" />
                </span>
              </li>
              <li className="thunder-war-list">
                <h4>
                  03<span>.</span> —— Explorer
                </h4>
                <p>探路者</p>
                <a href="javascript:;">
                  <img src="uploads/img/sfq3.png" />
                </a>
                <span>
                  <img src="uploads/img/addsfq.png" />
                </span>
              </li>
              <li className="thunder-war-list">
                <h4>
                  04<span>.</span> —— Thunder Armor
                </h4>
                <p>雷霆装甲</p>
                <a href="javascript:;">
                  <img src="uploads/img/sfq4.png" />
                </a>
                <span>
                  <img src="uploads/img/addsfq.png" />
                </span>
              </li>
              <li className="thunder-war-list">
                <h4>
                  05<span>.</span> —— Mechanical Arm
                </h4>
                <p>机械手</p>
                <a href="javascript:;">
                  <img src="uploads/img/sfq5.png" />
                </a>
                <span>
                  <img src="uploads/img/addsfq.png" />
                </span>
              </li>
              <li className="thunder-war-list">
                <h4>
                  06<span>.</span> —— Elytra Armor
                </h4>
                <p>鞘翅机甲</p>
                <a href="javascript:;">
                  <img src="uploads/img/sfq6.png" />
                </a>
                <span>
                  <img src="uploads/img/addsfq.png" />
                </span>
              </li>
              <li className="thunder-war-list">
                <h4>
                  07<span>.</span> —— Wild Bee
                </h4>
                <p>狂蜂战士</p>
                <a href="javascript:;">
                  <img src="uploads/img/sfq7.png" />
                </a>
                <span>
                  <img src="uploads/img/addsfq.png" />
                </span>
              </li>
            </ul>
          </div>
          {/*两版战车 */}
          <div className="row hidden-sm hidden-xs">
            <div className="col-xs-10 col-xs-offset-1">
              <div className="row thunder-img-slider">
                <div className="col-xs-10 col-xs-offset-1">
                  <div className="thunder-wheel">
                    <img src="uploads/img/con5.png" />
                  </div>
                  <div className="thunder-track" id="thunder-img-width">
                    <img src="uploads/img/con4.png" />
                  </div>
                  <div className="thunder-line" id="thunder-img_screen">
                    <img src="uploads/img/con6.png" />
                    <div className="thunder-slider-btn">
                      <img src="uploads/img/con7.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row thunder-war-intro">
              <p className="col-xs-6" style={{ textAlign: "left" }}>
                <span>装载车轮胎版</span>
              </p>
              <p className="col-xs-6" style={{ textAlign: "right" }}>
                <span>装载车履带版</span>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="thunder-title">
              <h2>模拟工程任务，体验机器与科技的魅力</h2>
              <p>
                模拟实物造型，结构明了，造型生动、炫酷； <br />
                铝合金氧化材质的铲斗、机械爪，耐磨有质感；
                <br />
                高仿真工程车型，让孩子自由发挥想象，通过编程或APP操控，体验工程的乐趣，学习工程知识和技能。
              </p>
            </div>
            <div className="col-xs-10 col-xs-offset-1">
              <video
                id="myvideo"
                controls="controls"
                width="100%"
                src="//asset.bellrobot.com/thunder/video/video.mp4"
                poster="uploads/img/videoimg.png"
              >
                your browser does not support the video tag
              </video>
            </div>
          </div>
        </div>
        <div className="thunder-con-part">
          <div className="container">
            <div className="row">
              <div className="col-sm-7">
                <div className="col-sm-11">
                  <img src="uploads/img/con1.png" />
                </div>
              </div>
              <div className="col-sm-4 thunder-part-right">
                <h3>340+个标准化、结构化、通用化零部件</h3>
                <p>
                  拥有80多种零件，数量超过340个，各种标准化、结构化、通用化机械零件和电子模块，还有机械手等扩展结构，实现更多更复杂的创意搭建。
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="thunder-title">
              <h2>脑洞大开，尽享创造的乐趣</h2>
              <p>
                从搭建、编程到操控，详尽的使用说明，一步步进行系统学习，逐步进阶高级创客。
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-offset-3 col-xs-6">
              <ul className="ailen-slide-bg">
                <li>
                  <img src="uploads/img/proimg_01.jpg" />
                </li>
                <li>
                  <img src="uploads/img/proimg_02.jpg" />
                </li>
                <li>
                  <img src="uploads/img/proimg_03.jpg" />
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="thunder-last-war">
              <img src="uploads/img/proimg_04.png" />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-offset-1 col-xs-10">
              <div className="col-xs-4">
                <div className="ailen-dwon">
                  <dl>
                    <dt>
                      <img src="uploads/img/ailenicon_01.png" />
                    </dt>
                    <dd>
                      搭建指南<span>83001套装官方搭建指南</span>
                    </dd>
                  </dl>
                  <div className="ailen-dwon-btn">
                    <button
                      className="ailen-btn-or"
                      onClick={() => {
                        window.location.href =
                          "//asset.bellrobot.com/thunder/document/dajian/83001搭建指南.rar";
                      }}
                    >
                      下载
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-xs-4">
                <div className="ailen-dwon">
                  <dl>
                    <dt>
                      <img src="uploads/img/ailenicon_02.png" />
                    </dt>
                    <dd>
                      Thunder IDE<span>图形化编程 丰富的硬件支持</span>
                    </dd>
                  </dl>
                  <div className="ailen-dwon-btn">
                    <button onClick={()=>{
                      window.location.href = WIN_URL_1_2_1
                    }}>
                      Windows版
                    </button>
                    <button onClick={()=>{
                      window.location.href = MAC_URL_1_2_1
                    }}>Mac版</button>
                  </div>
                </div>
              </div>
              <div className="col-xs-4">
                <div className="ailen-dwon">
                  <dl>
                    <dt>
                      <img src="uploads/img/ailenicon_03.png" />
                    </dt>
                    <dd>
                      Thunder Go<span>模型搭建教程 多种玩法操控</span>
                    </dd>
                  </dl>
                  <div className="ailen-dwon-btn">
                    <button>
                      <img src="uploads/img/QR_code2.png" />
                      iOS版
                    </button>
                    <button onClick={()=>{
                      window.location.href="http://docs.thunder.bellrobot.com/thunderInternetResource/ThunderGo.apk"
                    }}>
                      <img src="uploads/img/QR_code1.png" />
                      Android版
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ailen-foot-bg pro-foot-pad">
          <div className="container">
            <div className="row">
              <div className="thunder-title">
                <h2>配套教程资源，给孩子专业编程学习指导</h2>
                <p>
                  以AIQ核心理论为出发点，专业课程开发团队编写制作出符合不同年龄阶段的丰富课程和
                  <br />
                  配套教材、教案等教学资源，给学生、家长、老师提供详细的课程教学案例。
                  <br />
                  在课程中设计不同学科的奇妙知识，让兴趣成为学生动手创造和自发学习的动力，旨在让孩
                  <br />
                  子在轻松欢快的学习氛围中更好的学习编程，做到真正的寓教于乐。
                </p>
              </div>
              <div className="col-xs-10 col-xs-offset-1 thunder-guid">
                <div className="col-sm-4 col-xs-6">
                  <img src="uploads/img/con1.jpg" />
                </div>
                <div className="col-sm-4 col-xs-6">
                  <img src="uploads/img/con2.jpg" />
                </div>
                <div className="col-sm-4 col-xs-6">
                  <img src="uploads/img/con3.jpg" />
                </div>
                <div className="col-sm-4 col-xs-6">
                  <img src="uploads/img/con4.jpg" />
                </div>
                <div className="col-sm-4 col-xs-6">
                  <img src="uploads/img/con5.jpg" />
                </div>
                <div className="col-sm-4 col-xs-6">
                  <img src="uploads/img/con6.jpg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rev">
          <div className="thunder-foot-childer">
            <img src="uploads/img/con2.png" />
          </div>
          <div className="thunder-foot-war">
            <img src="uploads/img/con3.png" />
          </div>
          <div style={{ background: "#333333", height: 85 }} />
        </div>
      </div>
    );
  }
}
