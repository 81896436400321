import React, { useEffect } from "react";
import "./85002.scss";
import { BtnShaw } from "../../component/dog/DogChild";
import { remSet, remUnset } from "../../config/utils";

const T85002 = () => {
  useEffect(() => {
    remSet();
    return () => {
      remUnset();
    };
  }, []);
  return (
    <div className="t85002">
      <section>
        <img src="images/85002/bg1.png" />
        <div className="header">
          <img src="images/85002/icon1.png" />
          <img src="images/85002/icon2.png" />
          <img src="images/85002/icon3.png" />
          <span />
          <p>
            85002比赛器材包提供了1300+个Thunbot零部件，涉及梁类、齿轮类、紧固类、功能类、轴类、电子传感器等，支持每支参赛队伍搭建符合任务要求的1台机器人、1台机械臂，以便更好的完成“海洋挑战”任务；丰富的种类及足量的零部件为用户提供多种机器人搭建方案，使用户灵活发挥想象，创造更多可能性。
          </p>
        </div>
      </section>
      <section>
        <h3>85002器材包有什么？</h3>
        <img src="images/85002/bg2.png" />
        <a
          target="_blank"
          href={`${global.WWW_COM_DOC}85002-AI探索器材包零件清单.pdf`}
        >
          85002器材包零件清单 &gt;&gt;
        </a>
      </section>
      <section>
        <div>
          <h3>85002器材包能做什么？</h3>
          <p>
            85002器材包支持每支参赛队伍搭建符合任务要求的1台机器人+1台机械臂，以便更好的完成“海洋挑战”任务。丰富的种类及足量的零部件为用户提供多种机器人搭建方案，使用户灵活发挥想象，创造更多可能性。
          </p>
        </div>
        <img src="images/85002/bg3.png" />
      </section>
      <section>
        <div>
          <h3>课程服务</h3>
          <img src="images/85002/bg5.png" />
          <img className="img2" src="images/85002/bg6.png" />
        </div>
        <img src="images/85002/bg4.png" />
      </section>
      <section className="other">
        <span>相关推荐</span>
        <div>
          <BtnShaw
            src="/images/place/ic1.png"
            imgStyle={{ height: 176, width: "auto" }}
            name="62007"
            desc="RA-极限冰壶场地包"
            href="62007"
          />
          <div style={{ width: 80 }}></div>
          <BtnShaw
            src="/images/place/85001.png"
            imgStyle={{ height: 176, width: "auto" }}
            name="85001"
            href="85001"
            desc="RA-极限冰壶器材包"
            down_text="了解详情"
          />
        </div>
      </section>
    </div>
  );
};
export default T85002;
