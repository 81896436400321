import React from "react";
const styles = {
  title: {
    fontSize: 36,
    color: "#333",
    fontWeight: 500
  },
  content: {
    fontSize: 18,
    color: "#666"
  },
  pos_l: {
    width: "20%",
    position: "absolute",
    left: "15%",
    bottom: "10%"
  },
  title2: {
    fontSize: 24,
    color: "#333"
  },
  enter: {
    height: 60,
    width: 100,
    cursor: "pointer"
  }
};

const Lizard1 = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      background: "url(images/lizard/bg_lizard1.png) center no-repeat",
      paddingTop: 100
    }}
  >
    <div style={{ width: "45%" }}>
      <img src="/images/lizard/img_lizard2.png"></img>
    </div>
    <div style={{ width: "40%", marginTop: 100, marginLeft: -100 }}>
      <img src="/images/lizard/img_lizard1.png" />
    </div>
  </div>
);

const Lizard2 = () => (
  <div style={{ position: "relative" }}>
    <div
      style={{
        textAlign: "center",
        position: "absolute",
        right: 120,
        top: 260
      }}
    >
      <p style={{ ...styles.title, color: "#FFF" }}>重返森林纪</p>
      <p style={{ ...styles.content, color: "#fff" }}>
        我是一只小蜥蜴，我的家在森林里，那里绿树成荫、花草遍地、鸟语花香，还有
        <br />
        一条小溪，清澈见底，溪水哗哗地拍打着岸边。我的家园充满了生机......
      </p>
    </div>
    <img src="/images/lizard/img_lizard21.png" style={styles.pos_l} />
    <img src="/images/lizard/bg_lizard2.png"></img>
  </div>
);

const Lizard3 = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      background: "url(images/lizard/bg_lizard3.png) center no-repeat",
      paddingTop: 100,
      paddingBottom: 30
    }}
  >
    <div style={{ width: "40%" }}>
      <img src="/images/lizard/img_lizard21.png"></img>
    </div>
    <div style={{ marginTop: 100 }}>
      <p style={styles.title}>自我介绍</p>
      <div>
        <p style={styles.content}>
          名称：仿生蜥蜴（逃跑健将）
          <br></br>
          长：26cm
          <br></br> 体重：0.7kg
          <br></br>特点：逼真
        </p>
      </div>
    </div>
  </div>
);

class Lizard4 extends React.Component {
  state = {
    tab: 0
  };

  leave = () => {
    this.setState({ tab: 0 });
  };

  enter = tab => {
    this.setState({ tab });
  };
  render() {
    const { tab } = this.state;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: "url(images/lizard/bg_lizard4.png) center no-repeat",
          paddingTop: 50,
          paddingBottom: 80
        }}
      >
        <p style={{ ...styles.title, color: "#fff", marginBottom: 60 }}>
          自我剖析
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "relative"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between"
            }}
          >
            <img
              src="/images/dog/plugin1.png"
              style={{
                width: 130,
                visibility: tab === 1 ? "visible" : "hidden"
              }}
            />
            <img
              src="/images/dog/plugin4.png"
              style={{
                width: 130,
                visibility: tab === 2 ? "visible" : "hidden"
              }}
            />
            <img
              src="/images/dog/plugin2.png"
              style={{
                width: 130,
                visibility: tab === 3 ? "visible" : "hidden"
              }}
            />
            <img
              src="/images/dog/plugin8.png"
              style={{
                width: 130,
                visibility: tab === 4 ? "visible" : "hidden"
              }}
            />
          </div>
          <div
            style={{
              position: "absolute",
              width: 100,
              height: "100%",
              left: "20%"
            }}
          >
            <div
              style={{ ...styles.enter, marginTop: "15%" }}
              onMouseLeave={this.leave}
              onMouseEnter={() => this.enter(1)}
            ></div>

            <div
              style={{ ...styles.enter, marginTop: "55%" }}
              onMouseLeave={this.leave}
              onMouseEnter={() => this.enter(2)}
            ></div>
            <div
              style={{ ...styles.enter, marginTop: "220%" }}
              onMouseLeave={this.leave}
              onMouseEnter={() => this.enter(4)}
            ></div>
          </div>
          <div  style={{ width: "60%" }}>
          <img
            src="/images/lizard/img_lizard41.png"
          ></img>
          </div>
          <div
            style={{
              position: "absolute",
              width: 100,
              height: "100%",
              right: "18%"
            }}
          >
            <div
              style={{ ...styles.enter }}
              onMouseLeave={this.leave}
              onMouseEnter={() => this.enter(5)}
            ></div>

            <div
              style={{ ...styles.enter, marginTop: "400%" }}
              onMouseLeave={this.leave}
              onMouseEnter={() => this.enter(8)}
            ></div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between"
            }}
          >
            <img
              src="/images/dog/plugin5.png"
              style={{
                width: 130,
                visibility: tab === 5 ? "visible" : "hidden"
              }}
            />
            <img
              src="/images/dog/plugin4.png"
              style={{
                width: 130,
                visibility: tab === 6 ? "visible" : "hidden"
              }}
            />
            <img
              src="/images/dog/plugin3.png"
              style={{
                width: 130,
                visibility: tab === 7 ? "visible" : "hidden"
              }}
            />
            <img
              src="/images/dog/plugin9.png"
              style={{
                width: 130,
                visibility: tab === 8 ? "visible" : "hidden"
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
const Lizard5 = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      background: "#ECEBE9",
      paddingTop: 100,
      paddingBottom: 60
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        marginRight: 20,
        flex: 1
      }}
    >
       <img
        className="btn-big"
        src="/images/lizard/img_lizard52.png"
        style={{ width: "40%",flexShrink:0}}
      ></img>
         <img
        className="btn-big"
        src="/images/lizard/img_lizard51.png"
        style={{ width: "50%", marginTop: 20,flexShrink:0 }}
      ></img>
     
    </div>
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
      }}
    >
      <p style={styles.title}>自我展示</p>
      <img className="btn-big" src="/images/lizard/img_lizard53.png"
      style={{ width: "50%",flexShrink:0 }}></img>
      <img className="btn-big" src="/images/lizard/img_lizard54.png"
      style={{ width: "40%", marginTop: 20 ,flexShrink:0 }}></img>
     
    </div>
  </div>
);
const Lizard6 = () => (
  <div
    style={{
      paddingTop: 80,
      paddingBottom: 100,
      textAlign: "center"
    }}
  >
    <p style={styles.title}>寓教于乐</p>

    <div style={{ height: 100 }}></div>
    <div style={{ width: "70%", margin: "0 auto", display: "flex" }}>
      {[
        { name: "想象力", id: 1 },
        { name: "创造力", id: 2 },
        { name: "动手能力", id: 3 },
        { name: "编程能力", id: 4 }
      ].map((x, i) => (
        <div style={{ flex: 1 }} key={i}>
          <img
            src={`/images/lizard/ic_${x.id}.png`}
            style={{ height: 78, width: "auto" }}
          ></img>
          <div style={{ height: 50 }}></div>
          <p style={styles.title2}>{x.name}</p>
        </div>
      ))}
    </div>
  </div>
);
const Lizard7 = () => (
  <div
    style={{
      background: "#ECEBE9",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative"
    }}
  >
    <p
      style={{
        ...styles.content,
        position: "absolute",
        left: "30%"
      }}
    >
      逼真的外形，容易吸引孩子的兴趣，
      <br />
      有助于锻炼孩子的注意力，有利于
      <br />
      教师更好的开展沉浸式教学。
    </p>
    <img
      src="/images/lizard/bg_lizard7.png"
      style={{ height: 500, width: "auto"}}
    ></img>
   
  </div>
);

const Lizard8 = () => (
  <div
    style={{
      position: "relative",
      textAlign: "center"
    }}
  >
    <p
      style={{
        ...styles.content,
        position: "absolute",
        top: "42%",
        right: "18%"
      }}
    >
      让孩子自由发挥想象，探究真实动物的外形、习性，通过
      <br />
      搭建让想象变为现实，在逐步探究的过程中锻炼孩子思考
      <br />
      问题的能力，同时提高想象力、创造力。
    </p>
    <img
      src="/images/lizard/bg_lizard8.png"
      style={{ height: 500, width: "auto" }}
    ></img>
  </div>
);

const Lizard9 = () => (
  <div
    style={{
      height: 500,
      background: "#ECEBE9",
      position: "relative",
      display: "flex",
      justifyContent: "center",
      paddingTop: 100
    }}
  >
    <p
      style={{
        ...styles.content,
        position: "absolute",
        top: "58%",
        left: "20%"
      }}
    >
      通过编程让模仿真实动物具有的习性，
      <br />
      不仅让孩子加深对蜥蜴的认识，
      <br />
      还能锻炼孩子的逻辑思维、编程能力。
    </p>
    <img src="/images/lizard/bg_lizard9.png" style={{ width: "60%" }}></img>
  </div>
);
const Lizard10 = () => (
  <div
    style={{
      display: "flex"
    }}
  >
    <img
      src="/images/lizard/bg_lizard10.png"
      style={{ height: 500, width: "auto" }}
    ></img>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative"
      }}
    >
      <div style={{ width: "80%", textAlign: "center" }}>
        <p style={styles.content}>
          做为陪伴孩子的成长的玩具，除兼具教学价值外，也具有加强亲子之间的互动、激发孩子积极表达与合作，提高孩子沟通能力的价值。
        </p>
      </div>
      <div
        style={{ position: "absolute", top: "20%", right: "10%", zIndex: -1 }}
      >
        <img src="/images/lizard/ic_10.png"></img>
      </div>
    </div>
  </div>
);

export {
  Lizard1,
  Lizard2,
  Lizard3,
  Lizard4,
  Lizard5,
  Lizard6,
  Lizard7,
  Lizard8,
  Lizard9,
  Lizard10
};
