export const MenuData =[
    {
        "id": "cate1",
        "title": "电子零件",
        "value": "电子零件",
        "selectable": false,
        "children": [
            {
                "id": "cate1-1",
                "title": "控制类",
                "value": "控制类",
                "image": "",
                "nums": [
                    {
                        "id": "RPR000035",
                        "name": "控制器",
                        "remark": "控制器 TBot-I",
                        "desc": "雷霆机器人控制器Tbot-I，支持蓝牙4.2，包含2个编码电机、2个舵机、4个传感器、1个显示屏、1个UART接口；工作电压9V",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/控制器.png"
                    },
                    {
                        "id": "PRE000056",
                        "name": "蓝牙遥控手柄",
                        "remark": "蓝牙遥控手柄 63001",
                        "desc": "雷霆蓝牙遥控手柄，可支持自定义编程按键功能，支持蓝牙4.2，包含21组模拟和数字按键",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/蓝牙遥控手柄.png"
                    }
                ]
            },
            {
                "id": "cate1-2",
                "title": "电源类",
                "value": "电源类",
                "image": "",
                "nums": [
                    {
                        "id": "RPR000332",
                        "name": "锂电池",
                        "remark": "锂电池 63005",
                        "desc": "雷霆锂离子电池；工作电压9V；容量2300mAh；",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/锂电池.png"
                    },
                    {
                        "id": "PRE000065",
                        "name": "电源适配器",
                        "remark": "锂电池电源适配器",
                        "desc": "输入：100-240V~50/60Hz，0.6A；\n输出10V，2A；",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/电源适配器.png"
                    }
                ]
            },
            {
                "id": "cate1-3",
                "title": "动力类",
                "value": "动力类",
                "image": "",
                "nums": [
                    {
                        "id": "RPR000036",
                        "name": "编码电机",
                        "remark": "编码减速电机；",
                        "desc": "雷霆编码减速电机，通过编码器可闭环控制电机运动，堵转扭矩6Kg*cm；工作电压9V",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/编码电机.png"
                    },
                    {
                        "id": "PRE000062",
                        "name": "TT电机",
                        "remark": "TT电机",
                        "desc": "直流减速电机，减速比1：48，工作电压9V",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/TT电机.png"
                    },
                    {
                        "id": "RPR000324",
                        "name": "小型电机",
                        "remark": "小型电机",
                        "desc": "直流电机模块，内置控制器，可以对电机进行正反转及功率控制；工作电压5V",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/小型电机.png"
                    },
                    {
                        "id": "SAS000009",
                        "name": "MG995舵机",
                        "remark": "MG995数字舵机",
                        "desc": "雷霆数字舵机，运动范围0-180°，最大扭力15Kg*cm；工作电压5V；控制方式PWM",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/MG995舵机.png"
                    }
                ]
            },
            {
                "id": "cate1-4",
                "title": "传感器类",
                "value": "传感器类",
                "image": "",
                "nums": [
                    {
                        "id": "RPR000037",
                        "name": "显示屏",
                        "remark": "显示屏",
                        "desc": "雷霆LED显示屏由12×18个LED单色灯和2×6个LED全彩灯组成，支持对每一个灯进行控制；工作电压5V；通信协议I2C",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/显示屏.png"
                    },
                    {
                        "id": "PRE000074",
                        "name": "AI摄像头",
                        "remark": "AI视觉模块",
                        "desc": "AI摄像头；数字识别；标签识别；颜色识别；人脸识别；",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/AI摄像头.png"
                    },
                    {
                        "id": "RPR000038",
                        "name": "超声波传感器",
                        "remark": "超声波传感器",
                        "desc": "通过超声波来测距，测量范围5～250 cm；工作电压5V；通信协议I2C",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/超声波传感器.png"
                    },
                    {
                        "id": "RPR000300",
                        "name": "彩色传感器",
                        "remark": "彩色传感器",
                        "desc": "多功能传感器，可以检测环境光、反射光强度及R/G/B、H/S/V、8种指定颜色；工作电压5V；通信协议I2C",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/彩色传感器.png"
                    },
                    {
                        "id": "RPR000398",
                        "name": "RGB巡线传感器",
                        "remark": "RGB巡线传感器",
                        "desc": "4路RGB巡线传感器，支持4路检测，独立编程；工作电压5V；通信协议I2C",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/RGB巡线传感器.png"
                    },
                    {
                        "id": "RPR000323",
                        "name": "六轴传感器",
                        "remark": "六轴传感器",
                        "desc": "多功能传感器，具有3轴陀螺仪、3轴加速度计、姿态传感器、震动传感器功能；工作电压5V；通信协议I2C",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/六轴传感器.png"
                    },
                    {
                        "id": "RPR000365",
                        "name": "电子罗盘",
                        "remark": "电子罗盘",
                        "desc": "能检测绝对方向值，正北为0°，正南为180°；工作电压5V；通信协议I2C",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/电子罗盘.png"
                    },
                    {
                        "id": "RPR000363",
                        "name": "人体红外传感器",
                        "remark": "人体红外传感器",
                        "desc": "通过红外判断是否有人或物体移动，有移动输出1，否则输出0；工作电压5V；通信协议I2C",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/人体红外传感器.png"
                    },
                    {
                        "id": "RPR000364",
                        "name": "声音传感器",
                        "remark": "声音传感器",
                        "desc": "能检测周围环境中的声音大小，范围值为0（大致70分贝）～100（大致110分贝）；工作电压5V；通信协议I2C",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/声音传感器.png"
                    },
                    {
                        "id": "RPR000396",
                        "name": "环境温湿度传感器",
                        "remark": "环境温湿度传感器",
                        "desc": "能检测环境的温度和湿度；温度检测范围: -40℃～80℃；湿度检测范围: 0～100% RH；工作电压5V；通信协议I2C",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/环境温湿度传感器.png"
                    },
                    {
                        "id": "RPR000272",
                        "name": "触碰传感器",
                        "remark": "触碰传感器",
                        "desc": "多功能传感器，能检测按钮松开、按下、触碰三种不同的触碰状态，还具有一个可编程的RGB全彩灯；工作电压5V；通信协议I2C",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/触碰传感器.png"
                    },
                    {
                        "id": "RPR000395",
                        "name": "语音识别传感器",
                        "remark": "语音识别传感器",
                        "desc": "支持离线语音识别和判断，不需要联网，支持用户自定义修改语音识别关键词和语音应答；工作电压5V；通信协议I2C",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/语音识别传感器.png"
                    },
                    {
                        "id": "RPR000373",
                        "name": "电舵机扩展模块",
                        "remark": "电舵机扩展模块",
                        "desc": "支持扩展2个电机和2个舵机，模块需从锂电池单独供电；工作电压7.6V；通信协议UART",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/电舵机扩展模块.png"
                    },
                    {
                        "id": "RPR000372",
                        "name": "舵机扩展模块",
                        "remark": "舵机扩展模块",
                        "desc": "支持扩展4个舵机，模块需从锂电池单独供电；工作电压7.6V；通信协议I2C",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/舵机扩展模块.png"
                    },
                    {
                        "id": "RPR000376",
                        "name": "电机扩展模块",
                        "remark": "电机扩展模块",
                        "desc": "支持扩展4个电机，模块需从锂电池单独供电；工作电压7.6V；通信协议I2C",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/电机扩展模块.png"
                    },
                    {
                        "id": "RPR000310",
                        "name": "RJ25转接模块",
                        "remark": "RJ25转接模块",
                        "desc": "用来转接延长6P6C数据线",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/RJ25转接模块.png"
                    },
                    {
                        "id": "RPR000374",
                        "name": "电源扩展模块",
                        "remark": "电源扩展模块",
                        "desc": "电源扩展板，给其它扩展模块（电舵机、电机、舵机扩展模块等）供电而设计；",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/电源扩展模块.png"
                    }
                ]
            },
            {
                "id": "cate1-5",
                "title": "线材类",
                "value": "线材类",
                "image": "",
                "nums": [
                    {
                        "id": "CWR000065",
                        "name": "03000390",
                        "remark": "25cm传感器线",
                        "desc": "25cm传感器线；4P4C TO 4P4C",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/03000390.png"
                    },
                    {
                        "id": "CWR000090",
                        "name": "03000402",
                        "remark": "30cm传感器线",
                        "desc": "30cm传感器线；4P4C TO 4P4C",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/03000402.png"
                    },
                    {
                        "id": "CWR000098",
                        "name": "03000405",
                        "remark": "50cm传感器线",
                        "desc": "50cm传感器线；4P4C TO 4P4C",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/03000405.png"
                    },
                    {
                        "id": "CWR000066",
                        "name": "03000391",
                        "remark": "21cm显示器线",
                        "desc": "21cm显示器线；6P6C TO 6P6C",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/03000391.png"
                    },
                    {
                        "id": "CWR000091",
                        "name": "03000403",
                        "remark": "30cm显示器线",
                        "desc": "30cm显示器线；6P6C TO 6P6C",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/03000403.png"
                    },
                    {
                        "id": "CWR000099",
                        "name": "03000406",
                        "remark": "50cm显示器线",
                        "desc": "50cm显示器线；6P6C TO 6P6C",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/03000406.png"
                    },
                    {
                        "id": "CWR000123",
                        "name": "03000414",
                        "remark": "4P转6P传感器线",
                        "desc": "4P转6P传感器线；4P4C TO 6P6C",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/03000414.png"
                    },
                    {
                        "id": "CWR000067",
                        "name": "03000392",
                        "remark": "21cm编码电机线",
                        "desc": "21cm编码电机线；6P6C TO PH2.0-6P",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/03000392.png"
                    },
                    {
                        "id": "CWR000092",
                        "name": "03000404",
                        "remark": "30cm编码电机线",
                        "desc": "30cm编码电机线；6P6C TO PH2.0-6P",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/03000404.png"
                    },
                    {
                        "id": "CWR000100",
                        "name": "03000407",
                        "remark": "50cm编码电机线",
                        "desc": "50cm编码电机线；6P6C TO PH2.0-6P",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/03000407.png"
                    },
                    {
                        "id": "CWR000134",
                        "name": "03000413",
                        "remark": "AI摄像头线：",
                        "desc": "25cm AI摄像头线：4P4C水晶头-5264端子",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/03000413.png"
                    },
                    {
                        "id": "CWR000133",
                        "name": "03000412",
                        "remark": "锂电池外供电线，",
                        "desc": "25cm锂电池外供电线，XT30U母头-XT30U母头",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/03000412.png"
                    },
                    {
                        "id": "CWR000127",
                        "name": "03000394",
                        "remark": "编程线",
                        "desc": "编程线；micro usb 长度1米黑色",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/03000394.png"
                    }
                ]
            }
        ]
    },
    {
        "id": "cate0",
        "title": "机械零件",
        "value": "机械零件",
        "selectable": false,
        "children": [
            {
                "id": "cate0-1",
                "title": "功能类A",
                "value": "功能类A",
                "image": "",
                "nums": [
                    {
                        "id": "PRE000009",
                        "name": "02000160",
                        "remark": "张紧轮",
                        "desc": "张紧轮",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/02000160.png"
                    },
                    {
                        "id": "PRE000005",
                        "name": "02000132",
                        "remark": "轮毂（D型轴）",
                        "desc": "轮毂直径50，宽度30.0，中间为D型孔（配R01000273十字轴转D型轴轮毂转接件使用）",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/02000132.png"
                    },
                    {
                        "id": "PRE000079",
                        "name": "02000217",
                        "remark": "轮毂（十字轴）",
                        "desc": "轮毂直径50，宽度30.0，中间为十字孔（配十字轴使用）",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/02000217.png"
                    },
                    {
                        "id": "PRE000043",
                        "name": "02000190",
                        "remark": "大轮毂",
                        "desc": "轮毂直径80，宽度14.0",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/02000190.png"
                    },
                    {
                        "id": "PRE000055",
                        "name": "02000202",
                        "remark": "小轮胎",
                        "desc": "雷霆小号轮胎（配02000132轮毂使用）",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/02000202.png"
                    },
                    {
                        "id": "PRE000006",
                        "name": "02000146",
                        "remark": "左轮胎",
                        "desc": "左轮胎（配02000132轮毂使用）",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/02000146.png"
                    },
                    {
                        "id": "PRE000007",
                        "name": "02000165",
                        "remark": "右轮胎",
                        "desc": "右轮胎（配02000132轮毂使用）",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/02000165.png"
                    },
                    {
                        "id": "PRE000042",
                        "name": "02000189",
                        "remark": "大轮胎",
                        "desc": "轮胎直径94，宽度15.4（配02000190轮毂使用）",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/02000189.png"
                    },
                    {
                        "id": "PRE000061",
                        "name": "02000208",
                        "remark": "TT电机轮毂",
                        "desc": "TT电机轮毂，橙色轮毂，黑色轮胎",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/02000208.png"
                    },
                    {
                        "id": "PRE000076",
                        "name": "02000218",
                        "remark": "50mm全向轮",
                        "desc": "50mm全向轮（配R02000219全向轮联轴器使用）",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/02000218.png"
                    },
                    {
                        "id": "PRE000077",
                        "name": "02000219",
                        "remark": "全向轮联轴器",
                        "desc": "全向轮联轴器（配R02000218 50mm全向轮使用)",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/02000219.png"
                    },
                    {
                        "id": "PRE000010",
                        "name": "02000157",
                        "remark": "履带",
                        "desc": "履带",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/02000157.png"
                    },
                    {
                        "id": "PRE000008",
                        "name": "02000167",
                        "remark": "机械爪套",
                        "desc": "机械爪套",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/02000167.png"
                    },
                    {
                        "id": "PRE000063",
                        "name": "02000210",
                        "remark": "小万向轮",
                        "desc": "黑色万向轮，不锈钢滚珠",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/02000210.png"
                    },
                    {
                        "id": "RPR000042",
                        "name": "06000030",
                        "remark": "万向轮",
                        "desc": "万向轮",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/06000030.png"
                    },
                    {
                        "id": "PRE000011",
                        "name": "02000158",
                        "remark": "万向轮连接件",
                        "desc": "万向轮连接件",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/02000158.png"
                    },
                    {
                        "id": "FGE000053",
                        "name": "05000134",
                        "remark": "包胶轴承",
                        "desc": "包胶轴承",
                        "minCount": "#N/A",
                        "image": "uploads/img/thunbotImg/05000134.png"
                    }
                ]
            },
            {
                "id": "cate0-2",
                "title": "0808方梁",
                "value": "0808方梁",
                "image": "",
                "nums": [
                    {
                        "id": "HRE000117",
                        "name": "01000380",
                        "remark": "1单位单排方梁；",
                        "desc": "1单位单排方梁8mm",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000380.png"
                    },
                    {
                        "id": "HRE000118",
                        "name": "01000381",
                        "remark": "2单位单排方梁；",
                        "desc": "2单位单排方梁16mm",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000381.png"
                    },
                    {
                        "id": "HRE000119",
                        "name": "01000382",
                        "remark": "3单位单排方梁；",
                        "desc": "3单位单排方梁24mm",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000382.png"
                    },
                    {
                        "id": "HRE000120",
                        "name": "01000383",
                        "remark": "4单位单排方梁；",
                        "desc": "4单位单排方梁32mm",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000383.png"
                    },
                    {
                        "id": "HRE000121",
                        "name": "01000384",
                        "remark": "5单位单排方梁；",
                        "desc": "5单位单排方梁40mm",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000384.png"
                    },
                    {
                        "id": "HRE000122",
                        "name": "01000262",
                        "remark": "6单位单排方梁；",
                        "desc": "6单位单排方梁48mm",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000262.png"
                    },
                    {
                        "id": "HRE000123",
                        "name": "01000385",
                        "remark": "7单位单排方梁；",
                        "desc": "7单位单排方梁56mm",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000385.png"
                    },
                    {
                        "id": "HRE000124",
                        "name": "01000386",
                        "remark": "8单位单排方梁；",
                        "desc": "8单位单排方梁64mm",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000386.png"
                    },
                    {
                        "id": "HRE000125",
                        "name": "01000263",
                        "remark": "9单位单排方梁；",
                        "desc": "9单位单排方梁72mm",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000263.png"
                    },
                    {
                        "id": "HRE000126",
                        "name": "01000387",
                        "remark": "10单位单排方梁；",
                        "desc": "10单位单排方梁80mm",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000387.png"
                    },
                    {
                        "id": "HRE000127",
                        "name": "01000388",
                        "remark": "11单位单排方梁；",
                        "desc": "11单位单排方梁88mm",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000388.png"
                    },
                    {
                        "id": "HRE000231",
                        "name": "01000496",
                        "remark": "12单位单排方梁；",
                        "desc": "12单位单排方梁96mm",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000496.png"
                    },
                    {
                        "id": "HRE000128",
                        "name": "01000389",
                        "remark": "13单位单排方梁；",
                        "desc": "13单位单排方梁104mm",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000389.png"
                    },
                    {
                        "id": "HRE000129",
                        "name": "01000390",
                        "remark": "15单位单排方梁；",
                        "desc": "15单位单排方梁120mm",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000390.png"
                    },
                    {
                        "id": "HRE000130",
                        "name": "01000264",
                        "remark": "20单位单排方梁；",
                        "desc": "20单位单排方梁160mm",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000264.png"
                    },
                    {
                        "id": "HRE000131",
                        "name": "01000391",
                        "remark": "25单位单排方梁；",
                        "desc": "25单位单排方梁200mm",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/01000391.png"
                    },
                    {
                        "id": "HRE000132",
                        "name": "01000392",
                        "remark": "30单位单排方梁；",
                        "desc": "30单位单排方梁240mm",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/01000392.png"
                    },
                    {
                        "id": "HRE000240",
                        "name": "01000476",
                        "remark": "35单位单排方梁；",
                        "desc": "35单位单排方梁280mm",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/01000476.png"
                    },
                    {
                        "id": "HRE000241",
                        "name": "01000477",
                        "remark": "40单位单排方梁；",
                        "desc": "40单位单排方梁320mm",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/01000477.png"
                    },
                    {
                        "id": "HRE000242",
                        "name": "01000478",
                        "remark": "45单位单排方梁；",
                        "desc": "45单位单排方梁360mm",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/01000478.png"
                    },
                    {
                        "id": "HRE000243",
                        "name": "01000479",
                        "remark": "50单位单排方梁；",
                        "desc": "50单位单排方梁400mm",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/01000479.png"
                    },
                    {
                        "id": "HRE000244",
                        "name": "01000480",
                        "remark": "55单位单排方梁；",
                        "desc": "55单位单排方梁440mm",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/01000480.png"
                    },
                    {
                        "id": "HRE000245",
                        "name": "01000481",
                        "remark": "60单位单排方梁；",
                        "desc": "60单位单排方梁480mm",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/01000481.png"
                    }
                ]
            },
            {
                "id": "cate0-3",
                "title": "0816方梁",
                "value": "0816方梁",
                "image": "",
                "nums": [
                    {
                        "id": "HRE000133",
                        "name": "01000419",
                        "remark": "1单位双排方梁；",
                        "desc": "1单位双排方梁8mm",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000419.png"
                    },
                    {
                        "id": "HRE000134",
                        "name": "01000420",
                        "remark": "2单位双排方梁；",
                        "desc": "2单位双排方梁16mm",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000420.png"
                    },
                    {
                        "id": "HRE000135",
                        "name": "01000421",
                        "remark": "3单位双排方梁；",
                        "desc": "3单位双排方梁24mm",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000421.png"
                    },
                    {
                        "id": "HRE000136",
                        "name": "01000422",
                        "remark": "4单位双排方梁；",
                        "desc": "4单位双排方梁32mm",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000422.png"
                    },
                    {
                        "id": "HRE000137",
                        "name": "01000423",
                        "remark": "5单位双排方梁；",
                        "desc": "5单位双排方梁40mm",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000423.png"
                    },
                    {
                        "id": "HRE000138",
                        "name": "01000424",
                        "remark": "6单位双排方梁；",
                        "desc": "6单位双排方梁48mm",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000424.png"
                    },
                    {
                        "id": "HRE000139",
                        "name": "01000425",
                        "remark": "7单位双排方梁；",
                        "desc": "7单位双排方梁56mm",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000425.png"
                    },
                    {
                        "id": "HRE000140",
                        "name": "01000426",
                        "remark": "8单位双排方梁；",
                        "desc": "8单位双排方梁64mm",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000426.png"
                    },
                    {
                        "id": "HRE000141",
                        "name": "01000427",
                        "remark": "9单位双排方梁；",
                        "desc": "9单位双排方梁72mm",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000427.png"
                    },
                    {
                        "id": "HRE000142",
                        "name": "01000428",
                        "remark": "10单位双排方梁；",
                        "desc": "10单位双排方梁80mm",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000428.png"
                    },
                    {
                        "id": "HRE000143",
                        "name": "01000429",
                        "remark": "11单位双排方梁；",
                        "desc": "11单位双排方梁88mm",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000429.png"
                    },
                    {
                        "id": "HRE000144",
                        "name": "01000430",
                        "remark": "13单位双排方梁；",
                        "desc": "13单位双排方梁104mm",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000430.png"
                    },
                    {
                        "id": "HRE000145",
                        "name": "01000431",
                        "remark": "15单位双排方梁；",
                        "desc": "15单位双排方梁120mm",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000431.png"
                    },
                    {
                        "id": "HRE000146",
                        "name": "01000432",
                        "remark": "20单位双排方梁；",
                        "desc": "20单位双排方梁160mm",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000432.png"
                    },
                    {
                        "id": "HRE000147",
                        "name": "01000433",
                        "remark": "25单位双排方梁；",
                        "desc": "25单位双排方梁200mm",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/01000433.png"
                    },
                    {
                        "id": "HRE000148",
                        "name": "01000434",
                        "remark": "30单位双排方梁；",
                        "desc": "30单位双排方梁240mm",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/01000434.png"
                    },
                    {
                        "id": "HRE000246",
                        "name": "01000482",
                        "remark": "35单位双排方梁；",
                        "desc": "35单位双排方梁280mm",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/01000482.png"
                    },
                    {
                        "id": "HRE000247",
                        "name": "01000483",
                        "remark": "40单位双排方梁；",
                        "desc": "40单位双排方梁320mm",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/01000483.png"
                    },
                    {
                        "id": "HRE000248",
                        "name": "01000484",
                        "remark": "45单位双排方梁；",
                        "desc": "45单位双排方梁360mm",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/01000484.png"
                    },
                    {
                        "id": "HRE000249",
                        "name": "01000485",
                        "remark": "50单位双排方梁；",
                        "desc": "50单位双排方梁400mm",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/01000485.png"
                    },
                    {
                        "id": "HRE000250",
                        "name": "01000486",
                        "remark": "55单位双排方梁；",
                        "desc": "55单位双排方梁440mm",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/01000486.png"
                    },
                    {
                        "id": "HRE000251",
                        "name": "01000487",
                        "remark": "60单位双排方梁；",
                        "desc": "60单位双排方梁480mm",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/01000487.png"
                    }
                ]
            },
            {
                "id": "cate0-4",
                "title": "孔梁类",
                "value": "孔梁类",
                "image": "",
                "nums": [
                    {
                        "id": "HRE000237",
                        "name": "01000498",
                        "remark": "2孔孔梁；",
                        "desc": "2孔梁",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000498.png"
                    },
                    {
                        "id": "HRE000165",
                        "name": "01000242",
                        "remark": "3孔孔梁；",
                        "desc": "3孔梁",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000242.png"
                    },
                    {
                        "id": "HRE000167",
                        "name": "01000187",
                        "remark": "5孔孔梁；",
                        "desc": "5孔梁",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000187.png"
                    },
                    {
                        "id": "HRE000168",
                        "name": "01000282",
                        "remark": "6孔孔梁；",
                        "desc": "6孔梁",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000282.png"
                    },
                    {
                        "id": "HRE000169",
                        "name": "01000189",
                        "remark": "7孔孔梁",
                        "desc": "7孔梁",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000189.png"
                    },
                    {
                        "id": "HRE000170",
                        "name": "01000393",
                        "remark": "9孔孔梁",
                        "desc": "9孔梁",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000393.png"
                    },
                    {
                        "id": "HRE000171",
                        "name": "01000202",
                        "remark": "11孔孔梁",
                        "desc": "11孔梁",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000202.png"
                    },
                    {
                        "id": "HRE000205",
                        "name": "01000394",
                        "remark": "13孔孔梁",
                        "desc": "13孔梁",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000394.png"
                    },
                    {
                        "id": "HRE000234",
                        "name": "01000488",
                        "remark": "15孔孔梁",
                        "desc": "15孔梁",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000488.png"
                    },
                    {
                        "id": "HRE000235",
                        "name": "01000489",
                        "remark": "17孔孔梁",
                        "desc": "17孔梁",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000489.png"
                    },
                    {
                        "id": "HRE000236",
                        "name": "01000490",
                        "remark": "19孔孔梁",
                        "desc": "19孔梁",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000490.png"
                    }
                ]
            },
            {
                "id": "cate0-5",
                "title": "板梁类",
                "value": "板梁类",
                "image": "",
                "nums": [
                    {
                        "id": "HRE000215",
                        "name": "01000352",
                        "remark": "2×6板梁",
                        "desc": "2×6 板梁",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000352.png"
                    },
                    {
                        "id": "HRE000267",
                        "name": "01000532",
                        "remark": "2x7板梁",
                        "desc": "2x7 板梁",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000532.png"
                    },
                    {
                        "id": "HRE000266",
                        "name": "01000531",
                        "remark": "2x9板梁",
                        "desc": "2x9 板梁",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000531.png"
                    },
                    {
                        "id": "HRE000265",
                        "name": "01000376",
                        "remark": "2x11板梁",
                        "desc": "2x11 板梁",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000376.png"
                    },
                    {
                        "id": "HRE000209",
                        "name": "01000377",
                        "remark": "2×20板梁",
                        "desc": "2×20 板梁",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000377.png"
                    },
                    {
                        "id": "HRE000228",
                        "name": "01000455",
                        "remark": "3×3板梁",
                        "desc": "3×3 板梁",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000455.png"
                    },
                    {
                        "id": "HRE000211",
                        "name": "01000344",
                        "remark": "3×5板梁",
                        "desc": "3×5 板梁",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000344.png"
                    },
                    {
                        "id": "HRE000212",
                        "name": "01000345",
                        "remark": "3×7板梁",
                        "desc": "3×7 板梁",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000345.png"
                    },
                    {
                        "id": "HRE000213",
                        "name": "01000346",
                        "remark": "3×9板梁",
                        "desc": "3×9 板梁",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000346.png"
                    },
                    {
                        "id": "HRE000214",
                        "name": "01000353",
                        "remark": "3×11板梁",
                        "desc": "3×11 板梁",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000353.png"
                    },
                    {
                        "id": "HRE000210",
                        "name": "01000378",
                        "remark": "3×20板梁",
                        "desc": "3×20 板梁",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000378.png"
                    },
                    {
                        "id": "HRE000230",
                        "name": "01000471",
                        "remark": "TT电机安装板",
                        "desc": "TT电机安装板",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000471.png"
                    }
                ]
            },
            {
                "id": "cate0-6",
                "title": "齿轮类",
                "value": "齿轮类",
                "image": "",
                "nums": [
                    {
                        "id": "HRE000292",
                        "name": "01000116",
                        "remark": "8齿直齿；",
                        "desc": "8齿直齿齿轮",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000116.png"
                    },
                    {
                        "id": "HRE000293",
                        "name": "01000117",
                        "remark": "16齿直齿",
                        "desc": "16齿直齿齿轮",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000117.png"
                    },
                    {
                        "id": "HRE000294",
                        "name": "01000118",
                        "remark": "24齿直齿",
                        "desc": "24齿直齿齿轮",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/01000118.png"
                    },
                    {
                        "id": "HRE000295",
                        "name": "01000119",
                        "remark": "40齿直齿",
                        "desc": "40齿直齿齿轮",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/01000119.png"
                    },
                    {
                        "id": "HRE000296",
                        "name": "01000122",
                        "remark": "12齿混合齿",
                        "desc": "12齿混合齿齿轮",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000122.png"
                    },
                    {
                        "id": "HRE000297",
                        "name": "01000123",
                        "remark": "20齿混合齿",
                        "desc": "20齿混合齿齿轮",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/01000123.png"
                    },
                    {
                        "id": "HRE000298",
                        "name": "01000124",
                        "remark": "36齿混合齿",
                        "desc": "36齿混合齿齿轮",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/01000124.png"
                    },
                    {
                        "id": "HRE000299",
                        "name": "01000120",
                        "remark": "12齿伞齿",
                        "desc": "12齿伞齿齿轮",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000120.png"
                    },
                    {
                        "id": "HRE000300",
                        "name": "01000121",
                        "remark": "20齿伞齿",
                        "desc": "20齿伞齿齿轮",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/01000121.png"
                    },
                    {
                        "id": "HRE000301",
                        "name": "01000125",
                        "remark": "16齿过渡直齿轮",
                        "desc": "16齿过渡直齿轮",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000125.png"
                    },
                    {
                        "id": "HRE000302",
                        "name": "01000126",
                        "remark": "20齿过渡锥齿轮",
                        "desc": "20齿过渡锥齿轮",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000126.png"
                    },
                    {
                        "id": "HRE000044",
                        "name": "01000128",
                        "remark": "Rebot-蜗杆",
                        "desc": "蜗杆",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000128.png"
                    }
                ]
            },
            {
                "id": "cate0-7",
                "title": "齿条类",
                "value": "齿条类",
                "image": "",
                "nums": [
                    {
                        "id": "HRE000043",
                        "name": "01000127",
                        "remark": "Rebot-齿条",
                        "desc": "齿条",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000127.png"
                    },
                    {
                        "id": "HRE000046",
                        "name": "01000130",
                        "remark": "Rebot-链条",
                        "desc": "金属链条",
                        "minCount": "20",
                        "image": "uploads/img/thunbotImg/01000130.png"
                    },
                    {
                        "id": "HRE000047",
                        "name": "01000131",
                        "remark": "Rebot-履带",
                        "desc": "金属履带",
                        "minCount": "20",
                        "image": "uploads/img/thunbotImg/01000131.png"
                    },
                    {
                        "id": "HRE000256",
                        "name": "01000438",
                        "remark": "4孔齿条",
                        "desc": "4孔齿条",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000438.png"
                    },
                    {
                        "id": "HRE000255",
                        "name": "01000437",
                        "remark": "8孔齿条",
                        "desc": "8孔齿条",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000437.png"
                    },
                    {
                        "id": "HRE000260",
                        "name": "01000454",
                        "remark": "4孔M3沉头孔板",
                        "desc": "4孔M3沉头孔板",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000454.png"
                    },
                    {
                        "id": "HRE000257",
                        "name": "01000439",
                        "remark": "8孔M3沉头孔板",
                        "desc": "8孔M3沉头孔板",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000439.png"
                    },
                    {
                        "id": "HRE000259",
                        "name": "01000442",
                        "remark": "4孔M3螺纹板",
                        "desc": "4孔M3螺纹板",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000442.png"
                    },
                    {
                        "id": "HRE000258",
                        "name": "01000441",
                        "remark": "8孔M3螺纹板",
                        "desc": "8孔M3螺纹板",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000441.png"
                    }
                ]
            },
            {
                "id": "cate0-8",
                "title": "轴类",
                "value": "轴类",
                "image": "",
                "nums": [
                    {
                        "id": "HRE000149",
                        "name": "01000296",
                        "remark": "16mm十字轴；",
                        "desc": "16mm十字轴",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000296.png"
                    },
                    {
                        "id": "HRE000150",
                        "name": "01000297",
                        "remark": "24mm十字轴",
                        "desc": "24mm十字轴",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000297.png"
                    },
                    {
                        "id": "HRE000151",
                        "name": "01000298",
                        "remark": "32mm十字轴",
                        "desc": "32mm十字轴",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000298.png"
                    },
                    {
                        "id": "HRE000152",
                        "name": "01000337",
                        "remark": "40mm十字轴",
                        "desc": "40mm十字轴",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000337.png"
                    },
                    {
                        "id": "HRE000153",
                        "name": "01000445",
                        "remark": "48mm十字轴",
                        "desc": "48mm十字轴",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000445.png"
                    },
                    {
                        "id": "HRE000154",
                        "name": "01000364",
                        "remark": "56mm十字轴",
                        "desc": "56mm十字轴",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000364.png"
                    },
                    {
                        "id": "HRE000155",
                        "name": "01000365",
                        "remark": "64mm十字轴",
                        "desc": "64mm十字轴",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000365.png"
                    },
                    {
                        "id": "HRE000156",
                        "name": "01000446",
                        "remark": "72mm十字轴",
                        "desc": "72mm十字轴",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000446.png"
                    },
                    {
                        "id": "HRE000157",
                        "name": "01000366",
                        "remark": "80mm十字轴",
                        "desc": "80mm十字轴",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000366.png"
                    },
                    {
                        "id": "HRE000158",
                        "name": "01000447",
                        "remark": "88mm十字轴",
                        "desc": "88mm十字轴",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000447.png"
                    },
                    {
                        "id": "HRE000159",
                        "name": "01000448",
                        "remark": "96mm十字轴",
                        "desc": "96mm十字轴",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000448.png"
                    },
                    {
                        "id": "HRE000160",
                        "name": "01000449",
                        "remark": "104mm十字轴",
                        "desc": "104mm十字轴",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000449.png"
                    },
                    {
                        "id": "HRE000161",
                        "name": "01000299",
                        "remark": "108mm十字轴-两头车牙",
                        "desc": "108mm十字轴-两头车牙",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000299.png"
                    },
                    {
                        "id": "HRE000162",
                        "name": "01000450",
                        "remark": "112mm十字轴",
                        "desc": "112mm十字轴",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000450.png"
                    },
                    {
                        "id": "HRE000163",
                        "name": "01000451",
                        "remark": "120mm十字轴",
                        "desc": "120mm十字轴",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000451.png"
                    },
                    {
                        "id": "HRE000164",
                        "name": "01000452",
                        "remark": "160mm十字轴",
                        "desc": "160mm十字轴",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000452.png"
                    },
                    {
                        "id": "HRE000261",
                        "name": "01000491",
                        "remark": "200mm十字轴",
                        "desc": "200mm十字轴",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000491.png"
                    },
                    {
                        "id": "HRE000252",
                        "name": "01000501",
                        "remark": "240mm十字轴",
                        "desc": "240mm十字轴",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/01000501.png"
                    },
                    {
                        "id": "HRE000253",
                        "name": "01000502",
                        "remark": "280mm十字轴",
                        "desc": "280mm十字轴",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/01000502.png"
                    },
                    {
                        "id": "HRE000254",
                        "name": "01000503",
                        "remark": "320mm十字轴",
                        "desc": "320mm十字轴",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/01000503.png"
                    }
                ]
            },
            {
                "id": "cate0-9",
                "title": "拉簧类",
                "value": "拉簧类",
                "image": "",
                "nums": [
                    {
                        "id": "HRE000286",
                        "name": "01000560",
                        "remark": "0.8x8x30mm拉簧 ",
                        "desc": "0.8x8x30mm拉簧 ",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000560.png"
                    },
                    {
                        "id": "HRE000287",
                        "name": "01000561",
                        "remark": "0.8x8x35mm拉簧",
                        "desc": "0.8x8x35mm拉簧",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000561.png"
                    },
                    {
                        "id": "HRE000288",
                        "name": "01000562",
                        "remark": "0.8x8x70mm拉簧",
                        "desc": "0.8x8x70mm拉簧",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000562.png"
                    },
                    {
                        "id": "HRE000289",
                        "name": "01000563",
                        "remark": "0.8x8x90mm拉簧",
                        "desc": "0.8x8x90mm拉簧",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000563.png"
                    },
                    {
                        "id": "HRE000303",
                        "name": "01000564",
                        "remark": "0.6x6x35mm拉簧",
                        "desc": "0.6x6x35mm拉簧",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000564.png"
                    },
                    {
                        "id": "HRE000307",
                        "name": "01000565",
                        "remark": "0.3x3x45mm拉簧",
                        "desc": "0.3x3x45mm拉簧",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000565.png"
                    }
                ]
            },
            {
                "id": "cate0-10",
                "title": "异形梁",
                "value": "异形梁",
                "image": "",
                "nums": [
                    {
                        "id": "HRE000185",
                        "name": "01000192",
                        "remark": "3×3-120°梁",
                        "desc": "120°-3×3梁",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000192.png"
                    },
                    {
                        "id": "HRE000217",
                        "name": "01000354",
                        "remark": "4×4-120°梁",
                        "desc": "120° 4×4梁",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000354.png"
                    },
                    {
                        "id": "HRE000186",
                        "name": "01000288",
                        "remark": "双120°梁",
                        "desc": "双120°梁",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000288.png"
                    },
                    {
                        "id": "HRE000204",
                        "name": "01000350",
                        "remark": "借位梁",
                        "desc": "借位梁",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000350.png"
                    },
                    {
                        "id": "HRE000195",
                        "name": "01000295",
                        "remark": "T型梁",
                        "desc": "T型梁",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000295.png"
                    },
                    {
                        "id": "HRE000206",
                        "name": "01000347",
                        "remark": "2×4直角梁",
                        "desc": "2×4直角梁",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000347.png"
                    },
                    {
                        "id": "HRE000238",
                        "name": "01000499",
                        "remark": "3×3直角梁",
                        "desc": "3×3直角梁",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000499.png"
                    },
                    {
                        "id": "HRE000207",
                        "name": "01000349",
                        "remark": "3×5直角梁",
                        "desc": "3×5直角梁",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000349.png"
                    },
                    {
                        "id": "HRE000262",
                        "name": "01000201",
                        "remark": "3×3三角板",
                        "desc": "3×3三角板",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000201.png"
                    },
                    {
                        "id": "HRE000208",
                        "name": "01000200",
                        "remark": "4X4三角板",
                        "desc": "4X4三角板",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000200.png"
                    },
                    {
                        "id": "HRE000281",
                        "name": "01000553",
                        "remark": "5X7回形框架板；",
                        "desc": "5X7回形框架板",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000553.png"
                    },
                    {
                        "id": "HRE000180",
                        "name": "01000258",
                        "remark": "H型底板；",
                        "desc": "H型底板",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/01000258.png"
                    }
                ]
            },
            {
                "id": "cate0-11",
                "title": "功能类B",
                "value": "功能类B",
                "image": "",
                "nums": [
                    {
                        "id": "HRE000178",
                        "name": "01000272",
                        "remark": "自研电机安装板；",
                        "desc": "自研电机安装板",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000272.png"
                    },
                    {
                        "id": "HRE000179",
                        "name": "01000210",
                        "remark": "3x3L型支架；",
                        "desc": "3x3L型支架",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000210.png"
                    },
                    {
                        "id": "HRE000226",
                        "name": "01000470",
                        "remark": "U型框架板",
                        "desc": "U型框架板",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000470.png"
                    },
                    {
                        "id": "HRE000219",
                        "name": "01000363",
                        "remark": "2X2直角折弯连接件",
                        "desc": "2X2直角折弯连接件",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000363.png"
                    },
                    {
                        "id": "HRE000188",
                        "name": "01000255",
                        "remark": "2-7双直角折弯连接件",
                        "desc": "2-7双直角折弯连接件",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000255.png"
                    },
                    {
                        "id": "HRE000216",
                        "name": "01000362",
                        "remark": "单排U型曲梁",
                        "desc": "单排U型曲梁",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000362.png"
                    },
                    {
                        "id": "HRE000283",
                        "name": "01000555",
                        "remark": "2孔正交方梁",
                        "desc": "2孔正交型材梁16mm",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000555.png"
                    },
                    {
                        "id": "HRE000284",
                        "name": "01000556",
                        "remark": "3孔正交方梁",
                        "desc": "3孔正交型材梁24mm",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000556.png"
                    },
                    {
                        "id": "HRE000227",
                        "name": "01000453",
                        "remark": "T形借位方梁",
                        "desc": "T形借位方梁",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000453.png"
                    },
                    {
                        "id": "HRE000166",
                        "name": "01000284",
                        "remark": "舵机安装螺纹板；",
                        "desc": "舵机安装螺纹板；",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/01000284.png"
                    }
                ]
            },
            {
                "id": "cate0-12",
                "title": "功能类C",
                "value": "功能类C",
                "image": "",
                "nums": [
                    {
                        "id": "HRE000306",
                        "name": "01000566",
                        "remark": "4mm轴套（铁基）",
                        "desc": "4mm轴套（铁基）",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000566.png"
                    },
                    {
                        "id": "HRE000232",
                        "name": "01000375",
                        "remark": "8mm轴套",
                        "desc": "8mm轴套",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000375.png"
                    },
                    {
                        "id": "HRE000264",
                        "name": "01000529",
                        "remark": "16mm轴套",
                        "desc": "16mm轴套",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000529.png"
                    },
                    {
                        "id": "HRE000176",
                        "name": "01000281",
                        "remark": "舵盘（扭簧专用）",
                        "desc": "舵盘（扭簧专用）",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000281.png"
                    },
                    {
                        "id": "HRE000308",
                        "name": "01000567",
                        "remark": "舵盘（舵机专用）",
                        "desc": "舵盘（舵机专用）",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000567.png"
                    },
                    {
                        "id": "HRE000175",
                        "name": "01000285",
                        "remark": "舵机安装管套",
                        "desc": "舵机安装管套",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000285.png"
                    },
                    {
                        "id": "HTP000006",
                        "name": "01000286",
                        "remark": "舵机扭簧",
                        "desc": "舵机扭簧",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000286.png"
                    },
                    {
                        "id": "HRE000177",
                        "name": "01000291",
                        "remark": "舵机十字轴转接件",
                        "desc": "舵机十字轴转接件",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000291.png"
                    },
                    {
                        "id": "HRE000172",
                        "name": "01000273",
                        "remark": "十字轴转D型轴轮毂转接件",
                        "desc": "十字轴转D型轴轮毂转接件",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000273.png"
                    },
                    {
                        "id": "HRE000174",
                        "name": "01000198",
                        "remark": "十字孔梁",
                        "desc": "十字孔梁",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000198.png"
                    },
                    {
                        "id": "HRE000282",
                        "name": "01000554",
                        "remark": "十字轴连接件",
                        "desc": "十字轴连接件",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000554.png"
                    },
                    {
                        "id": "RPR000260",
                        "name": "06000017",
                        "remark": "Rebot-万向节",
                        "desc": "Rebot-万向节",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/06000017.png"
                    }
                ]
            },
            {
                "id": "cate0-13",
                "title": "功能类D",
                "value": "功能类D",
                "image": "",
                "nums": [
                    {
                        "id": "HRE000189",
                        "name": "01000279",
                        "remark": "机械手配MG995舵机手指",
                        "desc": "机械手配MG995舵机手指",
                        "minCount": "6",
                        "image": "uploads/img/thunbotImg/01000279.png"
                    },
                    {
                        "id": "HRE000190",
                        "name": "01000277",
                        "remark": "械手配MG995舵机齿轮右",
                        "desc": "械手配MG995舵机齿轮右",
                        "minCount": "6",
                        "image": "uploads/img/thunbotImg/01000277.png"
                    },
                    {
                        "id": "HRE000191",
                        "name": "01000278",
                        "remark": "械手配MG995舵机齿轮左",
                        "desc": "械手配MG995舵机齿轮左",
                        "minCount": "6",
                        "image": "uploads/img/thunbotImg/01000278.png"
                    },
                    {
                        "id": "HRE000275",
                        "name": "06000023",
                        "remark": "械手配MG995舵机基本板",
                        "desc": "械手配MG995舵机基本板",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/06000023.png"
                    },
                    {
                        "id": "HRE000194",
                        "name": "01000292",
                        "remark": "舵机安装支架",
                        "desc": "舵机安装支架",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000292.png"
                    },
                    {
                        "id": "HRE000218",
                        "name": "01000355",
                        "remark": "舵机支架共用板",
                        "desc": "舵机支架共用板",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/01000355.png"
                    },
                    {
                        "id": "HRE000280",
                        "name": "01000552",
                        "remark": "三四级等级考试框架板",
                        "desc": "三四级等级考试框架板",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/01000552.png"
                    },
                    {
                        "id": "HRE000278",
                        "name": "01000544",
                        "remark": "简易小车框架板",
                        "desc": "简易小车框架板",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/01000544.png"
                    }
                ]
            },
            {
                "id": "cate0-14",
                "title": "外观装饰类",
                "value": "外观装饰类",
                "image": "",
                "nums": [
                    {
                        "id": "HRE000181",
                        "name": "01000287",
                        "remark": "前面修饰外观件",
                        "desc": "前面修饰外观件",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/01000287.png"
                    },
                    {
                        "id": "HRE000182",
                        "name": "01000254",
                        "remark": "侧面装饰板",
                        "desc": "侧面装饰板",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/01000254.png"
                    },
                    {
                        "id": "HRE000183",
                        "name": "01000257",
                        "remark": "铲子钣金件",
                        "desc": "铲子钣金件",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/01000257.png"
                    },
                    {
                        "id": "HRE000193",
                        "name": "01000274",
                        "remark": "推土机推土板",
                        "desc": "推土机推土板",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/01000274.png"
                    },
                    {
                        "id": "HRE000184",
                        "name": "01000259",
                        "remark": "装饰支架",
                        "desc": "装饰支架",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/01000259.png"
                    },
                    {
                        "id": "HRE000187",
                        "name": "01000256",
                        "remark": "显示屏装夹板",
                        "desc": "显示屏装夹板",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/01000256.png"
                    }
                ]
            },
            {
                "id": "cate0-15",
                "title": "紧固类",
                "value": "紧固类",
                "image": "",
                "nums": [
                    {
                        "id": "HLS000049",
                        "name": "01000324",
                        "remark": "M2x2 几米螺丝",
                        "desc": "无头机米螺丝M2X2",
                        "minCount": "50",
                        "image": "uploads/img/thunbotImg/01000324.png"
                    },
                    {
                        "id": "HLS000076",
                        "name": "01000402",
                        "remark": "M2x4 十字螺丝",
                        "desc": "黑色十字螺丝M2*4",
                        "minCount": "50",
                        "image": "uploads/img/thunbotImg/01000402.png"
                    },
                    {
                        "id": "HLS000087",
                        "name": "01000413",
                        "remark": "M2x6 十字螺丝",
                        "desc": "黑色十字螺丝M2*6",
                        "minCount": "50",
                        "image": "uploads/img/thunbotImg/01000413.png"
                    },
                    {
                        "id": "HLS000085",
                        "name": "01000411",
                        "remark": "M3x6 十字螺丝",
                        "desc": "黑色十字螺丝M3*6",
                        "minCount": "50",
                        "image": "uploads/img/thunbotImg/01000411.png"
                    },
                    {
                        "id": "HLS000050",
                        "name": "01000325",
                        "remark": "M4x5 内六角螺丝",
                        "desc": "半圆头内六角螺丝M4X5",
                        "minCount": "20",
                        "image": "uploads/img/thunbotImg/01000325.png"
                    },
                    {
                        "id": "HLS000142",
                        "name": "01000571",
                        "remark": "M4x6 内六角螺丝",
                        "desc": "半圆头内六角螺丝M4X6",
                        "minCount": "20",
                        "image": "uploads/img/thunbotImg/01000571.png"
                    },
                    {
                        "id": "HLS000051",
                        "name": "01000326",
                        "remark": "M4x8 内六角螺丝",
                        "desc": "半圆头内六角螺丝M4X8",
                        "minCount": "20",
                        "image": "uploads/img/thunbotImg/01000326.png"
                    },
                    {
                        "id": "HLS000052",
                        "name": "01000327",
                        "remark": "M4x10 内六角螺丝",
                        "desc": "半圆头内六角螺丝M4X10",
                        "minCount": "20",
                        "image": "uploads/img/thunbotImg/01000327.png"
                    },
                    {
                        "id": "HLS000053",
                        "name": "01000301",
                        "remark": "M4x12 内六角螺丝",
                        "desc": "半圆头内六角螺丝M4X12",
                        "minCount": "20",
                        "image": "uploads/img/thunbotImg/01000301.png"
                    },
                    {
                        "id": "HLS000054",
                        "name": "01000302",
                        "remark": "M4x14 内六角螺丝",
                        "desc": "半圆头内六角螺丝M4X14",
                        "minCount": "20",
                        "image": "uploads/img/thunbotImg/01000302.png"
                    },
                    {
                        "id": "HLS000055",
                        "name": "01000303",
                        "remark": "M4x16 内六角螺丝",
                        "desc": "半圆头内六角螺丝M4X16",
                        "minCount": "20",
                        "image": "uploads/img/thunbotImg/01000303.png"
                    },
                    {
                        "id": "HLS000056",
                        "name": "01000304",
                        "remark": "M4x18 内六角螺丝",
                        "desc": "半圆头内六角螺丝M4X18",
                        "minCount": "20",
                        "image": "uploads/img/thunbotImg/01000304.png"
                    },
                    {
                        "id": "HLS000057",
                        "name": "01000305",
                        "remark": "M4x20 内六角螺丝",
                        "desc": "半圆头内六角螺丝M4X20",
                        "minCount": "20",
                        "image": "uploads/img/thunbotImg/01000305.png"
                    },
                    {
                        "id": "HLS000114",
                        "name": "01000525",
                        "remark": "M4x25 内六角螺丝",
                        "desc": "半圆头内六角螺丝M4X25",
                        "minCount": "20",
                        "image": "uploads/img/thunbotImg/01000525.png"
                    },
                    {
                        "id": "HLS000115",
                        "name": "01000526",
                        "remark": "M4x30 内六角螺丝",
                        "desc": "半圆头内六角螺丝M4X30",
                        "minCount": "20",
                        "image": "uploads/img/thunbotImg/01000526.png"
                    },
                    {
                        "id": "HLS000116",
                        "name": "01000527",
                        "remark": "M4x35 内六角螺丝",
                        "desc": "半圆头内六角螺丝M4X35(半牙)",
                        "minCount": "20",
                        "image": "uploads/img/thunbotImg/01000527.png"
                    },
                    {
                        "id": "HLS000117",
                        "name": "01000528",
                        "remark": "M4x40 内六角螺丝",
                        "desc": "半圆头内六角螺丝M4X40",
                        "minCount": "20",
                        "image": "uploads/img/thunbotImg/01000528.png"
                    },
                    {
                        "id": "HLS000137",
                        "name": "01000558",
                        "remark": "M4x50 内六角螺丝",
                        "desc": "半圆头内六角螺丝M4X50",
                        "minCount": "20",
                        "image": "uploads/img/thunbotImg/01000558.png"
                    },
                    {
                        "id": "HLS000132",
                        "name": "01000550",
                        "remark": "PWA2.3x22 十字自攻螺丝",
                        "desc": "PWA2.3x22 十字自攻螺丝（TT电机轮毂安装螺丝）",
                        "minCount": "20",
                        "image": "uploads/img/thunbotImg/01000550.png"
                    },
                    {
                        "id": "HLS000136",
                        "name": "01000557",
                        "remark": "M3x7.5 十字沉头螺丝",
                        "desc": "M3x7.5 十字沉头螺丝（齿条专用）",
                        "minCount": "20",
                        "image": "uploads/img/thunbotImg/01000557.png"
                    },
                    {
                        "id": "HLS000141",
                        "name": "01000547",
                        "remark": "M3x22 十字螺丝",
                        "desc": "M3x22 十字螺丝（TT电机安装螺丝）",
                        "minCount": "20",
                        "image": "uploads/img/thunbotImg/01000547.png"
                    },
                    {
                        "id": "HLS000107",
                        "name": "01000568",
                        "remark": "M3x25 十字螺丝",
                        "desc": "半圆头内六角螺丝M3X25",
                        "minCount": "20",
                        "image": "uploads/img/thunbotImg/01000568.png"
                    },
                    {
                        "id": "HLS000061",
                        "name": "01000315",
                        "remark": "1mm M4垫片",
                        "desc": "1mm M4垫片",
                        "minCount": "50",
                        "image": "uploads/img/thunbotImg/01000315.png"
                    },
                    {
                        "id": "HRE000291",
                        "name": "01000260",
                        "remark": "2mm M4垫片（铁基）",
                        "desc": "2mm M4垫片（铁基）",
                        "minCount": "50",
                        "image": "uploads/img/thunbotImg/01000260.png"
                    },
                    {
                        "id": "HLS000064",
                        "name": "01000314",
                        "remark": "M4 六角螺母；",
                        "desc": "M4 六角螺母；",
                        "minCount": "50",
                        "image": "uploads/img/thunbotImg/01000314.png"
                    },
                    {
                        "id": "HLS000063",
                        "name": "01000313",
                        "remark": "M4 防松螺母；",
                        "desc": "M4 防松螺母；",
                        "minCount": "50",
                        "image": "uploads/img/thunbotImg/01000313.png"
                    }
                ]
            },
            {
                "id": "cate0-16",
                "title": "工具类",
                "value": "工具类",
                "image": "",
                "nums": [
                    {
                        "id": "HRE000221",
                        "name": "05000026",
                        "remark": "0.9mm-L型扳手（M2无头内六角）",
                        "desc": "0.9mm-L型扳手（M2无头内六角）",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/05000026.png"
                    },
                    {
                        "id": "HRE000271",
                        "name": "05000118",
                        "remark": "1.5mm-球头L型扳手（M2内六角）",
                        "desc": "1.5mm-球头L型扳手（M2内六角）",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/05000118.png"
                    },
                    {
                        "id": "HRE000270",
                        "name": "05000117",
                        "remark": "2mm-球头L型扳手（M4内六角）",
                        "desc": "2mm-球头L型扳手（M4内六角）",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/05000117.png"
                    },
                    {
                        "id": "HRE000269",
                        "name": "05000116",
                        "remark": "2.5mm-球头L型扳手（M4内六角）",
                        "desc": "2.5mm-球头L型扳手（M4内六角）",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/05000116.png"
                    },
                    {
                        "id": "HWP000002",
                        "name": "01000319",
                        "remark": "M4平板扳手",
                        "desc": "M4平板扳手",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/01000319.PNG"
                    },
                    {
                        "id": "HRE000305",
                        "name": "05000169",
                        "remark": "M4十字套筒",
                        "desc": "M4十字套筒",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/05000169.png"
                    },
                    {
                        "id": "HRE000304",
                        "name": "05000168",
                        "remark": "0.9mm-几米螺丝刀（M2内六角）",
                        "desc": "0.9mm-几米螺丝刀（M2内六角）",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/05000168.png"
                    },
                    {
                        "id": "HRE000274",
                        "name": "05000125",
                        "remark": "可换头内六角/十字螺丝刀",
                        "desc": "可换头内六角/十字螺丝刀",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/05000125.png"
                    }
                ]
            },
            {
                "id": "cate0-17",
                "title": "辅助类",
                "value": "辅助类",
                "image": "",
                "nums": [
                    {
                        "id": "PRE000048",
                        "name": "05000109",
                        "remark": "蓝色冰壶",
                        "desc": "蓝色冰壶；直径30mm",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/05000109.png"
                    },
                    {
                        "id": "PRE000049",
                        "name": "05000110",
                        "remark": "橙色冰壶",
                        "desc": "橙色冰壶；直径30mm",
                        "minCount": "5",
                        "image": "uploads/img/thunbotImg/05000110.png"
                    },
                    {
                        "id": "BST000132",
                        "name": "05000114",
                        "remark": "极限冰壶赛之星星贴纸",
                        "desc": "极限冰壶赛之星星贴纸",
                        "minCount": "10",
                        "image": "uploads/img/thunbotImg/05000114.jpg"
                    },
                    {
                        "id": "EGE000017",
                        "name": "05000172",
                        "remark": "白色橡皮筋",
                        "desc": "白色橡皮筋；宽度1.5mm，厚度1.5mm，圆形直径50mm",
                        "minCount": "50",
                        "image": "uploads/img/thunbotImg/05000172.png"
                    },
                    {
                        "id": "BCA000017",
                        "name": "05000174",
                        "remark": "色卡",
                        "desc": "雷霆色卡,共4张，8种颜色（红、黄、绿、青、蓝、紫，黑、白）",
                        "minCount": "2",
                        "image": "uploads/img/thunbotImg/05000174.png"
                    }
                ]
            },
            {
                "id": "cate0-18",
                "title": "地图类",
                "value": "地图类",
                "image": "",
                "nums": [
                    {
                        "id": "BMP000024",
                        "name": "05000173",
                        "remark": "巡线地图",
                        "desc": "雷霆巡线地图；100cm×60cm",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/05000173.png"
                    },
                    {
                        "id": "BMP000167",
                        "name": "2022年极限冰壶赛事地图",
                        "remark": "极限冰壶2022赛纸，灯布，表面哑光，2.4m*2.4m",
                        "desc": "2022年极限冰壶赛事地图；2.4m*2.4m",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/2022年极限冰壶赛事地图.png"
                    },
                    {
                        "id": "BMP000171",
                        "name": "2022年极限冰壶普及赛地图",
                        "remark": "极限冰壶普及赛2022赛纸，灯布，表面哑光，1.2m*2.4m",
                        "desc": "2022年极限冰壶普及赛地图；2.4m*2.4m",
                        "minCount": "1",
                        "image": "uploads/img/thunbotImg/2022年极限冰壶普及赛地图.png"
                    }
                ]
            }
        ]
    }
]