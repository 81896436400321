/**
 *作者：lorne
 *时间：2019/9/4
 *功能：
 */
import React from "react";
import Base from "../Base";

export default class Home extends Base {
  componentReady() {
    var swiper = new window.Swiper(".swiper-container", {
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      watchSlidesProgress: true,
      loop: true,
      loopFillGroupWithBlank: true,
      slidesPerView: 5,
      slidesPerGroup: 3,
      loopAdditionalSlides: 100,
      autoplayDisableOnInteraction: false,
      autoplay: {
        delay: 6000 //1秒切换一次
      },
      navigation: {
        prevEl: ".swiper-button-prev",
        nextEl: ".swiper-button-next"
      }
    });

    (function($ = window.$) {
      $(".carousel").carousel({
        interval: 5000
      });
      $(".index-intro-btn").click(function() {
        $(this).css("background", "#b26d00");
      });
      $(".idx-edu-btn").click(function() {
        window.location.href = "education";
      });
      $(".idx-match-btn").click(function() {
        window.location.href = "dasai";
      });
      $(".tab-con dl")
        .eq(0)
        .show();
      $(".tab-title li").click(function() {
        var i = $(this).index();
        $(".tab")
          .find(".tab-con dl")
          .eq(i)
          .show()
          .siblings()
          .hide();
        $(this)
          .addClass("tab-this")
          .siblings()
          .removeClass("tab-this");
      });

      $(".index-war-tab li a").on("click", function() {
        var path = $(this).children("img")[0].src;
        $("#myModal img").attr("src", path);
      });
    })(window.jQuery);

    swiper.el.onmouseover = function() {
      //鼠标放上暂停轮播
      swiper.autoplay.stop();
    };
    swiper.el.onmouseleave = function() {
      swiper.autoplay.start();
    };
  }

  renderPage() {
    return (
      <div>
        <div className="">
          <div
            id="carousel-example-generic"
            className="carousel slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators">
              <li
                data-target="#carousel-example-generic"
                data-slide-to="0"
                className="active"
              ></li>
              <li
                data-target="#carousel-example-generic"
                data-slide-to="1"
              ></li>
              <li
                data-target="#carousel-example-generic"
                data-slide-to="2"
              ></li>
              <li
                data-target="#carousel-example-generic"
                data-slide-to="3"
              ></li>
                <li
                data-target="#carousel-example-generic"
                data-slide-to="4"
              ></li>
            </ol>
            <div className="carousel-inner" role="listbox">
              <div
                className="item active"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.props.history.push("/83005");
                }}
              >
                <img src="uploads/img/idxbanner4.png" style={{ height: 600 }} />
              </div>
              <div className="item">
                <div style={{ position: "relative" }}>
                  <div style={{ position: "absolute", width: "100%",display:'flex'}}>
                    <div
                      style={{ width: "25%", height: 600, cursor: "pointer" }}
                      onClick={() => {
                        this.props.history.push("/dog");
                      }}
                    />
                    <div
                      style={{ width: "25%", height: 600, cursor: "pointer" }}
                      onClick={() => {
                        this.props.history.push("/lizard");
                      }}
                    />
                    <div
                      style={{ width: "25%", height: 600, cursor: "pointer" }}
                      onClick={() => {
                        this.props.history.push("/bat");
                      }}
                    />
                     <div
                      style={{ width: "25%", height: 600, cursor: "pointer" }}
                      onClick={() => {
                        this.props.history.push("/motor");
                      }}
                    />
                  </div>
                  <img src="images/banner1.png" style={{ height: 600 }} />
                </div>
              </div>
              <div className="item">
                <img src="uploads/img/idxbanner.png" style={{ height: 600 }} />
              </div>
              <div className="item">
                <img src="uploads/img/idxbanner2.png" style={{ height: 600 }} />
              </div>
              <div className="item">
                <img src="uploads/img/idxbanner5.png" style={{ height: 600 }} />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="thunder-title">
              <h2>可拼装、可编程、可遥控的系列机器人</h2>
              <p>
                雷霆系列是集教学、创意、赛事与娱乐为一体的可编程机器人系列产品，
                <br />
                适用于8岁以上的学龄儿童和青少年；
                <br />
                该产品拥有丰富的标准化、系列化、通用化各形结构件和电子模块，
                <br />
                可搭建出多种题材丰富、生动有趣的构型，同时也是RA国际机器人大赛的专属器材。
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1 tab">
              <div className="col-sm-3">
                <ul className="tab-title">
                  <li className="tab-this">
                    <a href="javascript:;">
                      <img src="uploads/img/idxicon.png" />
                      <p>STEAM教育和创客教育套件</p>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;">
                      <img src="uploads/img/idxicon1.png" />
                      <p>系列化零部件</p>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;">
                      <img src="uploads/img/idxicon2.png" />
                      <p>图形化编程软件与APP</p>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;">
                      <img src="uploads/img/idxicon3.png" />
                      <p>系统化课程与教材</p>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-9">
                <div className="tab-con">
                  <dl>
                    <dt>
                      <img src="uploads/img/idxcon1.png" />
                    </dt>
                    <dd>
                      <h3>STEAM教育和创客教育套件</h3>
                      <p>
                        题材丰富的构型、简单有趣的DIY创意，激发创意灵感，让孩子更多想象成为现实；雷霆系列产品，让你快速选择合适的STEAM学习、教育、娱乐工具。
                      </p>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <img src="uploads/img/idxcon2.png" />
                    </dt>
                    <dd>
                      <h3>系列化零部件</h3>
                      <p>
                        丰富的标准化、系列化、通用化各形结构件与电子模块，结合少量装饰件，打造属于你的创意灵感库。
                      </p>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <img src="uploads/img/idxcon3.png" />
                    </dt>
                    <dd>
                      <h3>图形化编程软件与APP</h3>
                      <p>
                        Thunder
                        IDE图形化编程软件，多种声、光、形的编程模块自由组合，通过拖放程序指令块，实现对机器人的各种智能控制；Thunder
                        GO移动APP，支持无线蓝牙连接，无需编程，即下即玩，简单上手，让孩子轻松操控属于自己的智能机器人。
                      </p>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <img src="uploads/img/idxcon4.png" />
                    </dt>
                    <dd>
                      <h3>系统化课程与教材</h3>
                      <p>
                        结合不同年龄段学生发展特点，定制适宜每个年龄段的学生教材，集合系统化课程内容与教案，为学生、家长、教育者提供合适的学习和教学资源，多方面培养学生的知识与技能，旨在提高学生的AIQ。
                      </p>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1 index-show-war">
              <div className="col-sm-4 index-war-big">
                <dl>
                  <dt>
                    <img src="uploads/img/idxcon2.jpg" />
                  </dt>
                  <dd>
                    <img src="uploads/img/idxicon4.png" />
                    <span>炫酷外观</span>
                  </dd>
                </dl>
              </div>
              <div className="col-sm-8 index-war-samll">
                <div className="col-xs-6">
                  <dl>
                    <dt>
                      <img src="uploads/img/idxcon3.jpg" />
                    </dt>
                    <dd>
                      <img src="uploads/img/idxicon5.png" />
                      <span>铝合金氧化材质</span>
                    </dd>
                  </dl>
                </div>
                <div className="col-sm-6">
                  <dl>
                    <dt>
                      <img src="uploads/img/idxcon4.jpg" />
                    </dt>
                    <dd>
                      <img src="uploads/img/idxicon6.png" />
                      <span>声、光互动</span>
                    </dd>
                  </dl>
                </div>
                <div className="col-sm-6">
                  <dl>
                    <dt>
                      <img src="uploads/img/idxcon5.jpg" />
                    </dt>
                    <dd>
                      <img src="uploads/img/idxicon7.png" />
                      <span>丰富的输入/输出接口</span>
                    </dd>
                  </dl>
                </div>
                <div className="col-sm-6">
                  <dl>
                    <dt>
                      <img src="uploads/img/idxcon6.jpg" />
                    </dt>
                    <dd>
                      <img src="uploads/img/idxicon8.png" />
                      <span>丰富的独立传感器</span>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="index-pro-bg hidden-xs">
          <div className="container">
            <div className="row">
              <div className="thunder-title">
                <h2>激发想象，创造无限可能</h2>
                <p>
                  雷霆系列产品采用工业设计的外观与模块化外壳封装，方便与零部件组合，降低创造难度；
                  <br />
                  与生活中随处可见的事物相结合，触发灵感，拓展想象空间，创造无限可能。
                </p>
              </div>
            </div>
            <div className="row">
              <div className="index-war-tab swiper-container">
                <ul className="swiper-wrapper">
                  <li className="swiper-slide">
                    <a
                      href="javascript:;"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      <img src="uploads/img/1light.png" />
                    </a>
                  </li>
                  <li className="swiper-slide">
                    <a
                      href="javascript:;"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      <img src="uploads/img/2sixfoot.png" />
                    </a>
                  </li>
                  <li className="swiper-slide">
                    <a
                      href="javascript:;"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      <img src="uploads/img/3run.png" />
                    </a>
                  </li>
                  <li className="swiper-slide">
                    <a
                      href="javascript:;"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      <img src="uploads/img/4bat.png" />
                    </a>
                  </li>
                  <li className="swiper-slide">
                    <a
                      href="javascript:;"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      <img src="uploads/img/5rabit.png" />
                    </a>
                  </li>
                  <li className="swiper-slide">
                    <a
                      href="javascript:;"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      <img src="uploads/img/6lizard.png" />
                    </a>
                  </li>
                  <li className="swiper-slide">
                    <a
                      href="javascript:;"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      <img src="uploads/img/7wheel.png" />
                    </a>
                  </li>
                  <li className="swiper-slide">
                    <a
                      href="javascript:;"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      <img src="uploads/img/8threewheel.png" />
                    </a>
                  </li>
                  <li className="swiper-slide">
                    <a
                      href="javascript:;"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      <img src="uploads/img/91plane.png" />
                    </a>
                  </li>
                  <li className="swiper-slide">
                    <a
                      href="javascript:;"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      <img src="uploads/img/91twowheel.png" />
                    </a>
                  </li>
                  <li className="swiper-slide">
                    <a
                      href="javascript:;"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      <img src="uploads/img/92swing.png" />
                    </a>
                  </li>
                  <li className="swiper-slide">
                    <a
                      href="javascript:;"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      <img src="uploads/img/93door.png" />
                    </a>
                  </li>
                </ul>
                <div className="swiper-button-prev">
                  <img src="uploads/img/lefticon.png" />
                </div>

                <div className="swiper-button-next">
                  <img src="uploads/img/righticon.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-offset-1 col-sm-10 index-icon">
              <div className="col-sm-2 col-xs-6">
                <dl>
                  <dt>
                    <img src="uploads/img/idxicon9.png" />
                  </dt>
                  <dd>创造能力</dd>
                </dl>
              </div>
              <div className="col-sm-2 col-xs-6">
                <dl>
                  <dt>
                    <img src="uploads/img/idxicon10.png" />
                  </dt>
                  <dd>学习能力</dd>
                </dl>
              </div>
              <div className="col-sm-2 col-xs-6">
                <dl>
                  <dt>
                    <img src="uploads/img/idxicon11.png" />
                  </dt>
                  <dd>沟通能力</dd>
                </dl>
              </div>
              <div className="col-sm-2 col-xs-6">
                <dl>
                  <dt>
                    <img src="uploads/img/idxicon12.png" />
                  </dt>
                  <dd>数据能力</dd>
                </dl>
              </div>
              <div className="col-sm-2 col-xs-6">
                <dl>
                  <dt>
                    <img src="uploads/img/idxicon14.png" />
                  </dt>
                  <dd>编程能力</dd>
                </dl>
              </div>
              <div className="col-sm-2 col-xs-6">
                <dl>
                  <dt>
                    <img src="uploads/img/idxicon13.png" />
                  </dt>
                  <dd>动手能力</dd>
                </dl>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 index-intro-img">
              <img src="uploads/img/idx.png" />
            </div>
            <div className="col-sm-5 col-sm-offset-1">
              <div className="index-intro-tit">
                <h3>
                  将AI教育引入课堂
                  <br />
                  培养人工智能时代原住民
                  <br />
                  提高孩子的AIQ
                </h3>
                <div className="index-intro">
                  <p>雷霆专注于8岁以上学龄儿童和青少年的AIQ培养:</p>
                  <p>
                    与生活中随处可见的事物相结合，触发灵感，拓展想象空间，提升学生的创造力；
                  </p>
                  <p>
                    通过机械搭建与组装，提高学生的动手能力、问题解决能力，获得更多的成就感；
                  </p>
                  <p>
                    通过图形化编程，实现机器人的智能控制，结合课程案例，使学生轻松进入编程世界；
                  </p>
                  <p>
                    通过编程学习，培养信息化思维，让孩子习惯用信息化、数据化的视角认识现实世界。
                  </p>
                </div>
                <button className="index-intro-btn idx-edu-btn">
                  了解更多
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="index-text-bg">
          <div className="col-xs-10 col-xs-offset-1">
            <h4>
              在国际大赛中体验刺激，收获荣誉，实现孩子们的创造价值；
              <br />
              在主题竞赛中学以致用，不断强化和验证学习成果，以学习力持续提高孩子们的AIQ。
            </h4>
            <p>
              RA国际机器人大赛、各种主题竞赛，既满足学校和培训机构对机器人赛事的需求，
              <br />
              同时使学生在比赛中将多学科知识运用于实践，学习策略分工，感受团结协作的力量。
            </p>

            <button className="index-intro-btn idx-match-btn">了解更多</button>
          </div>
        </div>

        <div
          className="modal fade"
          id="myModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog idx-modle" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <img src="uploads/img/1light.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
