/**
 *作者：lorne
 *时间：2019/9/17
 *功能：
 */
import React from 'react'
import { Route,Switch} from 'react-router-dom'
import AlienDetail from "./AlienDetail";
import DaSai from '../place/Race'
import Education from './Education'
import PartsShow from './PartsShow'
import Product from './Product'
import ProductPage from './ProductPage'
import ProductDetail from './ProductDetail'
import Support from './Support'

function pages() {
  return <Switch>
    <Route exact path="/aliendetail" component={AlienDetail}/>
    <Route exact path="/dasai" component={DaSai}/>
    <Route exact path="/education" component={Education}/>
    <Route exact path="/partsshow" component={PartsShow}/>
    <Route exact path="/product" component={Product}/>
    {/* <Route exact path="/productpage" component={ProductPage}/> */}
    <Route exact path="/productdetail" component={ProductDetail}/>
    {/* <Route exact path="/support" component={Support}/> */}
    </Switch>
}


export default pages
