import React from "react";

const styles = {
  part1: {
    position: "relative"
  },
  part11: {
    width: "50%",
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    margin: "auto"
  },
  part2: {
    background: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: 80,
    paddingBottom: 80,
    maxWidth: 1200,
    margin: "0 auto"
  },
  title: {
    fontSize: 36,
    color: "#333",
    fontWeight: 500
  },
  part3_img: { position: "absolute", width: "30%" },
  title2: {
    fontSize: 24,
    color: "#333"
  },
  content: {
    fontSize: 18,
    color: "#666"
  },
  download: {
    width: 320,
    height: 370,
    background: "#F4F3F3",
    borderRadius: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  btn: {
    height: 47,
    width: 154,
    borderRadius: 23,
    border: "1px solid #FF9C00",
    fontSize: 18,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer"
  },
  enter: {
    height: 60,
    width: 100,
    cursor: "pointer"
  }
};

const Part1 = () => (
  <div style={styles.part1}>
    <img src="/images/dog/part1.png"></img>
    <img src="/images/dog/part11.png" style={styles.part11} />
  </div>
);
class Part2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [
        {
          id: 1,
          desc:
            "一个来自美国波特兰的三岁小男孩，名叫巴迪，旁边是他最好的朋友雷根----一只拉布拉多米克斯。他们可以说是世界上感情最好的孩子和狗狗！"
        },
        {
          id: 2,
          desc:
            "巴迪和雷根闲下来的时候，喜欢一起享受湖边时光，面对面坐在一起，喝着茶，聊着天，吹着小风。"
        },
        {
          id: 3,
          desc:
            "他们每次洗澡都是一起的，雷根洗完以后会穿上浴袍，乖乖滴等着它的小伙伴洗完。"
        },
        {
          id: 4,
          desc: "最惬意的就是一起在湖面泛舟~"
        },
        {
          id: 5,
          desc: "最浪漫的就是陪你一起看夕阳~"
        },
        {
          id: 6,
          desc: "一起为巴迪庆祝三岁生日！"
        },
        {
          id: 7,
          desc: "巴迪每天也会定时带雷根出去散步。"
        },
        {
          id: 8,
          desc:
            "世界上最棒的友谊，不仅仅局限于人和人之间，人和狗狗之间也是一样的，当他们读懂了彼此的语言，他们的心灵就开始了不停滴碰撞，每一分，每一秒，只要有你在，就有了无尽的快乐！"
        }
      ],
      active: 0
    };
  }

  componentDidMount() {
    this.active = this.state.active;

    this.inter = setInterval(() => {
      this.active++;
      if (this.active > 7) {
        this.active = 0;
      }
      this.setState({
        active: this.active
      });
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.inter);
  }

  render() {
    const { list, active } = this.state;

    return (
      <div style={styles.part2}>
        <p style={styles.title}>一个孩子和狗狗的故事</p>
        <div
          style={{
            display: "flex",
            position: "relative",
            background: "url(images/dog/part2.png) no-repeat top center",
            backgroundSize: "100%",
            height: 400,
            width: "70%",
            justifyContent: "space-between",
            paddingTop: 15,
            paddingLeft: 10
          }}
        >
          <img
            key={active}
            src={`images/dog/dog${list[active].id}.png`}
            className="dog3-story"
          />
          <div
            style={{
              width: "50%",
              textAlign: "center",
              marginTop: 20,
              marginRight: 30
            }}
          >
            <p style={styles.content}>{list[active].desc}</p>
          </div>
        </div>
      </div>
    );
  }
}
const Part3 = () => (
  <div style={{ position: "relative" }}>
    <div
      style={{
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        top: -50
      }}
    >
      <div
        style={{
          textAlign: "center"
        }}
      >
        <p style={styles.title}>一只机械宠物狗的自我介绍......</p>
        <div className="dog3"></div>
        <p style={styles.title2}>才貌兼得</p>
        <p style={styles.content}>
          活泼的小狗 趣味的动作 丰富的表情
          <br></br>
          大家好，我叫贝旺，身高22cm，体重1.2kg。
          <br></br>
          活泼可爱 能唱能跳
          <br></br>
          摇摇尾巴 悄悄耳朵
          <br></br>
          开心时送你爱心 生气时请勿靠近
        </p>
      </div>
      <img src="/images/dog/img_part3.png" style={{ width: "45%" }}></img>
    </div>
    <img src="/images/dog/bg_part3.png"></img>
  </div>
);
class Part4 extends React.Component {
  state = {
    tab: 0
  };

  leave = () => {
    this.setState({ tab: 0 });
  };

  enter = tab => {
    this.setState({ tab });
  };

  render() {
    const { tab } = this.state;
    return (
      <div
        style={{
          background: "#F6F6F6"
        }}
      >
        <div
          style={{
            textAlign: "center",
            paddingTop: 100,
            paddingBottom: 60,
            maxWidth: 1200,
            margin: "0 auto"
          }}
        >
          <p style={styles.title}>零件认识</p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
              }}
            >
              <img
                src="/images/dog/plugin5.png"
                style={{
                  width: 130,
                  visibility: tab === 1 ? "visible" : "hidden"
                }}
              />
              <img
                src="/images/dog/plugin6.png"
                style={{
                  width: 130,
                  visibility: tab === 2 ? "visible" : "hidden"
                }}
              />
              <img
                src="/images/dog/plugin2.png"
                style={{
                  width: 130,
                  visibility: tab === 3 ? "visible" : "hidden"
                }}
              />
              <img
                src="/images/dog/plugin8.png"
                style={{
                  width: 130,
                  visibility: tab === 4 ? "visible" : "hidden"
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                width: 100,
                height: "100%",
                left: "20%"
              }}
            >
              <div
                style={{ ...styles.enter }}
                onMouseLeave={this.leave}
                onMouseEnter={() => this.enter(1)}
              ></div>

              <div
                style={{ ...styles.enter, marginTop: "70%" }}
                onMouseLeave={this.leave}
                onMouseEnter={() => this.enter(2)}
              ></div>

              <div
                style={{ ...styles.enter, marginTop: "90%" }}
                onMouseLeave={this.leave}
                onMouseEnter={() => this.enter(3)}
              ></div>
              <div
                style={{ ...styles.enter, marginTop: "110%" }}
                onMouseLeave={this.leave}
                onMouseEnter={() => this.enter(4)}
              ></div>
            </div>
            <div style={{ width: "60%" }}>
              <img src="/images/dog/img_part4.png"></img>
            </div>
            <div
              style={{
                position: "absolute",
                width: 100,
                height: "100%",
                right: "17%"
              }}
            >
              <div
                style={{ ...styles.enter }}
                onMouseLeave={this.leave}
                onMouseEnter={() => this.enter(5)}
              ></div>
              <div
                style={{ ...styles.enter, marginTop: "65%" }}
                onMouseLeave={this.leave}
                onMouseEnter={() => this.enter(6)}
              ></div>
              <div
                style={{ ...styles.enter, marginTop: "50%" }}
                onMouseLeave={this.leave}
                onMouseEnter={() => this.enter(7)}
              ></div>
              <div
                style={{ ...styles.enter, marginTop: "160%" }}
                onMouseLeave={this.leave}
                onMouseEnter={() => this.enter(8)}
              ></div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
              }}
            >
              <img
                src="/images/dog/plugin1.png"
                style={{
                  width: 130,
                  visibility: tab === 5 ? "visible" : "hidden"
                }}
              />
              <img
                src="/images/dog/plugin4.png"
                style={{
                  width: 130,
                  visibility: tab === 6 ? "visible" : "hidden"
                }}
              />
              <img
                src="/images/dog/plugin3.png"
                style={{
                  width: 130,
                  visibility: tab === 7 ? "visible" : "hidden"
                }}
              />
              <img
                src="/images/dog/plugin7.png"
                style={{
                  width: 130,
                  visibility: tab === 8 ? "visible" : "hidden"
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const Part5 = () => (
  <div
    style={{
      display: "flex",
      paddingTop: 120,
      paddingBottom: 90
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        flex: 1
      }}
    >
     <img className="btn-big" src="/images/dog/img_part51.png" style={{width:'50%',flexShrink:0}}></img>
      <div style={{ height: 20 }}></div>
      <img
        className="btn-big"
        src="/images/dog/img_part54.png"
        style={{ width: "40%",flexShrink:0}}
      ></img>
      <div style={{ height: 20 }}></div>
      <img
        className="btn-big"
        src="/images/dog/img_part55.png"
        style={{ width: "30%",flexShrink:0 }}
      ></img>
    </div>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginLeft: 20,
        flex: 1,
        alignItems: "flex-start"
      }}
    >
      <p style={styles.title}>产品展示</p>
      <div style={{ height: 10 }}></div>
      <img className="btn-big" src="/images/dog/img_part53.png" style={{width:'50%',flexShrink:0}}></img>
      <div style={{ height: 20 }}></div>
      <div style={{ width: "40%",flexShrink:0}}>
      <img
        className="btn-big"
        src="/images/dog/img_part52.png"
      ></img>
      </div>
    </div>
  </div>
);

const Part6 = () => (
  <div
    style={{
      background: "#F6F6F6"
    }}
  >
    <div
      style={{
        maxWidth: 1200,
        margin: "0 auto",
        paddingTop: 80,
        paddingBottom: 100,
        textAlign: "center"
      }}
    >
      <p style={styles.title}>寓教于乐</p>
      <div style={{ height: 10 }}></div>
      <p style={styles.title2}>功能多多 给你无限乐趣</p>
      <div style={{ height: 100 }}></div>
      <div style={{ width: "70%", margin: "0 auto", display: "flex" }}>
        {[
          { name: "机械结构", id: 1 },
          { name: "电子传感", id: 2 },
          { name: "动手创作", id: 3 },
          { name: "作品展示", id: 4 }
        ].map((x, i) => (
          <div style={{ flex: 1 }} key={i}>
            <img
              src={`/images/dog/section${x.id}.png`}
              style={{ height: 78, width: "auto" }}
            ></img>
            <div style={{ height: 50 }}></div>
            <p style={styles.content}>{x.name}</p>
          </div>
        ))}
      </div>
      <div
        style={{
          width: "70%",
          margin: "0 auto",
          display: "flex",
          marginTop: 90
        }}
      >
        {[
          { name: "蓝牙手柄", id: 5 },
          { name: "编程控制", id: 6 },
          { name: "仿生动作", id: 7 },
          { name: "人机互动", id: 8 }
        ].map((x, i) => (
          <div style={{ flex: 1 }} key={i}>
            <img
              src={`/images/dog/section${x.id}.png`}
              style={{ height: 78, width: "auto" }}
            ></img>
            <div style={{ height: 50 }}></div>
            <p style={styles.content}>{x.name}</p>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const Part7 = () => (
  <div
    style={{
      textAlign: "center",
      paddingTop: 70,
      maxWidth: 1200,
      margin: "0 auto"
    }}
  >
    <p style={styles.title}>多种互动模式</p>
    <div style={{ height: 10 }}></div>
    <p style={styles.content}>
      可以用颜色、超声波传感器做为多种互动模式的触发机制，在不同模式下
      <br></br>
      体验贝旺的情绪与行为，实现贝旺与孩子有趣、有爱的互动。
    </p>
    <video
      id="myvideo"
      controls="controls"
      width="100%"
      src="//asset.bellrobot.com/thunder/document/video/240016.mp4"
      poster="images/dog/dog_video.png"
    >
      your browser does not support the video tag
    </video>
    <p style={{ ...styles.title, marginTop: 50 }}>丰富的LED表情</p>
    <div style={{ height: 10 }}></div>
    <p style={styles.content}>
      12×18个LED单色灯和2×6个LED全彩灯屏幕，会随着贝旺的情绪变化做出不同的表情。
    </p>
    <img src="/images/dog/img_part72.png" style={{ width: "70%" }}></img>
  </div>
);
const Part8 = () => (
  <div style={{ position: "relative", overflow: "hidden" }}>
    <div
      style={{
        ...styles.part11,
        textAlign: "center",
        paddingTop: 50,
        width: "90%",
        zIndex: 9
      }}
    >
      <p style={{ ...styles.title, color: "#fff" }}>蓝牙手柄、编程任意操控</p>
      <p style={{ ...styles.content, color: "#fff" }}>
        用蓝牙手柄无线连接贝旺的主控，或者用Thunder IDE积木式编程软件进行编程，
        <br></br>
        让孩子用不同的方式与贝旺互动，同时还能锻炼孩子的编程能力。
        <br></br>
      </p>
    </div>
    <div
      style={{
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
        position: "absolute",
        bottom: 0,
        width: "100%"
      }}
    >
      <img src="/images/dog/img_part81.png" style={{ width: "25%" }}></img>
      <img src="/images/dog/img_part82.png" style={{ width: "35%" }}></img>
    </div>
    <img src="/images/dog/bg_part8.png"></img>
  </div>
);

const Part9 = props => {
  const container = props && props.style ? props.style : {};
  let desc1 = "240016贝旺零件清单";
  let desc2 = "贝旺搭建指示说明";
  let down_expalin = "240016贝旺搭建说明.pdf";
  let down_dajian = "240016贝旺零件清单.pdf";
  switch (props.type) {
    case "lizard":
      desc1 = "240017蜥蜴零件清单";
      desc2 = "蜥蜴搭建指示说明";
      down_expalin = "240017蜥蜴搭建说明.pdf";
      down_dajian = "240017蜥蜴零件清单.pdf";
      break;
    case "bat":
      desc1 = "240018蝙蝠零件清单";
      desc2 = "蝙蝠搭建指示说明";
      down_expalin = "240018蝙蝠搭建说明.pdf";
      down_dajian = "240018蝙蝠零件清单.pdf";
      break;
    case "motor":
      desc1 = "270001机车零件清单";
      desc2 = "机车搭建指示说明";
      down_expalin = "270001机车搭建说明.pdf";
      down_dajian = "270001机车零件清单.pdf";
      break;
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        paddingTop: 88,
        paddingBottom: 88,
        ...container
      }}
    >
      <CardDown
        src="/images/dog/img_part92.png"
        imgStyle={{ width: 100, marginBottom: 4 }}
        name="零件清单"
        desc={desc1}
        down={down_dajian}
      />
      <div style={{ width: 20 }}></div>
      <CardDown
        src="/images/dog/img_part91.png"
        imgStyle={{ width: 100, marginBottom: 24 }}
        name="搭建说明"
        down={down_expalin}
        desc={desc2}
      />
    </div>
  );
};

const CardDown = ({ src, imgStyle, name, desc, down }) => (
  <div style={styles.download}>
    <div style={{ height: 240, textAlign: "center" }}>
      <img src={src} style={imgStyle}></img>
      <p style={styles.title2}>{name}</p>
      <p style={{ fontSize: 18, color: "#666" }}>{desc}</p>
    </div>
    <a
      className="downloadBtn"
      href={`//asset.bellrobot.com/thunder/document/pdf/${down}`}
      style={styles.btn}
    >
      下载
    </a>
  </div>
);

const Part10 = props => {
  let name = "贝旺";
  switch (props.type) {
    case "lizard":
      name = "蜥蜴";
      break;
    case "bat":
      name = "蝙蝠";
      break;
    case "motor":
      name = "机车";
      break;
  }
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          ...styles.part11,
          width: "80%",
          textAlign: "center",
          paddingTop: 100
        }}
      >
        <p style={styles.title}>主人，要带我回家吗？</p>
        <p style={styles.title2}>联系我们</p>
        <p style={styles.content}>
          {`了解更多${name}信息？或是定制一份适合您需求的产品？请拨打400-609-0919或邮件发送到thunder@bell.ai。`}
        </p>
      </div>
      <img src="/images/dog/bg_part10.png"></img>
    </div>
  );
};

const Part11 = ({ type }) => (
  <div style={{ textAlign: "center", paddingTop: 80, paddingBottom: 80 }}>
    <p style={styles.title}>相关推荐</p>
    <div
      style={{
        margin: "0 auto",
        width: "80%",
        display: "flex",
        justifyContent: "space-around"
      }}
    >
      <BtnShaw
        src="/images/dog/icon_1.png"
        imgStyle={{ height: 183, width: "auto" }}
        name="TC001"
        desc="雷霆蓝牙遥控手柄"
        href="tc001"
      />
      {type === "dog" ? null : (
        <BtnShaw
          src="/images/dog/ic_bellwan.png"
          imgStyle={{ height: 165, width: "auto", marginBottom: 18 }}
          name="240016"
          desc="贝旺"
          href="dog"
        />
      )}
      {type === "bat" ? null : (
        <BtnShaw
          src="/images/dog/icon_2.png"
          imgStyle={{ height: 165, width: "auto", marginBottom: 18 }}
          name="240018"
          desc="蝙蝠"
          href="bat"
        />
      )}
      {type === "lizard" ? null : (
        <BtnShaw
          src="/images/dog/icon_3.png"
          imgStyle={{ height: 180, width: "auto", marginBottom: 3 }}
          name="240017"
          desc="蜥蜴"
          href="lizard"
        />
      )}
      {type === "motor" ? null : (
        <BtnShaw
          src="/images/dog/icon_4.png"
          imgStyle={{ height: 154, width: "auto", marginBottom: 29 }}
          name="270001"
          desc="机车"
          href="motor"
        />
      )}
    </div>
  </div>
);

const BtnShaw = ({ src, imgStyle, name, desc, href,down_text }) => (
  <a
    href={href}
    className="box-t-shadow"
    style={{
      width: 220,
      height: 400,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 20
    }}
  >
    <img style={{ width: 220 }} src={src}></img>
    <p style={{ fontSize: 18, color: "#333", fontWeight: "bold" }}>
      {name}
      <br></br>
      {desc}
    </p>
    <div style={{ height: 30 }}></div>
    <div className="downloadBtn" style={styles.btn}>
      {down_text?down_text:'了解详情'}
    </div>
  </a>
);

export {
  Part1,
  Part2,
  Part3,
  Part4,
  Part5,
  Part6,
  Part7,
  Part8,
  Part9,
  Part10,
  Part11,
  BtnShaw
};
