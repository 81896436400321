/**
 *作者：lorne
 *时间：2019/9/17
 *功能：
 */
import React,{Component,Fragment} from 'react'

export default class Education extends Component {

  componentDidMount() {
    let $ = window.$
    document.title = '教育页面'
  }


  render(){
    return (<Fragment>


      <div className="" style={{textAlign:'center'}}>
        <img src="uploads/img/education.png" style={{
          width: '100%'
        }} alt=""/>
      </div>

    </Fragment>)
  }


}
