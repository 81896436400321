/**
 *作者：lorne
 *时间：2019/9/6
 *功能：
 */
import React from "react";
import './index.css'
import { MAC_URL_1_2_1, WIN_URL_1_2_1 } from "../../../config/utils";

 const Download = ()=>(
    <div className='container alien-down-c'>
        <div className="row">
            <div className="col-sm-offset-2 col-sm-8">
                <div className="col-sm-6 col-xs-12">
                    <div className="ailen-dwon">
                        <dl>
                            <dt><img src="uploads/img/ailenicon_01.png"/></dt>
                            <dd>搭建指南<span>83005套装官方搭建指南</span></dd>
                        </dl>
                        <div className="ailen-dwon-btn">
                            <button className="ailen-btn-or"
                                    onClick={()=>{
                                        window.location.href='//docs.thunder.bellrobot.com/thunderInternetResource/document/雷霆83005搭建指南.pdf'
                                    }}>下载
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xs-12">
                    <div className="ailen-dwon">
                        <dl>
                            <dt><img src="uploads/img/ailenicon_02.png"/></dt>
                            <dd>Thunder IDE<span>图形化编程  丰富的硬件支持</span></dd>
                        </dl>
                        <div className="ailen-dwon-btn">
                            <button
                                onClick={()=>{
                                    window.location.href= WIN_URL_1_2_1
                                }}>Windows版
                            </button>
                            <br/>
                            <button  onClick={()=>{
                              window.location.href= MAC_URL_1_2_1
                            }}>Mac版</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
)

export default Download
