import React, { Component } from "react";
import { Button } from "antd";

const navMenuList = [
  { label: "产品", href: "productpage" },
  // { label: "教育", href: "education" },
  { label: "创客", href: "parts" },
  { label: "大赛", href: "dasai" },
  { label: "下载", href: "support#software-download" },
  { label: "支持", href: "https://www.yuque.com/bellrobot", isBlank: true},
];
export class NavBar extends Component {
  render() {
    const pathname = window.location.pathname;
    return (
      <div className="thunder-nav">
        <a className="navbar-brand thunder-logo" href="/">
          <img src="uploads/img/logo.png" />
        </a>

        <ul className="thunder-nav-list">
          <div style={{ height: 24 }} />
          {navMenuList.map((item) => {
            return (
              <li key={item.href}>
                <a
                  className={pathname.includes(item.href) ? "active" : ""}
                  href={item.href}
                  target={item.isBlank ? "_blank" : "_self"}
                >
                  {item.label}
                </a>
              </li>
            );
          })}
        </ul>
        {/* <div style={{ display: "flex", alignItems: "center", marginLeft: 20 }}>
          <Button
            className="go-to-developer"
            onClick={() => {
              window.open("http://docs.thunder.bellrobot.com/", "_blank");
              // window.location.href = "http://docs.thunder.bellrobot.com/";
            }}
          >
            <img style={{ width: 16, marginRight: 5 }} src="images/矩形.png" />
            教育资源站
          </Button>
        </div> */}
      </div>
    );
  }
}
