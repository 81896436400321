import React, { Component } from "react";
import { Button, Tooltip } from "antd";
import Feedback from "../parts/Feedback";
import { sendEmail } from "../parts/Emal";
import "./style.scss";

const colCenter = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};
export default class Footer extends Component {
  state = {
    showFeedback: false,
  };
  componentDidMount() {}
  hideFeed = (data) => {
    if (data.name) {
      sendEmail(`合作意向申请`, JSON.stringify(data));
    }
    this.setState({ showFeedback: false });
  };

  render() {
    const { showFeedback } = this.state;
    return (
      <footer className="flex-col-h-center thunder-foot">
        <Feedback
          title="合作意向申请表"
          visible={showFeedback}
          onFinish={this.hideFeed}
        />
        <ul className="footer-ul">
          <li>
            <div className="thunder-foot-list" style={colCenter}>
              <a href="productpage">产品分类</a>
              <p>
                <a id="steam-suite" href="productpage#steam-suite">
                  套装
                </a>
              </p>
              <p>
                <a id="expand" href="productpage#expand">
                  拓展包及配件
                </a>
              </p>
              <p>
                <a id="danping" href="productpage#danping">
                  单品
                </a>
              </p>
              <p>
                <a id="danping" href="productpage#raceing">
                  比赛
                </a>
              </p>
              <p>
                <a id="spare-parts" href="parts">
                  创客
                </a>
              </p>
            </div>
          </li>
          {/* <li>
            <a href="education">教育</a>
          </li> */}
          <li>
            <div className="thunder-foot-list" style={colCenter}>
              <a href="dasai">大赛</a>
              <p>
                <a id="danping" href="62007">
                  62007赛事场地包
                </a>
              </p>
              <p>
                <a id="danping" href="85001">
                  85001赛事器材包
                </a>
              </p>
              <p>
                <a id="danping" href="62004">
                  62004赛事场地包
                </a>
              </p>
              <p>
                <a id="danping" href="85003">
                  85003赛事器材包
                </a>
              </p>
              <p>
                <a id="danping" href="62005">
                  62005赛事场地包
                </a>
              </p>
              <p>
                <a id="danping" href="85004">
                  85004赛事器材包
                </a>
              </p>
            </div>
          </li>
          <li>
            <div className="thunder-foot-list" style={colCenter}>
              <a href="support">下载</a>
              <p>
                <a id="software-download" href="support#software-download">
                  软件下载
                </a>
              </p>
              <p>
                <a id="common-problem" href="support#tutorial">
                  使用手册
                </a>
              </p>
              <p>
                <a id="document-download" href="support#product-document">
                  产品文档
                </a>
              </p>
              <p>
                <a id="common-problem" href="support#rebot-testing">
                  机器人等级考试
                </a>
              </p>
            </div>
          </li>
          <li>
            <div className="thunder-foot-contact">
              <Tooltip
                placement="right"
                overlayStyle={{ padding: 0 }}
                title={
                  <img style={{ width: 130 }} src="uploads/img/code.png" />
                }
              >
                <div className="thunder-online-serviece">
                  <img src="images/parts/online_service.png" />
                  在线客服
                </div>
              </Tooltip>

              <Tooltip
                placement="right"
                overlayStyle={{ padding: 0 }}
                title={
                  <img style={{ width: 130 }} src="uploads/img/code.png" />
                }
              >
                <div className="thunder-rev">
                  <img src="uploads/img/wchat.png" />
                  微信公众号
                </div>
              </Tooltip>
              <div>
                <img src="uploads/img/emil.png" />
                thunder@bell.ai
              </div>
              <div>
                <img src="uploads/img/phone.png" />
                400-609-0919
              </div>
              <div>
                <Button
                  onClick={() => {
                    this.setState({ showFeedback: true });
                  }}
                  className="message-board"
                >
                  留言板
                </Button>
              </div>
            </div>
          </li>
        </ul>
        <div className="flex-col-h-center thunder-foot-con">
          <p>
            版权所有©贝尔科教集团 备案号：粤ICP备15106839号-13 客服电话
            400-609-0919  深圳市南山区源政创业大厦A座A座818
          </p>
        </div>
      </footer>
    );
  }
}
