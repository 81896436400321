/**
 *作者：lorne
 *时间：2019/9/17
 *功能：
 */
import React,{Component,Fragment} from 'react'
import { MAC_URL_1_2_1, WIN_URL_1_2_1 } from '../../config/utils';

export default class DaSai extends Component {

  componentDidMount() {
    let $ = window.$
    document.title = '83003套装'
    $(function() {
      var i = 0;
      var timer;
      var slidlist = $('.ailen-slide-bg li');
      slidlist.eq(0).show().siblings('.ailen-slide-bg li').hide();
      showTime();
      function showTime() {
        timer = setInterval(function() {
          Show();
          i++;
          if(i == 3) {
            i = 0;
          }
        }, 2000);
      }
      function Show() {
        slidlist.eq(i).fadeIn(300).siblings('.ailen-slide-bg li').fadeOut(0);
      }
      $(".modal").each(function(index) {
        $(this).on('show.bs.modal', function(e) {
          var open = $(this).attr('data-easein');
          if(open == 'shake') {
            $('.modal-dialog').velocity('callout.' + open);
          } else if(open == 'pulse') {
            $('.modal-dialog').velocity('callout.' + open);
          } else if(open == 'tada') {
            $('.modal-dialog').velocity('callout.' + open);
          } else if(open == 'flash') {
            $('.modal-dialog').velocity('callout.' + open);
          } else if(open == 'bounce') {
            $('.modal-dialog').velocity('callout.' + open);
          } else if(open == 'swing') {
            $('.modal-dialog').velocity('callout.' + open);
          } else {
            $('.modal-dialog').velocity('transition.' + open);
          }

        });
      });
    })
  }

  render(){
    return (<Fragment>
      <div className="ailen-banner-bg">
        <div className="container ailen-rev">
          <div className="row">
            <div className="col-xs-6 col-xs-offset-1 detail-banner-animate">
              <div className="ailen-banner-font"><img src="uploads/img/bannercon9.png"/></div>
              <p className="ailen-banner-intro">83003
                是雷霆83002套件产品的升级版。该产品自带4种搭建形态，可拓展搭建至少8个炫酷构型，很好地锻炼孩子的动手能力；通过编程使不同DIY形态都呈现最佳动作效果，让孩子学习编程知识的同时升级体验机器人的乐趣；此外，支持APP无线蓝牙操控，即下即玩，无需编程，简单上手，让孩子轻松操控属于自己的智能机器人。</p>
            </div>
            <div className="ailen-banner-img"><img src="uploads/img/bannercon10.png"/></div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="ailen-title col-xs-offset-1">
            <p>4种形态，8个炫酷构型</p>
            <p>领略来自外星球超级战车的魅力</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 col-xs-6 ailen-war">
            <img src="uploads/img/con11.jpg"/>
            <p>鞘翅机甲-轮胎版/履带版</p>
          </div>
          <div className="col-md-3 col-xs-6 ailen-war">
            <img src="uploads/img/con12.jpg"/>
            <p>雷霆装甲-轮胎版/履带版</p>
          </div>
          <div className="col-md-3 col-xs-6 ailen-war">
            <img src="uploads/img/con13.jpg"/>
            <p>狂蜂战士-轮胎版/履带版</p>
          </div>
          <div className="col-md-3 col-xs-6 ailen-war">
            <img src="uploads/img/con14.jpg"/>
            <p>探路者-轮胎版/履带版</p>
          </div>
        </div>
      </div>
      <div className="ailen-upgrade-bg">
        <div className="container">
          <div className="row">
            <div className="ailen-title col-xs-offset-1">
              <p>多功能玩法</p>
              <p>升级体验机器人的魅力</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-5 col-xs-offset-1 ailen-upgrade-one">
              <div className="ailen-upgrade-tit">
                <strong>01</strong>
                <span>
								<p>画线</p>
								<p>在APP屏幕上画线，小车按照屏幕路线</p>
								<p>轨迹行驶。</p>
							</span>
              </div>
              <img src="uploads/img/line.gif"/>
              <div className="ailen-or"></div>
            </div>
            <div className="col-xs-4 col-xs-offset-1 ailen-upgrade-two">
              <img src="uploads/img/line1.gif"/>
              <div className="ailen-upgrade-tit">
                <strong>02</strong>
                <span>
								<p>巡线</p>
								<p>将小车放置在巡线地图上（黑色位置），点击开始后，小车将自动沿黑线前进。</p>
								<p></p>
							</span>
              </div>
              <div className="ailen-or1"></div>
              <div className="ailen-img-or"><img src="uploads/img/con25.png"/></div>
            </div>
          </div>
          <div className="row ailen-upgrade">
            <div className="col-xs-5 col-xs-offset-1 ailen-upgrade-one">
              <div className="ailen-upgrade-tit">
                <strong>03</strong>
                <span>
								<p>颜色编程</p>
								<p>点击开始机器人匀速前进行驶，使用不同色卡靠近颜色传感器操控机器人执行不同动作。 </p>
							</span>
              </div>
              <img src="uploads/img/color.gif"/>
              <div className="ailen-or2"></div>
              <div className="ailen-img-or1"><img src="uploads/img/con24.png"/></div>
            </div>
            <div className="col-xs-4 col-xs-offset-1">
              <div className="ailen-more">
                <h3>更多玩法</h3>
                <p>避障</p>
                <p>跟随</p>
                <p>驾驶</p>
                <p>……</p>
                <div className="ailen-more-icon">
                  <span><img src="uploads/img/con23.png"/></span>
                  <span><img src="uploads/img/con22.png"/></span>
                  <span><img src="uploads/img/con21.png"/></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="thunder-title">
            <h2>图形化编程</h2>
            <p>图形化编程，降低学习难度，让低龄儿童也能轻松学习；<br/>多种声＼光＼形的编程模块自由组合，让孩子发挥想像，培养创新能力和逻辑思维能力。</p>
          </div>
        </div>
      </div>
      <div><img src="uploads/img/con27.png"/></div>

      <div className="ailen-app-bg">
        <div className="container">
          <div className="row">
            <div className="col-xs-2 ailen-app-dllist ailen-app-margin">
              <dl>
                <dt><img src="uploads/img/conicon.png"/></dt>
                <dd>搭建</dd>
              </dl>
              <dl>
                <dt><img src="uploads/img/conicon1.png"/></dt>
                <dd>驾驶</dd>
              </dl>
              <dl>
                <dt><img src="uploads/img/conicon2.png"/></dt>
                <dd>跟随</dd>
              </dl>
            </div>
            <div className="col-xs-8">
              <div className="thunder-title ailen-con-title">
                <h2>APP手动遥控结合，寓教于乐</h2>
                <p>脱离编程，手动操控APP，快速上手，也能让机器人动起来。</p>
              </div>
              <div className="alien-app-img"><img src="uploads/img/con26.png"/></div>
            </div>
            <div className="col-xs-2 ailen-app-dllist">
              <dl>
                <dt><img src="uploads/img/conicon3.png"/></dt>
                <dd>巡线</dd>
              </dl>
              <dl>
                <dt><img src="uploads/img/conicon4.png"/></dt>
                <dd>避障</dd>
              </dl>
              <dl>
                <dt><img src="uploads/img/conicon5.png"/></dt>
                <dd>画线</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="thunder-title">
            <h2>脑洞大开，尽享创造的乐趣</h2>
            <p>配套搭建指导说明、配套APP、编程软件，引导孩子一步一步进行创造和学习。</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-offset-3 col-xs-6">
            <ul className="ailen-slide-bg">
              <li><img src="uploads/img/ailenimg_03.jpg"/></li>
              <li><img src="uploads/img/ailenimg_02.jpg"/></li>
              <li><img src="uploads/img/ailenimg_01.jpg"/></li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="ailen-last-war">
            <img src="uploads/img/ailenimg_04.png"/>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-offset-1 col-xs-10">
            <div className="col-xs-4">
              <div className="ailen-dwon">
                <dl>
                  <dt><img src="uploads/img/ailenicon_01.png"/></dt>
                  <dd>搭建指南<span>83003套装官方搭建指南</span></dd>
                </dl>
                <div className="ailen-dwon-btn">
                  <button className="ailen-btn-or"
                          onClick={()=>{
                            window.location.href='//asset.bellrobot.com/thunder/document/dajian/83003搭建指南.rar'
                          }}>下载
                  </button>
                </div>
              </div>
            </div>
            <div className="col-xs-4">
              <div className="ailen-dwon">
                <dl>
                  <dt><img src="uploads/img/ailenicon_02.png"/></dt>
                  <dd>Thunder IDE<span>图形化编程  丰富的硬件支持</span></dd>
                </dl>
                <div className="ailen-dwon-btn">
                  <button
                    onClick={()=>{
                      window.location.href= WIN_URL_1_2_1
                    }}>Windows版
                  </button>
                  <button onClick={()=>{
                    window.location.href= MAC_URL_1_2_1
                  }}>Mac版</button>
                </div>
              </div>
            </div>
            <div className="col-xs-4">
              <div className="ailen-dwon">
                <dl>
                  <dt><img src="uploads/img/ailenicon_03.png"/></dt>
                  <dd>Thunder Go<span>模型搭建教程   多种玩法操控</span></dd>
                </dl>
                <div className="ailen-dwon-btn">
                  <button><img src="uploads/img/QR_code2.png"/>iOS版</button>
                  <button  onClick={()=>{
                      window.location.href="http://docs.thunder.bellrobot.com/thunderInternetResource/ThunderGo.apk"
                    }}><img src="uploads/img/QR_code1.png"/>Android版</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ailen-foot-bg">
        <div className="container">
          <div className="row">
            <div className="thunder-title">
              <h2>升级拓展，探索更多创意与玩法</h2>
              <p>搭配61002零件升级包可拓展搭建更多炫酷构型，让孩子在不断探索的过程中，<br/>逐步提高创造力和动手思考能力。</p>
            </div>
          </div>
          <div className="row detail-foot-part">
            <div className="col-lg-10 col-lg-offset-1">
              <div>
                <div className="detail-foot-add">
                  <div>
                    <a href="aliendetail.html" target="_blank"><img src="uploads/img/con9.jpg"/></a>
                    <strong>83003</strong>
                  </div>
                  <span>+</span>
                </div>
              </div>
              <div className="detail-foot-mid">
                <a href="#" data-toggle="modal" data-target="#myModal"><img src="uploads/img/con21.jpg"/></a>
                <strong>61002</strong>
              </div>
              <div>
                <div className="detail-foot-equal">
                  <span>=</span>
                  <div>
                    <a href="product.html" target="_blank"><img src="uploads/img/con10.jpg"/></a>
                    <strong>83001</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div id="myModal" className="modal" data-easein="expandIn" tabIndex="-1" role="dialog"
           aria-labelledby="myModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
              <img src="uploads/img/suite-05-show.png"/>
            </div>
          </div>
        </div>
      </div>
    </Fragment>)
  }

}
