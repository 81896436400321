/**
 * 雷霆tiny
 */
import React, { Fragment } from "react";
import "./index2.scss";
import Base from "../Base";
import {
  MAC_URL_1_2_1,
  WIN_URL_1_2_1,
  remSet,
  remUnset,
} from "../../config/utils";

export default class index extends Base {
  state = {
    active: 0,
    active2: 0,
    active3: 0,
    isPlay: false,
    className: "hid",
    opacity: 0,
    height: "5000px",
    setPosition: "fixedP",
  };
  autoLoop;
  static defaultProps = {
    title: "雷霆Tebot",
  };
  enter = (index) => {
    this.setState(
      (prevState) => {
        const video = document.getElementById(`fun_video_${prevState.active}`);
        video && video.pause();
        return {
          active: index,
        };
      },
      () => {
        const video = document.getElementById(`fun_video_${index}`);
        video.currentTime = 0;
        video && video.play();
      }
    );
  };
  clickItem = (index) => {
    this.setState(
      (prevState) => {
        const design_video = document.getElementById(
          `design_video_${prevState.active2}`
        );
        design_video && design_video.pause();
        return { active2: index };
      },
      () => {
        const design_video = document.getElementById(`design_video_${index}`);
        design_video.currentTime = 0;
        const t = setTimeout(() => {
          clearTimeout(t);
          design_video && design_video.play();
        }, 500);
      }
    );
  };
  autoLoopFun = (enble = true) => {
    let index = this.state.active2;
    if (enble) {
      this.autoLoop = setInterval(() => {
        index++;
        if (index > 5) {
          index = 0;
        }
        this.clickItem(index);
      }, 4000);
    } else {
      clearInterval(this.autoLoop);
    }
  };
  componentDidMount() {
    remSet();
    window.addEventListener("scroll", this.handleScroll);
    let videoplay = document.getElementById("videoplay");
    let videoPalse = document.getElementById("videoPalse"); //video标签
    this.autoLoopFun();
    if (videoPalse) {
      if (videoplay.paused) {
        videoPalse.onclick = function () {
          videoplay.play();
          videoPalse.style.display = "none";
        };
      } else {
        videoplay.onclick = function () {
          videoplay.pause();
          videoPalse.style.display = "";
        };
      }
    }
  }
  componentWillUnmount() {
    remUnset();
  }
  handleScroll = (event) => {
    //滚动条高度
    let scrollTop = document.documentElement.scrollTop;
    const targetH = document.getElementById("ani_tiny").offsetTop;
    const modules = document.getElementById("tebot_modules").offsetTop;
  };

  srcollEvent = (event) => {

  };
  renderPage() {
    const classcify = [
      {
        icon: "images/tiny/car.png",
        name: "一体化车架",
      },
      {
        icon: "images/tiny/tt.png",
        name: "双TT电机",
      },
      {
        icon: "images/tiny/app.png",
        name: "APP操控",
      },
      {
        icon: "images/tiny/code.png",
        name: "Scratch & Python编程",
      },
      {
        icon: "images/tiny/teach.png",
        name: "实践式新科学课程",
      },
      {
        icon: "images/tiny/match.png",
        name: "国际机器人赛事",
      },
    ];
    const modules = [
      {
        title: "控制器",
        des: "小车的控制中心，接口丰富，采用ESP32双核物联网芯片。",
      },
      {
        title: "超声波传感器",
        des: "用于检测距离，使小车自动避障，跟随物体移动。",
      },
      {
        title: "彩色传感器",
        des:
          "具有环境光、反射光及颜色检测三种工作模式。用来检测环境光及反射光的相对强度，和识别物体的颜色。",
      },
      {
        title: "电机",
        des: "双TT电机，为小车提供驱动力；转速快，动力足，安装灵活。",
      },
      {
        title: "车架",
        des: "一体化车架，无需螺母，零部件安装简单。",
      },
      {
        title: "车轮",
        des: "胎纹深，越野性强。",
      },
    ];
    const activity = [
      {
        icon: "images/tiny/icon.png",
        active: "images/tiny/icon_active.png",
        name: "驾驶",
      },
      {
        icon: "images/tiny/icon2.png",
        active: "images/tiny/icon2_active.png",
        name: "跟随",
      },
      {
        icon: "images/tiny/icon3.png",
        active: "images/tiny/icon3_active.png",
        name: "避障",
      },
      {
        icon: "images/tiny/icon5.png",
        active: "images/tiny/icon5_active.png",
        name: "巡线",
      },
      {
        icon: "images/tiny/icon7.png",
        active: "images/tiny/icon7_active.png",
        name: "音乐家",
      },
      {
        icon: "images/tiny/icon8.png",
        active: "images/tiny/icon8_active.png",
        name: "语音控制",
      },
      {
        icon: "images/tiny/icon4.png",
        active: "images/tiny/icon4_active.png",
        name: "编程",
      },
    ];
    const teach = [
      {
        title: "课程体系",
        des:
          "83007机器人配备内容丰富的课程，课程主题包括陆地训练、荒漠生存、逃 脱困境、超级机器人等主题，帮助孩子学习多学科知识，掌握搭建技巧，锻炼编程能力，提高孩子的动手能力和创造力。",
      },
      {
        title: "大赛",
        des:
          "RA国际机器人大赛、WRC世界机器人大赛及国际主流赛事。通过比赛不断强化和验证学习成果，在协助和竞争中，一步步完善孩子的认知体系，让孩子对知识的掌握、理解、应用更加水到渠成。",
      },
    ];

    const { active2, active } = this.state;

    return (
      <Fragment>
        <div className="tinyPage">
          <section style={{ position: "relative" }}>
            <div className="tinyDetail" id="tebotHead">
              <div className="bg1" />
              <div className="tinyTitle">
                {/* <img
                  src="images/tiny/tiny_title.png"
                  style={{ width: 189 }}
                ></img> */}
                <h3>83007</h3>
                <h3>入门级编程教育机器人</h3>
              </div>
            </div>
          </section>
          <div className="tinyIcon">
            <p className="pro">
              83007是一款适用于8岁以上学龄儿童的编程教育机器人。秉承寓教于乐的设计理
              念，让孩子在玩乐中收获知识，超越自我，玩出名堂！
            </p>
            <div className="tinySpe">
              {classcify.map((item, index) => (
                <div className="classify" key={index}>
                  <img src={item.icon} />
                  <p>{item.name}</p>
                </div>
              ))}
            </div>
          </div>

          <section
            style={{
              position: "relative",
              overflow: "hidden",
              backgroundColor: "white",
            }}
          >
            <div className="tinyVideo">
              <video
                controls="controls"
                id="videoplay"
                onPlay={() => {
                  const videoPalse = document.getElementById("videoPalse"); //video标签
                  videoPalse.style.display = "none";
                }}
                src="http://docs.thunder.bellrobot.com/thunderInternetResource/Tebot/Tebot.mp4"
              ></video>
              <div className="playBg" id="videoPalse">
                <img className="bgColor" src="images/tiny/playBg.png" />
                <div className="playBtn">
                  <img src="images/tiny/playBtn.png" />
                </div>
              </div>
            </div>

            <div className="tinyModule">
              <p style={{ textAlign: "center", opacity: " 0.8" }}>形态模块</p>
              <div className="modules" id="tebot_modules">
                <div
                  className="moduleLeft"
                  id="ani_tiny"
                  onMouseEnter={() => {
                    this.autoLoopFun(false);
                  }}
                  onMouseLeave={() => {
                    this.autoLoopFun();
                  }}
                >
                  {modules.map((item, index) => (
                    <div
                      className="moduleItem"
                      onClick={() => this.clickItem(index)}
                      key={index}
                    >
                      {item.title}
                      <p
                        className={
                          this.state.active2 == index ? "desActive" : "des"
                        }
                      >
                        {item.des}
                      </p>
                      <div
                        className={
                          this.state.active2 == index ? "lineActive" : "line"
                        }
                      ></div>
                    </div>
                  ))}
                </div>
                <div className="moduleRight">
                  {[
                    "控制器",
                    "超声波传感器",
                    "彩色传感器",
                    "电机",
                    "车架",
                    "车轮",
                  ].map((k, i) => {
                    return (
                      <video
                        key={`design_video_${i}`}
                        className="animated fadeInUp"
                        style={{ opacity: i === active2 ? 1 : 0 }}
                        name="media"
                        muted
                        src={`http://docs.thunder.bellrobot.com/thunderInternetResource/Tebot/${k}.mp4`}
                        id={`design_video_${i}`}
                      >
                        <source
                          src={`http://docs.thunder.bellrobot.com/thunderInternetResource/Tebot/${k}.mp4`}
                          type="video/mp4"
                        ></source>
                      </video>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="tinyActivity">
              <p
                style={{
                  textAlign: "center",
                  opacity: " 0.8",
                  fontWeight: 500,
                }}
              >
                萌趣互动 7 种官方玩法
              </p>

              <div className="activity">
                {[
                  "驾驶",
                  "跟随",
                  "避障",
                  "巡线",
                  "音乐家",
                  "语音控制",
                  "编程",
                ].map((n, i) => {
                  return (
                    <video
                      key={`fun_video_${i}`}
                      style={{
                        position: "absolute",
                        opacity: i === active ? 1 : 0,
                      }}
                      muted
                      src={`http://docs.thunder.bellrobot.com/thunderInternetResource/Tebot/${n}.mp4`}
                      id={`fun_video_${i}`}
                      preload="auto"
                    >
                      <source
                        src={`http://docs.thunder.bellrobot.com/thunderInternetResource/Tebot/${n}.mp4`}
                        type="video/mp4"
                      ></source>
                    </video>
                  );
                })}
                <div className="mask"></div>
                {activity.map((item, index) => (
                  <div
                    className="activityItem"
                    onMouseEnter={() => this.enter(index)}
                    key={index}
                  >
                    <img
                      src={item.active}
                      style={{ opacity: active === index ? 1 : 0.5 }}
                    />
                    <p>{item.name}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="tinyCode">
              <p style={{ textAlign: "center", opacity: " 0.8" }}>
                图形化编程只是启蒙，让孩子自由操控代码才是王道
              </p>
              <p className="produce">
                雷霆IDE2.0支持Scratch与Python两种编程语言。
                <br />
                点击积木，Python代码双屏对照，引导孩子去发掘积木背后的奥秘，循序渐进的
                <br />
                锻炼孩子逻辑思维能力，找到编程的乐趣，让Python更易学。
              </p>
              <img src="images/tiny/code_child.png" />
            </div>
            <div className="tinyModule">
              <p style={{ textAlign: "center", opacity: " 0.8" }}>
                实践式课程教学
              </p>
              <div className="modules">
                <div className="moduleLeft">
                  {teach.map((item, index) => (
                    <div
                      className="moduleItem"
                      onClick={() => this.setState({ active3: index })}
                      key={index}
                    >
                      {item.title}
                      <p
                        className={
                          this.state.active3 == index ? "desActive" : "des"
                        }
                      >
                        {item.des}
                      </p>
                      <div
                        className={
                          this.state.active3 == index ? "lineActive2" : "line"
                        }
                      ></div>
                    </div>
                  ))}
                </div>
                <div style={{ height: "4.58rem" }} className="moduleRight">
                  <img
                    src={"images/tiny/tinyIndex0.png"}
                    style={{
                      display: this.state.active3 === 0 ? "block" : "none",
                    }}
                  />
                  <img
                    src={"images/tiny/tinyIndex1.png"}
                    style={{
                      display: this.state.active3 === 1 ? "block" : "none",
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ marginBottom: "0.5rem" }} className="container">
              <div className="row">
                <div className="col-xs-offset-1 col-xs-10">
                  <div className="col-xs-4">
                    <div className="ailen-dwon">
                      <dl>
                        <dt>
                          <img src="uploads/img/ailenicon_01.png" />
                        </dt>
                        <dd>
                          用户手册<span>83007用户手册</span>
                        </dd>
                      </dl>
                      <div className="ailen-dwon-btn">
                        <button
                          className="ailen-btn-or"
                          onClick={() => {
                            window.location.href =
                              "http://docs.thunder.bellrobot.com/thunderInternetResource/document/雷霆83007用户手册.pdf";
                          }}
                        >
                          下载
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-4">
                    <div className="ailen-dwon">
                      <dl>
                        <dt>
                          <img src="uploads/img/ailenicon_02.png" />
                        </dt>
                        <dd>
                          Thunder IDE<span>图形化编程 丰富的硬件支持</span>
                        </dd>
                      </dl>
                      <div className="ailen-dwon-btn">
                        <button
                          onClick={() => {
                            window.location.href = WIN_URL_1_2_1;
                          }}
                        >
                          Windows版
                        </button>
                        <button
                          onClick={() => {
                            window.location.href = MAC_URL_1_2_1;
                          }}
                        >
                          Mac版
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-4">
                    <div className="ailen-dwon">
                      <dl>
                        <dt>
                          <img src="uploads/img/ailenicon_03.png" />
                        </dt>
                        <dd>
                          Thunder Go<span>模型搭建教程 多种玩法操控</span>
                        </dd>
                      </dl>
                      <div className="ailen-dwon-btn">
                        <button>
                          <img src="uploads/img/QR_code2.png" />
                          iOS版
                        </button>
                        <button
                          onClick={() => {
                            window.location.href =
                              "http://docs.thunder.bellrobot.com/thunderInternetResource/ThunderGo.apk";
                          }}
                        >
                          <img src="uploads/img/QR_code1.png" />
                          Android版
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Fragment>
    );
  }
}
