import React from "react";
import { Part10, Part11, Part9 } from "./DogChild";

const styles = {
  title: {
    fontSize: 36,
    color: "#333",
    fontWeight: 500
  },
  content: {
    fontSize: 18,
    color: "#666"
  },
  title2: {
    fontSize: 24,
    color: "#333"
  },
  enter: {
    height: 60,
    width: 100,
    cursor: "pointer"
  }
};

export default class Motor extends React.Component {
  componentDidMount() {
    document.title = "270001机车";
  }
  render() {
    return (
      <div>
        <div style={{ position: "relative", overflow: "hidden" }}>
          <img src="/images/dog/bg_m1.png"></img>
          <img
            src="/images/dog/img_m1.png"
            style={{
              position: "absolute",
              bottom: "-5%",
              right: "15%",
              width: "60%"
            }}
          ></img>
        </div>
        <img src="/images/dog/bg_m2.png" />
        <div style={{ position: "relative" }}>
          <img
            src="/images/dog/img_m3.png"
            style={{ position: "absolute", right: 0, bottom: 0, width: "60%" }}
          />
          <div
            style={{ paddingTop: 150, paddingBottom: 80, marginLeft: "15%" }}
          >
            <p style={styles.title}>创意激发入门模型</p>
            <div style={{ marginTop: 100 }}>
              <p style={styles.title}>产品介绍</p>
              <p style={styles.content}>
                名称：金属拼装机车
                <br></br>
                长：26cm
                <br></br>
                重：0.7kg
              </p>
            </div>
          </div>
          <img src="/images/dog/bg_m3.png" />
        </div>

        <Motor4 />
        <Motor5 />
        <Motor6 />
        <Motor7 />
        <Motor8 />
        <Part9 style={{ background: "#ECEBE9" }} type="motor" />
        <Part10 type="motor" />
        <Part11 type="motor" />
      </div>
    );
  }
}

class Motor4 extends React.Component {
  state = {
    tab: 0
  };

  leave = () => {
    this.setState({ tab: 0 });
  };

  enter = tab => {
    this.setState({ tab });
  };
  render() {
    const { tab } = this.state;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: "url(images/dog/bg_m4.png) center no-repeat",
          paddingTop: 50,
          paddingBottom: 80
        }}
      >
        <p style={{ ...styles.title, color: "#fff", marginBottom: 60 }}>
          雷霆工厂
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "relative"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between"
            }}
          >
            <img
              src="/images/dog/plugin7.png"
              style={{
                width: 130,
                visibility: tab === 1 ? "visible" : "hidden"
              }}
            />
            <img
              src="/images/dog/plugin4.png"
              style={{
                width: 130,
                visibility: tab === 2 ? "visible" : "hidden"
              }}
            />
            <img
              src="/images/dog/plugin2.png"
              style={{
                width: 130,
                visibility: tab === 3 ? "visible" : "hidden"
              }}
            />
            <img
              src="/images/dog/plugin7.png"
              style={{
                width: 130,
                visibility: tab === 4 ? "visible" : "hidden"
              }}
            />
          </div>
          <div
            style={{
              position: "absolute",
              width: 100,
              height: "100%",
              left: "18%"
            }}
          >
            <div
              style={{ ...styles.enter, marginTop: "300%" }}
              onMouseLeave={this.leave}
              onMouseEnter={() => this.enter(4)}
            ></div>
          </div>
          <div style={{ width: "60%" }}>
            <img src="/images/dog/img_m4.png"></img>
          </div>
          <div
            style={{
              position: "absolute",
              width: 100,
              height: "100%",
              right: "22%"
            }}
          >
            <div
              style={{ ...styles.enter }}
              onMouseLeave={this.leave}
              onMouseEnter={() => this.enter(5)}
            ></div>

            <div
              style={{ ...styles.enter, marginTop: "240%" }}
              onMouseLeave={this.leave}
              onMouseEnter={() => this.enter(8)}
            ></div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between"
            }}
          >
            <img
              src="/images/dog/plugin5.png"
              style={{
                width: 130,
                visibility: tab === 5 ? "visible" : "hidden"
              }}
            />
            <img
              src="/images/dog/plugin4.png"
              style={{
                width: 130,
                visibility: tab === 6 ? "visible" : "hidden"
              }}
            />
            <img
              src="/images/dog/plugin12.png"
              style={{
                width: 130,
                visibility: tab === 7 ? "visible" : "hidden"
              }}
            />
            <img
              src="/images/dog/plugin12.png"
              style={{
                width: 130,
                visibility: tab === 8 ? "visible" : "hidden"
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const Motor5 = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      background: "#ECEBE9",
      paddingTop: 100,
      paddingBottom: 60
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        marginRight: 20,
        flex: 1
      }}
    >
      <img
        src="/images/dog/img_m51.png"
        className="btn-big"
        style={{ width: "40%",flexShrink:0}}
      ></img>
      <img
        className="btn-big"
        src="/images/dog/img_m54.png"
        style={{ width: "50%", marginTop: 20,flexShrink:0}}
      ></img>
    </div>
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
      }}
    >
      <p style={styles.title}>自我展示</p>
      <img className="btn-big" src="/images/dog/img_m52.png" 
        style={{ width: "50%",flexShrink:0 }}></img>
      <div style={{ marginTop: 20 }}>
      <img className="btn-big" src="/images/dog/img_m53.png"
      style={{width:'40%'}}></img>
      </div>
    </div>
  </div>
);

const Motor6 = () => (
  <div
    style={{
      display: "flex",
      paddingTop: 80,
      paddingBottom: 80,
      flexDirection: "column",
      maxWidth: 1200,
      margin: "0 auto"
    }}
  >
    <div
      style={{
        display: "flex",
        textAlign: "center"
      }}
    >
      {[
        { name: "线条设计流畅，金属材质更显质感", id: 1 },
        { name: "真实的把手方向设计，前轮可转弯导向", id: 2 }
      ].map((x, i) => (
        <div style={{ flex: 1 }} key={i}>
          <img
            src={`/images/dog/ic_m${x.id}.png`}
            style={{ height: 200, width: "auto" }}
          ></img>
          <div style={{ height: 50 }}></div>
          <p style={styles.title2}>{x.name}</p>
        </div>
      ))}
    </div>
    <div
      style={{
        display: "flex",
        textAlign: "center"
      }}
    >
      {[
        { name: "宽轮胎增加机车的稳定和厚重感，确保机车不会翻倒", id: 3 },
        { name: "有一种情怀叫哈雷", id: 4 }
      ].map((x, i) => (
        <div style={{ flex: 1 }} key={i}>
          <img
            src={`/images/dog/ic_m${x.id}.png`}
            style={{ height: 200, width: "auto" }}
          ></img>
          <div style={{ height: 50 }}></div>
          <p style={styles.title2}>{x.name}</p>
        </div>
      ))}
    </div>
    <div
      style={{
        display: "flex",
        textAlign: "center"
      }}
    >
      {[
        { name: "机车的力度美感及实用性，都展现在这款摩托车之上", id: 5 },
        { name: "图解说明，易懂易组装", id: 6 }
      ].map((x, i) => (
        <div style={{ flex: 1 }} key={i}>
          <img
            src={`/images/dog/ic_m${x.id}.png`}
            style={{ height: 200, width: "auto" }}
          ></img>
          <div style={{ height: 50 }}></div>
          <p style={styles.title2}>{x.name}</p>
        </div>
      ))}
    </div>
  </div>
);

const Motor7 = () => (
  <div
    style={{
      paddingTop: 80,
      paddingBottom: 100,
      textAlign: "center",
      background: "#ECEBE9"
    }}
  >
    <p style={styles.title}>给孩子一个与生活交流的机会</p>
    <p style={styles.content}>
      高仿真模型，金属炫酷的外观，激发孩子探索学科知识的兴趣；
      <br />
      独立拼装，在过程中收获快乐，并促进精细动作技能的提升，爱拼的孩子值得拥有。
    </p>
    <div style={{ height: 100 }}></div>
    <div style={{ width: "70%", margin: "0 auto" }}>
      <div
        style={{
          display: "flex"
        }}
      >
        {[
          { name: "思维逻辑", id: 1 },
          { name: "创造力", id: 2 },
          { name: "动手能力", id: 3 },
          { name: "反应能力", id: 4 }
        ].map((x, i) => (
          <div style={{ flex: 1 }} key={i}>
            <img
              src={`/images/dog/icm_${x.id}.png`}
              style={{ height: 78, width: "auto" }}
            ></img>
            <div style={{ height: 50 }}></div>
            <p style={styles.title2}>{x.name}</p>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const Motor8 = () => (
  <div>
    <div style={{ position: "relative" }}>
      <img src="/images/dog/bg_m7.png" style={{ height: 600 }} />
      <div
        style={{
          position: "absolute",
          top: "40%",
          right: "20%",
          textAlign: "center"
        }}
      >
        <p style={styles.title}>训练反应能力</p>
        <p style={styles.content}>锻炼手眼协同 培养耐心与专注力</p>
      </div>
    </div>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#ECEBE9"
      }}
    >
      <div style={{ textAlign: "center" }}>
        <p style={styles.title}>培养自信心</p>
        <p style={styles.content}>
          在拼装过程中鼓励孩子
          <br />
          提高孩子面对困难的勇气及克服困难的信心
        </p>
      </div>
      <img
        src="/images/dog/img_m8.png"
        style={{ height: 600, marginLeft: 80, width: "auto" }}
      />
    </div>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <img
        src="/images/dog/img_m9.png"
        style={{ height: 600, width: "auto" }}
      />
      <div style={{ textAlign: "center", marginLeft: 80 }}>
        <p style={styles.title}>锻炼逻辑思维</p>
        <p style={styles.content}>
          训练手脑协调能力 提高视觉辨别力 <br />
          提高空间想象力
        </p>
      </div>
    </div>
  </div>
);
