/**
 *作者：lorne
 *时间：2019/9/9
 *功能：
 */
import React from 'react'
import './index.css'

const features = [{
    intro: <p>4.2蓝牙无线连接<br/>自动匹配</p>
}, {
    intro: <p>14个按键<br/>让操作得心应手</p>
}, {
    intro: <p>2组摇杆灵敏转动<br/>良好的操作体验</p>
}, {
    intro: <p>ABS树脂材质<br/>舒适手感</p>
}, {intro: <p>自定义编程<br/>功能按键任意切换</p>}]
const TC1 = ({items = features}) => (<div className='tc_1'>
    <img src='images/tc001/tc_hand.png'/>
    <div className='container'>
        <div className='row'>
            <div className='col-sm-offset-1 col-sm-10'>
                <div className='col-sm-1'/>
                {items.map((x, i) => <div
                    key={`tc_ic_${i}`}
                    className='col-sm-2 col-xs-6 tc_1_hand'>
                    <div>
                        <img src={`images/tc001/tc_ic_${i + 1}.png`}/>
                    </div>
                    <div>
                        {x.intro}
                    </div>

                </div>)}

            </div>

        </div>

    </div>

</div>)
const TC2 = () => (<img src='images/tc001/tc_bg_2.png'/>)

const TC3 = () => (<div className='container tc_3'>
    <div className='row'>

        <div className='col-sm-1'/>
        <div className='col-sm-3'>
            <h2>蓝牙无线连接 自动适配</h2>
            <p>采用蓝牙4.2无线传输的连接解决方案，360°无死角限制，响应速度快，功率低，延迟率减少；能记忆存储与之配对的主控信息，下次使用时手柄与主控自动适配连接。</p>
        </div>
        <div className='col-sm-8'>
            <img src='images/tc001/tc_img_2.png'/>
            <img src='images/tc001/tc_img_1.png'/>
        </div>
    </div>

</div>)

const TC4 = () => (<div className='tc_4'>
    <div className="thunder-title">
        <h2>更多自定义按键 给孩子更自由的操控空间</h2>
        <p>
            上下左右、ABCD、Ⅰ、Ⅱ、L1、L2、R1、R2，多达14个自定义按键，
            <br/>
            布局合理，供孩子赋予编程功能，让孩子操控更自由。
        </p>
    </div>
    <img src='images/tc001/tc_img_3.png'/>
</div>)

const TC5 = () => (<div className='rev tc_5 hidden-xs'>
    <img src='images/tc001/tc_img_4.png'/>
    <div className="thunder-title">
        <h2>人性化电量管理系统 电量玩的久</h2>
        <p>

            一键查看电池电量，低电量时自动提醒，让孩子避免在使用的过程中没电带来的麻烦；
            <br/>
            采用电源优化管理系统，智能判断条件，手柄自动进入待机省点模式，省事省电;
            <br/>
            使用时按住上下左右、ABCD、L1、R1、HOME任意键一键唤醒，快捷方便。

        </p>
    </div>

</div>)

const TC6 = ()=>(<div className='flex-col-h-center tc_6'>
    <div className="thunder-title">
        <h2>360°自由转幅摇杆 灵敏准确</h2>
        <p>
            摇杆实现360°全域定位，合理摇摆幅度，操控流畅细腻，让孩子体验媲美方向盘的操控体验；
            <br/>
            摇杆帽贴合拇指曲线设计，使触感舒适，能有效防止手指滑落。
        </p>
    </div>
    <img src='images/tc001/tc_img_5.png'/>
</div>)

const TC7 =()=>(<div className='container-fluid tc_7'>
    <div className='row'>
        <div className='col-sm-offset-1 col-sm-10' style={{display:'flex',alignItems:'center'}}>
            <div className='col-sm-6 flex-center'>
              <div className='tc_b tc_7_txt'>
                <h2>灵巧快速操作的人体工学设计细节</h2>
                <p>
                  手柄从形状、选材等各方面都注重孩子使用时的操控感和舒适感。
                  拥有符合人体工程学的流线设计，让操控更舒适；外壳采用ABS环保
                  塑料材质，可以有效防止刮伤；左右握把采用纹理防滑设计，可以有效避免因手心出汗而失误。
                </p>
              </div>

            </div>
            <div className='col-sm-6 hidden-xs'>
                <img src='images/tc001/tc_img_7.png'/>
            </div>
        </div>

    </div>

</div>)

const TC8 = ()=>(<div className='ct_8'>
    <div className='ct_8_c'>
       <div style={{display:'flex',flexDirection:'column',
       alignItems:'center'}}>
         <span style={{color:'#333',fontSize:36}}>相关参数</span>
         <img style={{height:560,width:'100%'}} src='images/tc001/tc_img_8.png'/>
       </div>
        <div style={{marginBottom:160}}>
            <span>《蓝牙遥控使用说明》电子文档</span>
            <a href='//asset.bellrobot.com/thunder/document/pdf/雷霆蓝牙遥控使用说明.pdf'
              className='btn_tc_down flex-center'>
                <span>下载</span>
            </a>

        </div>
    </div>
    <div className='container-fluid ct_8_b'>
    <div className='row'>
       <div className='col-sm-offset-1 col-sm-10'>
           <div className='col-sm-4'>
               <p>
                   产品型号：63001
                   <br/>
                   <br/>
                   额定电压：4.5V
                   <br/>
                   <br/>
                   外形尺寸：161×104×62mm（长宽高）
               </p>

           </div>
           <div className='col-sm-4'>
               <p>
                   重量：约140g
                   <br/>
                   <br/>
                   蓝牙标准：蓝牙4.2
                   <br/>
                   <br/>
                   电池规格：使用3节1.5V AAA 电池供电
               </p>
           </div>
           <div className='col-sm-4'>
               <p>
                   使用寿命：按键30万次； <br/> L、R摇杆（X、Y轴）100万次
                   额定电压：4.5V
                   <br/>
                   <br/>
                   功能硬件：电源开关×1；LED多功能指示灯×4；<br/> USB接口×1
               </p>
           </div>
       </div>
    </div>
    </div>

</div>)



export {TC1, TC2, TC3, TC4, TC5,TC6,TC7,TC8}
