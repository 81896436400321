export function getElementByClass(tag) {
  return document.getElementsByClassName(tag);
}

export const MAC_URL_1_2_1 = `https://docs.thunder.bellrobot.com//thunderInternetResource/Thunder_IDE_Setup_1.2.6.dmg`;
export const WIN_URL_1_2_1 = `https://docs.thunder.bellrobot.com//thunderInternetResource/Thunder_IDE_Setup_1.2.6.exe`;
export const THUNBOT_DOWNLOAD_URL = {
  mac:
    "https://docs.thunder.bellrobot.com/thunderInternetResource/Thunder_IDE_Setup_2.0.1.dmg",
  win:
    "https://docs.thunder.bellrobot.com/thunderInternetResource/Thunder_IDE_Setup_2.0.1.exe",
};
export const HiCode_DOWNLOAD_URL = {
  mac:
    "https://docs.thunder.bellrobot.com/thunderInternetResource/HiCode/HiCode_official.dmg",
  win:
    "https://docs.thunder.bellrobot.com/thunderInternetResource/HiCode/HiCode_official.exe",
};
global.WWW_COM_DOC = `https://docs.thunder.bellrobot.com/thunderInternetResource/document/`;

export const resizeEvt =
  "orientationchange" in window ? "orientationchange" : "resize";

// 动态计算根元素的font-size，某些页面font-size需动态设置，放到需要的页面的生命周期函数中去
export const remSet = () => {
  const docEl = document.documentElement;
  const recalc = function () {
    const clientWidth = docEl.clientWidth;
    if (!clientWidth) {
      return;
    }
    docEl.style.fontSize = 100 * (clientWidth / 1920) + "px";
  };

  recalc();
  if (!document.addEventListener) return;
  window.addEventListener(resizeEvt, recalc, false);
  // document.addEventListener("DOMContentLoaded", recalc, false);
};
export const remUnset = () => {
  document.documentElement.style.fontSize = "unset";
  window.removeEventListener(resizeEvt);
};
