import React, { Component } from "react";
import { BtnShaw } from "../dog/DogChild";

const styles = {
  title: {
    color: "#2A2A2A",
    fontSize: 36,
  },
  text: {
    color: "#2A2A2A",
    fontSize: 18,
  },
  titleW: {
    color: "#fff",
    fontSize: 36,
  },
  textW: {
    color: "#fff",
    fontSize: 18,
  },
};
export default class RacePlace extends Component {
  render() {
    return (
      <div>
        <div className="parter">
          <div className="partC" style={{ justifyContent: "center" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "10%",
              }}
            >
              <img
                src="/images/place/85004-banner-text.png"
                style={{ width: "40%" }}
              />
              <p
                style={{
                  fontSize: 24,
                  color: "white",
                  width: "40%",
                  marginTop: "20px",
                }}
              >
                85004器材包支持每支参赛队伍搭建比赛所需的1台参赛机器人；其拥有种类丰富的零部件，数量充足，孩子们可创造出多种搭建方案的机器人。
              </p>
            </div>
          </div>
          <img src="/images/place/85004-banner-bg.png"></img>
        </div>
        <div
          className="parter"
          style={{
            background: "url(images/place/85003-part2-bg.png)",
            backgroundSize: "cover",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={"/images/place/85004-part2-box.png"}
              style={{ width: "45%" }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "70px",
              }}
            >
              <p style={styles.title}>器材包有什么？</p>
              <p style={styles.text}>
                250+个涉及梁类、齿轮类、紧固类、功能类、轴类等Thunbot零部件
              </p>
              <p style={styles.text}>
                1个Tbot-I控制器、2个彩色传感器、1个六轴传感器、1个触碰传感器、1个显示屏
              </p>
              <p style={styles.text}>2个舵机、2个TT电机</p>
              <p style={styles.text}>1个锂电池、1个锂电池充电器</p>
              <p style={styles.text}>2把L型扳手、1个M4扳手和1个可换头螺丝刀</p>

              <a
                target="_blank"
                href="http://docs.thunder.bellrobot.com/thunderInternetResource/document/85004《RA挑战赛-极智速滑》器材包零件清单.png"
                className="btn-h"
                style={{
                  height: 57,
                  paddingLeft: 33,
                  paddingRight: 33,
                  background: "#FF9C00",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "60%",
                  marginTop: 30,
                }}
              >
                <span style={styles.textW}>85004器材包零件清单</span>
              </a>
            </div>
          </div>
        </div>
        <div className="parter">
          <div
            className="partC"
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <p style={{ ...styles.titleW, marginTop: 80 }}>赛事介绍</p>
            <p style={{ ...styles.textW, width: 960, margin: "30px 0" }}>
              “RA -
              极智速滑”挑战赛以2022年冬奥会为背景，于2021年正式发布并举办。
            </p>
            <p style={{ ...styles.textW, width: 960, margin: "30px 0" }}>
              “极智速滑”为全自动计时任务挑战赛，参赛选手需在赛前完成自动机器人的搭建、编程和调试。
              “RA科技挑战赛-极智速滑”比赛场地整场为两个1.2X2.4米标准赛台长边拼接而成，单个赛台为半场，每场比赛由两支参赛队伍、两台自动机器人参加。两台机器人分别在各自的区域完成相应的任务，不能进入对方的区域。每场比赛根据自动机器人完成任务的情况计总分。比赛时长180秒。
            </p>
            <p style={{ ...styles.textW, width: 960 }}>
              参赛组别为小学组（7-12岁）
            </p>
          </div>
          <img src="/images/place/85004-game-bg.png"></img>
        </div>
        <div className="parter">
          <div className="partC" style={{ alignItems: "center" }}>
            <span style={{ margin: "80px 0", ...styles.titleW }}>课程服务</span>
            <p style={{ ...styles.textW, width: 960, color: 'rgba(194, 209, 248, 1)' }}>
              85004器材包为“RA -
              极智速滑”挑战赛提供器材支撑，此外还提供12课时项目制课程。
            </p>
            <p style={{ ...styles.textW, width: 960, color: 'rgba(194, 209, 248, 1)' }}>
              通过阅读赛制、分析任务、策略制定、模型搭建、模拟比赛等环节，不仅锻炼孩子的动手能力，提升数据分析能力，团队协作能力，抗挫折和解决问题的能力，让孩子体会机器人赛事对学习的价值，以赛促学。
            </p>
            <img
              style={{ width: "60%", marginTop: 50 }}
              src="/images/place/85004-course-outline.png"
            ></img>
            <img
              style={{ width: "60%", marginTop: 70 }}
              src="/images/place/85004-course-ppt.png"
            ></img>
          </div>
          <img src="/images/place/85004-course-bg.png"></img>
        </div>

        <div style={{ textAlign: "center", paddingTop: 80, paddingBottom: 80 }}>
          <p style={styles.title}>相关推荐</p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <BtnShaw
              src="/images/place/85004-recommend.png"
              imgStyle={{ height: 176, width: "auto" }}
              desc={
                <div>
                  62005场地包
                  <br />
                  “RA - 极智速滑”挑战赛
                </div>
              }
              href="62005"
            />
          </div>
        </div>
      </div>
    );
  }
}
