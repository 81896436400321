import React, { Component } from 'react';
import { NavBar } from './NavBar';
import Footer from './Footer/index.js';

class App extends Component {


  componentDidMount() {

  }

  render() {
    let layout = React.Children.map(this.props.children, (child) => child,this)

    return (
      <div style={{paddingTop:78}}>
        <NavBar />
        {layout}
        <Footer />
      </div>
    )
  }
}



export default App;
