/**
 *作者：lorne
 *时间：2019/9/17
 *功能：
 */
import React,{Component,Fragment} from 'react'
import { MAC_URL_1_2_1, WIN_URL_1_2_1 } from '../../config/utils';


export default class ProductDetail extends Component{

  componentDidMount() {
    let $ = window.$
    document.title = '83002套装'
    $(function() {
      var i = 0;
      var timer;
      var slidlist = $('.detail-mid-ullist li');
      var slidtext = $('.detail-mid-text p');
      slidlist.eq(0).show().siblings('.detail-mid-ullist li').hide();
      slidtext.eq(0).show().siblings('.detail-mid-text p').hide();
      showTime();
      //创建一个showTime函数
      function showTime() {
        //定时器
        timer = setInterval(function() {
          //调用一个Show()函数
          Show();
          i++;
          //当图片是最后一张的后面时，设置图片为第一张
          if(i == 3) {
            i = 0;
          }
        }, 2000);
      }
      //创建一个Show函数
      function Show() {
        //在这里可以用其他jquery的动画
        slidlist.eq(i).fadeIn(300).siblings('.detail-mid-ullist li').fadeOut(300);
        slidtext.eq(i).fadeIn(300).siblings('.detail-mid-text p').fadeOut(300);
      }

      $(".modal").each(function(index) {
        $(this).on('show.bs.modal', function(e) {
          var open = $(this).attr('data-easein');
          if(open == 'shake') {
            $('.modal-dialog').velocity('callout.' + open);
          } else if(open == 'pulse') {
            $('.modal-dialog').velocity('callout.' + open);
          } else if(open == 'tada') {
            $('.modal-dialog').velocity('callout.' + open);
          } else if(open == 'flash') {
            $('.modal-dialog').velocity('callout.' + open);
          } else if(open == 'bounce') {
            $('.modal-dialog').velocity('callout.' + open);
          } else if(open == 'swing') {
            $('.modal-dialog').velocity('callout.' + open);
          } else {
            $('.modal-dialog').velocity('transition.' + open);
          }

        });
      });
    })

  }

  render(){
    return (<Fragment>
      <div className="detail-banner-bg">
        <div className="container">
          <div className="row">
            <div className="col-sm-5 col-sm-offset-1 detail-banner-animate">
              <div className="detail-banner-font"><img src="uploads/img/bannercon7.png"/></div>
              <p className="detail-banner-intro">83002
                是雷霆系列较简单的STEAM教育套件产品，以编程学习为主；融合了科学、技术、工程、艺术、数学等学科知识，而且搭建简单、有趣，有助于引导孩子从零开始学习编程和多学科知识，激发科学兴趣，培养动手能力。</p>
              <div className="detail-banner-dllist">
                <dl>
                  <dt><img src="uploads/img/bannercon3.png"/></dt>
                  <dt>科学</dt>
                </dl>
                <dl>
                  <dt><img src="uploads/img/bannercon4.png"/></dt>
                  <dt>技术</dt>
                </dl>
                <dl>
                  <dt><img src="uploads/img/bannercon11.png"/></dt>
                  <dt>工程</dt>
                </dl>
                <dl>
                  <dt><img src="uploads/img/bannercon5.png"/></dt>
                  <dt>艺术</dt>
                </dl>
                <dl>
                  <dt><img src="uploads/img/bannercon6.png"/></dt>
                  <dt>数学</dt>
                </dl>
              </div>
            </div>
            <div className="col-sm-6 detail-banner-img hidden-xs">
              <img src="uploads/img/bannercon8.png"/>
            </div>
          </div>
        </div>
      </div>
      <div className="container hidden-md hidden-sm hidden-xs">
        <div className="row">

          <div className="thunder-title">
            <h2>形态模块</h2>
            <p>丰富的扩展接口，加上标准化、系列化通用结构与零部件,<br/>可扩展搭建更多题材丰富的构型，满足孩子更多创意的实现。</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-10 col-xs-offset-1 detail-mod-rev">
            <div className="detail-mod-img"><img src="uploads/img/con16.png"/></div>
            <div className="detail-mod">
              <ul className="detail-mod-list col-xs-5">
                <li>
                  <img src="uploads/img/con8.png"/>
                  <span>
									<h4>丰富的扩展口</h4>
									<p>2个舵机口、3个通用I²C口、1个通用I/O口、<br/>1个显示屏口、1个UART接口，支持外接舵<br/>机、显示屏及各种传感器</p>
								</span>
                  <div className="detail-border"></div>
                </li>
                <li>
                  <img src="uploads/img/con13.png"/>
                  <span>
									<h4>电机</h4>
									<p>连接控制器电机口，为小车<br/>提供驱动力；转速快，动力<br/>足，安装灵活</p>
								</span>
                  <div className="detail-border detail-border-or"></div>
                </li>
                <li>
                  <img src="uploads/img/con14.png"/>
                  <span>
									<h4>超声波传感器</h4>
									<p>用于检测距离，使小车自动避<br/>障，跟随物体移动</p>
								</span>
                  <div className="detail-border detail-border-or1"></div>
                </li>
                <li>
                  <img src="uploads/img/con15.png"/>
                  <span>
									<h4>巡线传感器</h4>
									<p>能使小车沿着白色背景上的黑色<br/>线条移动，或者沿着黑色背景上<br/>的白色线条移动</p>
								</span>
                  <div className="detail-border detail-border-or2"></div>
                </li>
              </ul>
              <ul className="detail-mod-list detail-mod-list-right col-xs-5 col-xs-offset-2">
                <li>
								<span style={{paddingRight:'100px'}}>
									<h4>控制器</h4>
									<p>小车的控制中心和供电站</p>
								</span>
                  <img src="uploads/img/con9.png"/>
                </li>
                <li>
								<span>
									<h4>喇叭&蓝牙</h4>
									<p>内置喇叭，可播放美妙有趣的音乐<br/>内置蓝牙，无线连接，实现APP操控小车</p>
								</span>
                  <img src="uploads/img/con10.png"/>
                  <div className="detail-border detail-border-or3"></div>
                </li>
                <li>
								<span>
									<h4>通用零部件</h4>
									<p>兼容雷霆系列通用化、标准化零部件</p>
								</span>
                  <img src="uploads/img/con11.png"/>
                  <div className="detail-border detail-border-or4"></div>
                </li>
                <li>
								<span>
									<h4>轮胎</h4>
									<p>胎纹深，越野性强</p>
								</span>
                  <img src="uploads/img/con12.png"/>
                  <div className="detail-border detail-border-or5"></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="detail-elect-bg">
        <div className="container">
          <div className="row">

            <div className="thunder-title">
              <h2>电机强劲</h2>
              <p>电机额定转速300r/min，最大力矩6kg*cm，<br/>无论是宽广的草地，还是泥泞坎坷的陆地，都能以最佳状态奔跑，<br/>带给孩子在更广的领域操控驾驶的快乐。</p>
            </div>
          </div>
          <div className="row">
            <div className="detail-elect-img col-xs-offset-1"><img src="uploads/img/con17.png"/></div>
          </div>
        </div>
      </div>
      <div className="container detail-elect-botcon">
        <div className="row">
          <div className="detail-or hidden-sm hidden-xs">
            <p></p>
          </div>
          <div className="col-sm-4 col-sm-offset-1 detail-mid-intro">
            <h3>简易搭建</h3>
            <p>采用模块化设计，<br/>主控与各零部件色彩区别搭配，辨别度高；<br/>配有详细的搭建步骤图示说明，<br/>避免大量文字说明带来的枯燥，<br/>引导孩子一步步搭建完成。</p>
          </div>
          <div className="col-sm-7 detail-mid-img">
            <div className="detail-mid-slide">
              <div className="detail-mid">
                <ul className="detail-mid-ullist">
                  <li><img src="uploads/img/con18.jpg"/></li>
                  <li><img src="uploads/img/con19.jpg"/></li>
                  <li><img src="uploads/img/con20.jpg"/></li>
                </ul>
              </div>
              <div className="detail-mid-p">
                <div className="detail-mid-text">
                  <p>01. —— 02.</p>
                  <p>02. —— 03.</p>
                  <p>03. —— 01.</p>
                </div>
              </div>
            </div>
            <div className="detail-mid-bg"><img src="uploads/img/con18.png"/></div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row" style={{margin:'50px 0'}}>
          <div className="col-xs-offset-1 col-xs-10">
            <div className="col-xs-4 col-xs-offset-2">
              <div className="ailen-dwon">
                <dl>
                  <dt><img src="uploads/img/ailenicon_01.png"/></dt>
                  <dd>搭建指南<span>83002套装官方搭建指南</span></dd>
                </dl>
                <div className="ailen-dwon-btn">
                  <button className="ailen-btn-or"
                          onClick={()=>{
                            window.location.href='//asset.bellrobot.com/thunder/document/dajian/83002搭建指南.pdf'
                          }}>下载
                  </button>
                </div>
              </div>
            </div>
            <div className="col-xs-4">
              <div className="ailen-dwon">
                <dl>
                  <dt><img src="uploads/img/ailenicon_02.png"/></dt>
                  <dd>Thunder IDE<span>图形化编程  丰富的硬件支持</span></dd>
                </dl>
                <div className="ailen-dwon-btn">
                  <button
                    onClick={()=>{
                      window.location.href= WIN_URL_1_2_1
                    }}>Windows版
                  </button>
                  <button onClick={()=>{
                      window.location.href= MAC_URL_1_2_1
                    }}>Mac版</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">

          <div className="thunder-title">
            <h2>图形化编程</h2>
            <p>图形化编程，降低学习难度，让低龄儿童也能轻松学习；<br/>多种声＼光＼形的编程模块自由组合，让孩子发挥想像，培养创新能力和逻辑思维能力。</p>
          </div>
        </div>
      </div>
      <div><img src="uploads/img/con20.png"/></div>
      <div className="container">
        <div className="row">

          <div className="thunder-title">
            <h2>强大的扩展能力，让更多想象成为现实</h2>
            <p>搭配不同零件扩展包，搭出百变形态。在搭建的过程中，孩子建立起对机器人的初步认识，<br/>培养科学探索的兴趣，并锻炼创新思维，让想象力转化为实物。</p>
          </div>
        </div>
        <div className="row detail-foot-part">
          <div className="col-lg-10 col-lg-offset-1">
            <div>
              <div className="detail-foot-add">
                <div>
                  <a href="productdetail.html" target="_blank"><img src="uploads/img/con7.jpg"/></a>
                  <strong>83002</strong>
                </div>
                <span>+</span>
              </div>
            </div>
            <div className="detail-foot-mid">
              <a href="#myModal" data-toggle="modal" data-target=""><img src="uploads/img/con8.jpg"/></a>
              <strong>61001</strong>
            </div>
            <div>
              <div className="detail-foot-equal">
                <span>=</span>
                <div>
                  <a href="aliendetail.html" target="_blank"><img src="uploads/img/con9.jpg"/></a>
                  <strong>83003</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row detail-foot-part">
          <div className="col-lg-10 col-lg-offset-1">
            <div>
              <div className="detail-foot-addbot">
                <div>
                  <a href="productdetail.html" target="_blank"><img src="uploads/img/con7.jpg"/></a>
                  <strong>83002</strong>
                </div>
                <span>+</span>
              </div>
            </div>
            <div className="detail-foot-midbot">
              <div>
                <a href="#" data-toggle="modal" data-target="#myModal"><img src="uploads/img/con8.jpg"/></a>
                <strong>61001</strong>
              </div>
              <span>+</span>
              <div>
                <a href="#" data-toggle="modal" data-target="#myModalpart"><img src="uploads/img/con21.jpg"/></a>
                <strong>61002</strong>
              </div>
            </div>
            <div>
              <div className="detail-foot-equalbot">
                <span>=</span>
                <div>
                  <a href="product.html" target="_blank"><img src="uploads/img/con10.jpg"/></a>
                  <strong>83001</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footerpage"></div>


      <div id="myModal" className="modal" data-easein="expandIn" tabIndex="-1" role="dialog"
           aria-labelledby="myModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
              <img src="uploads/img/suite-04-show.png"/>
            </div>
          </div>
        </div>
      </div>

      <div id="myModalpart" className="modal" data-easein="expandIn" tabIndex="-1" role="dialog"
           aria-labelledby="myModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
              <img src="uploads/img/suite-05-show.png"/>
            </div>
          </div>
        </div>
      </div>
    </Fragment>)
  }
}
