import React, { Component } from "react";
import { BtnShaw } from "../dog/DogChild";

const styles = {
  title: {
    color: "#2A2A2A",
    fontSize: 36,
  },
  text: {
    color: "#2A2A2A",
    fontSize: 18,
  },
  titleW: {
    color: "#fff",
    fontSize: 36,
  },
  textW: {
    color: "#fff",
    fontSize: 18,
  },
};
export default class RacePlace extends Component {
  render() {
    return (
      <div>
        <div className="parter">
          <div className="partC" style={{ justifyContent: "center" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "10%",
              }}
            >
              <img
                src="/images/place/85003banner.png"
                style={{ width: "40%" }}
              />
              <p
                style={{
                  fontSize: 24,
                  color: "white",
                  width: "40%",
                  marginTop: "20px",
                }}
              >
                85003器材包支持每支参赛队伍搭建比赛所需的1台参赛机器人；其拥有种类丰富的的零部件，数量充足，孩子们可创造出多种搭建方案的机器人。
              </p>
            </div>
          </div>
          <img src="/images/place/85003bg.png"></img>
        </div>
        <div
          className="parter"
          style={{
            background: "url(images/place/85003-part2-bg.png)",
            backgroundSize: "cover",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={"/images/place/85003-part2-box.png"}
              style={{ width: "45%" }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "70px",
              }}
            >
              <p style={styles.title}>器材包有什么？</p>
              <p style={styles.text}>
                2300+个涉及梁类、齿轮类、紧固类、功能类、轴类等Thunbot零部件
              </p>
              <p style={styles.text}>
                {" "}
                1个Tbot-I控制器、1个蓝牙遥控器、1个显示器
              </p>
              <p style={styles.text}>
                2个彩色传感器、1个六轴传感器、1个触碰传感器、2个RJ25转接模块
              </p>
              <p style={styles.text}>6个舵机、6个编码电机、1个风扇模块</p>
              <p style={styles.text}>
                1个舵机扩展板、1个电舵机扩展模块、1个电机扩展模块
              </p>
              <p style={styles.text}>1个锂电池、1个锂电池充电器</p>
              <p style={styles.text}>10把L型扳手、4个M4扳手和2个可换头螺丝刀</p>

              <a
                target="_blank"
                href="http://docs.thunder.bellrobot.com/thunderInternetResource/document/85003《RA挑战赛-拆弹专家》器材包零件清单.png"
                className="btn-h"
                style={{
                  height: 57,
                  paddingLeft: 33,
                  paddingRight: 33,
                  background: "#FF9C00",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "60%",
                  marginTop: 30,
                }}
              >
                <span style={styles.textW}>85003器材包零件清单</span>
              </a>
            </div>
          </div>
        </div>
        <div className="parter">
          <div
            className="partC"
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <p style={{ ...styles.titleW, marginTop: 80 }}>赛事介绍</p>
            <p style={{ ...styles.textW, width: 960, margin: "30px 0" }}>
              “RA -
              拆弹专家”挑战赛为RA机器人联盟于2021年全新展开的大型机器人对抗项目.
              本赛事旨在推动机器人技术发展, 促进学校教育, 培养青少年创新能力,
              使更多青少年有更多机会参加科技交流活动、展示自己的才华和能力,
              激发他们对科技和机器人世界的不懈探索
            </p>
            <p style={{ ...styles.textW, width: 960, margin: "30px 0" }}>
              “RA - 拆弹专家”挑战赛比赛场地大小为4x3米,
              每场比赛参赛队伍分为红蓝双方,
              每方1台步兵机器人、1台工程机器人及1台拆卸机器人,
              要求每支参赛队伍操纵自主设计或改装的机器人,
              于180秒内完成爆炸物回收、爆炸物拆解、导弹拦截及据点占领任务,
              比赛最后所得分值最高的队伍赢得比赛胜利
            </p>
            <p style={{ ...styles.textW, width: 960 }}>
              参赛组分为小初组(11-15岁)和高职组(16-18岁)两个组
            </p>
          </div>
          <img src="/images/place/85003-game-bg.png"></img>
        </div>
        <div className="parter">
          <div className="partC" style={{ alignItems: "center" }}>
            <span style={{ margin: '80px 0', ...styles.titleW }}>课程服务</span>
            <p style={{ ...styles.textW, width: 960, color: 'rgba(194, 209, 248, 1)' }}>
              85003器材包为“RA -
              拆弹专家”挑战赛提供器材支撑，此外还提供12课时项目制课程。
            </p>
            <p style={{ ...styles.textW, width: 960, color: 'rgba(194, 209, 248, 1)' }}>
              本课程以竞技挑战以及人工智能为主题，旨在以竞技挑战的形式学习大疆机甲大师S1、机甲大师EP、以及雷霆机器人的相关知识，通过竞技挑战、分组对抗的形式
              学习底盘、云台、麦克拉姆轮、FPV相机、发射器以及机械手等相关机械知识，培养学生团队合作、竞技对抗和解决问题的能力，了解最前沿的科技。
            </p>
            <img
              style={{ width: "60%", marginTop: 50 }}
              src="/images/place/85003-course-outline.png"
            ></img>
            <img
              style={{ width: "60%", marginTop: 70 }}
              src="/images/place/85003-course-ppt.png"
            ></img>
          </div>
          <img src="/images/place/85003-course-bg.png"></img>
        </div>

        <div style={{ textAlign: "center", paddingTop: 80, paddingBottom: 80 }}>
          <p style={styles.title}>相关推荐</p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <BtnShaw
              src="/images/place/62004-playground.png"
              imgStyle={{ height: 176, width: "auto" }}
              desc={
                <div>
                  62004场地包
                  <br />
                  “RA - 拆弹专家”挑战赛
                </div>
              }
              href="62004"
            />
          </div>
        </div>
      </div>
    );
  }
}
