/**
 *作者：lorne
 *时间：2019/9/7
 *功能：
 */
import React, {Component} from 'react'
import './index.css'
import '../83004/download/index.css'

const Product631 = () => (<div className='bg_2'>
  <div className='container-fluid'>
    <div className='row'>
      <div className='col-sm-offset-2 col-sm-10 padding0'>
        <div className='col-sm-4 col-xs-12 intro'>
          <h2>深入学习</h2>
          <br/>
          <p>
            机械工程扩展包旨在与
            <a href='/83004'>《83004模块化声光互动机器人》</a>
            结合，通过编程教学项目使学生在声光互动机器人学习之上，增加机械、传动、工程等领域知识，拓展学生的知识面，使学生的编程能力从基础向高阶迈进，提高学生的问题解决能力。
          </p>
          <br/>
          <p>
            机械工程扩展包与声光互动机器人一起使用，可扩展搭建以下三个构型：
          </p>
          <br/>
          <p>
            叉车<br/>搬运车<br/>机械手
          </p>
          <br/>
          <p>3合1扩展构型，体验神奇多变的工程乐趣。</p>
        </div>
        <div className='col-sm-8 padding0'>
          <img src='images/61004/car_41.png'/>

        </div>
      </div>

    </div>
    <div className='row padding0'>

      <div className='col-sm-offset-2 col-sm-10 s-txt'>
        <div className='col-sm-4 flex'>
          <img src='images/61004/dot.png'/>
          <span>内含齿轮、梁类，轴类等5种铝合金机<br/>械零件，可扩展搭建更多工程车形态;</span>
        </div>
        <div className='col-sm-4 flex'>
          <img src='images/61004/dot.png'/>
          <span>内含编码伺服舵机，可灵活实现角度变<br/>化，实现更多场景应用；</span>
        </div>
        <div className='col-sm-4 flex'>
          <img src='images/61004/dot.png'/>
          <span>丰富的运动控制功能，可玩性更高。</span>
        </div>
      </div>
    </div>
  </div>


</div>)

const Product632 = () => (<div className='bg_3'>
  <div className='container'>

    <div className='row' style={{display: 'flex', alignItems: 'center'}}>
      <div className='col-sm-8'>
        <img src='images/61004/img_1.png'/>
      </div>

      <div className='col-sm-4 bg_3_txt'>
        <h2>课程学习</h2>
        <br/>
        <span>学习主题：智能搬运</span>
        <br/>
        <br/>
        <p>课时：1.5h/每次课*4节课=6小时 该课程体系始终秉持着智能硬件为教育服务，涵盖数学、物理、机械、工程、编程、科学等方面的知识。
        </p>
      </div>
    </div>

  </div>

</div>)

const Product633 = () => (
  <div className='bg_4'>
    <div className="thunder-title">
      <h2>学以致用</h2>
      <p>机械工程扩展包非常适合各种工程设计和编程挑战。“RA挑战赛-城市救援”、“RA挑战赛-突入重围”等挑战项目为<br/>学生提供了创造性的解决问题，将STEAM教育成果应用于实践的机会。</p>
    </div>
    <Part5/>

  </div>
)

class Part5 extends Component {

  state = {
    active: 0,
    items: [{
      title: 'RA挑战赛—城市救援',
      intro: '“RA挑战赛—城市救援”比赛分为自动控制及遥控控制两个阶段，机器人需要完成资源部署、清除障碍、疏导居民及扑灭大火任务，完成任务分值高的一方获得比赛胜利。'
    }, {
      title: 'RA 挑战赛—突入重围',
      intro: `“RA 挑战赛—突入重围”大赛为 RA 国际机器人大赛的核心活动之一，通过比赛，引领青少年塑造创客精神，增强社会参与意识及竞争意识，培养个人独立思考能力及团队协作能力，提高青少年 AIQ。`
    }],
  }

  componentDidMount() {
    let $ = window.$
    /*轮播*/
    let that = this
    $("#part5 .slide").on("click", function () {
      let index = $(this).index(),
        next = index + 1;

      let isSlide2 = $(this).hasClass('slide2')
      that.setState({
        active: isSlide2 ? 0 : 1
      })

      if (next > 1) {
        next = 0;
      }
      $("#part5 .slide").eq(index).addClass("active").removeClass("prev").removeClass("next");
      $("#part5 .slide").eq(next).addClass("next").removeClass("prev").removeClass("active");
    });
  }

  render() {
    const {items, active} = this.state
    return <div className='flex-row-c'>
      <div style={{width: '20%'}}>
        <div className='line_ora'/>
        <br/>
        <br/>
        <p align="justify">{items[active].intro}</p>
      </div>
      <div id='part5'>
        <div className="switchover">
          <div className="slide slide2 active"></div>
          <div className="slide slide3 next"></div>
        </div>
        <p align="center">{items[active].title}</p>
      </div>
    </div>
  }

}

const Product634 = () => (<div className='bg_5'>
  <h2>零件清单</h2>

  <img src='images/61004/img_3.png'/>
</div>)


const Product635 = () => (<div className='bg_6 flex-row-c'>
  <div style={{width: '20%'}}>
    <div className="ailen-dwon" style={{backgroundColor: '#fff'}}>
      <dl>
        <dt><img src="images/61004/notebook.png"/></dt>
        <dd>使用说明<span>61004扩展包使用说明</span></dd>
      </dl>
      <div className="ailen-dwon-btn">
        <button className="ailen-btn-or"
                onClick={() => {
                  window.location.href = '//asset.bellrobot.com/thunder/document/pdf/61004使用说明.pdf'
                }}>下载
        </button>
      </div>
    </div>
  </div>
  <div style={{width: '20%', marginLeft: 30}}>
    <div className="ailen-dwon" style={{backgroundColor: '#fff'}}>
      <dl>
        <dt><img src="uploads/img/ailenicon_01.png"/></dt>
        <dd>搭建指南<span>61004扩展包官方搭建指南</span></dd>
      </dl>
      <div className="ailen-dwon-btn">
        <button className="ailen-btn-or"
                onClick={() => {
                  window.location.href = '//asset.bellrobot.com/thunder/document/pdf/61004搭建指南.pdf'
                }}
        >下载
        </button>
      </div>
    </div>
  </div>
  {/*<div className='col-sm-3 col-xs-6'>*/}
  {/*<div className="ailen-dwon" style={{backgroundColor:'#fff'}}>*/}
  {/*<dl>*/}
  {/*<dt><img src="images/61004/ide.png"/></dt>*/}
  {/*<dd>示例程序<span>61004扩展包示例程序</span></dd>*/}
  {/*</dl>*/}
  {/*<div className="ailen-dwon-btn">*/}
  {/*<button className="ailen-btn-or"*/}
  {/*>下载*/}
  {/*</button>*/}
  {/*</div>*/}
  {/*</div>*/}
  {/*</div>*/}
  {/*<div className='col-sm-3 col-xs-6'>*/}
  {/*<div className="ailen-dwon" style={{backgroundColor:'#fff'}}>*/}
  {/*<dl>*/}
  {/*<dt><img src="images/61004/folder.png"/></dt>*/}
  {/*<dd>教学示例<span>61004扩展包示例教学/PPT/视频等</span></dd>*/}
  {/*</dl>*/}
  {/*<div className="ailen-dwon-btn">*/}
  {/*<button className="ailen-btn-or"*/}
  {/*>下载*/}
  {/*</button>*/}
  {/*</div>*/}
  {/*</div>*/}
  {/*</div>*/}

</div>)


export {Product631, Product632, Product633, Product634, Product635}
