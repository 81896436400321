export function sendEmail(sub, body) {
  window.Email.send({
    SecureToken: "998efc9f-f524-45de-b8b0-3b16d1eaac3e",
    To: "shaosl@bell.ai",
    From: "thunder@bell.ai",
    Subject: sub,
    Body: body,
  }).then((message) => {
    console.log(message);
  });
}
