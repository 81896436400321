// Actio Type
const ADD_PARTS = "add_parts";
const SUBSTRACT_PARTS = "substract_parts";
const CLEAR_PARTS = "clear_parts";
const RESET_PARTS = "reset_parts";

const initialState = JSON.parse(localStorage.getItem("PARTS")) || {
  selectedParts: {},
  totalCount: 0,
};

//reducer 可以接受state但是不可以修改state 只有store才能改变
//纯函数指得是，制定固定的输入，就一定会有固定的输出，而且不会有任何副作用
const reducer = (state = initialState, action) => {
  let newState = state;
  switch (action.type) {
    case ADD_PARTS:
      newState = {
        totalCount: state.totalCount + action.addPart.minCount,
        selectedParts: {
          ...state.selectedParts,
          [action.addPart.id]: action.addPart,
        },
      };
      break;
    case SUBSTRACT_PARTS:
      const { parts, minCount } = action;
      const totalCount = state.totalCount - minCount;
      newState = {
        totalCount: totalCount < 0 ? 0 : totalCount,
        selectedParts: parts,
      };
      break;
    case CLEAR_PARTS:
      newState = {
        totalCount: 0,
        selectedParts: {},
      };
      break;
    case RESET_PARTS:
      newState = action.resetParts;
      break;
    default:
      break;
  }
  localStorage.setItem("PARTS", JSON.stringify(newState));
  return newState;
};

/****
 * action
 */
const addParts = (addPart) => {
  return {
    type: ADD_PARTS,
    addPart,
  };
};

const substractParts = (parts, minCount) => {
  return {
    type: SUBSTRACT_PARTS,
    parts,
    minCount,
  };
};

const clearParts = () => {
  return {
    type: CLEAR_PARTS,
  };
};

const resetParts = (resetParts) => {
  return {
    type: RESET_PARTS,
    resetParts,
  };
};

export {
  reducer as default,
  initialState as defaultParts,
  addParts,
  substractParts,
  clearParts,
  resetParts
};
