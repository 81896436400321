import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Router from "./config/Router";
import "antd/dist/antd.css";
// import './config/remCalc'
import { Provider } from "react-redux";
import store from "./redux/store";

import allsettled from "promise.allsettled";
if (!Promise.allSettled) Promise.allSettled = allsettled;

const smtp = document.createElement("script");
smtp.src = "https://smtpjs.com/v3/smtp.js";
document.head.appendChild(smtp);

ReactDOM.render(
  <Provider store={store}>
    <Router />
  </Provider>,
  document.getElementById("root")
);
