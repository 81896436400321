import React, { Component } from "react";
import "./race.css";
import DialogContainer from "../dog/DialogContainer";
const styles = {
  title: {
    color: "#2A2A2A",
    fontSize: 36,
  },
  text: {
    color: "#2A2A2A",
    fontSize: 24,
  },
  titleW: {
    color: "#fff",
    fontSize: 36,
  },
  textW: {
    color: "#fff",
    fontSize: 24,
  },
  btnGo: {
    height: 65,
    width: 207,
    background: `url(images/place/btn_place.png)`,
    backgroundSize: "100% 100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFF",
    fontSize: 18,
  },
};

export default class Race extends Component {
  render() {
    return (
      <div>
        <div
          style={{
            background: "url(images/place/header1.png)",
            backgroundSize: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 140,
            paddingBottom: 120,
          }}
        >
          <p style={{ fontSize: 60, color: "#fff" }}>在世界舞台上熠熠生辉</p>
          <div
            style={{
              height: 1,
              width: 530,
              background: "#fff",
              marginBottom: 30,
              marginTop: 15,
            }}
          />
          <p style={{ ...styles.textW, textAlign: "justify", width: "50%" }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;雷霆系列产品为RA国际机器人大赛、世界机器人大赛（WRC）等国际主流赛事和各种机器人主题活动提供器材支撑，使学生在活动中将多学科知识运用于实践，学习策略分工，感受团结协作的力量。
          </p>
          <p style={{ ...styles.textW, textAlign: "justify", width: "50%" }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;通过比赛不断强化和验证学习成果，充分展现⼴⼤⻘少年科技思想和能力，促进其学习各学科知识，培养⼯程素养，沟通表达、团队合作、逻辑思维及规划策划等能⼒，为国家培养优秀的⼈⼯智能科技⼈才储备力量。
          </p>
        </div>
        <div
          className="parter"
          style={{
            background: "url(images/place/bg_race2.png)",
            backgroundSize: "100% 100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p style={{ ...styles.titleW, marginTop: 100 }}>RA国际机器人大赛</p>
          <p
            style={{
              ...styles.textW,
              marginTop: 80,
              marginBottom: 60,
              paddingLeft: 100,
              paddingRight: 100,
            }}
          >
            RA国际机器人大赛隶属于RA机器人联盟，是面向全球4-16岁青少年机器人爱好者打造的国际级机器人IP竞技平台
          </p>

          <RaceVideo />

          <a
            target="_blank"
            href="https://roboa.org"
            className="btn"
            style={{
              width: 427,
              height: 65,
              borderRadius: 33,
              background: "#FF9C00",
              fontSize: 20,
              color: "#FFF",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 80,
              marginBottom: 100,
            }}
          >
            前往了解更多RA国际机器人大赛&gt;&gt;
          </a>
        </div>
        <RaceSwiper />
        <div
          style={{
            background: "#000000",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
          }}
        >
          <p style={{ ...styles.titleW, marginTop: 70}}>赛事 器材支撑</p>
          <div style={{ width: "50%", position: "relative",marginBottom:100 }}>
            <a
              href="62007"
              className="btn"
              style={{
                ...styles.btnGo,
                position: "absolute",
                right: "7%",
                top: "40%",
              }}
            >
              前往了解更多
            </a>
            <a
              href="85001"
              className="btn"
              style={{
                ...styles.btnGo,
                position: "absolute",
                right: "7%",
                top: "82%",
              }}
            >
              前往了解更多
            </a>
            <img src="/images/place/img7.png" />
          </div>

          <div style={{ width: "50%", position: "relative",marginBottom:100 }}>
            <a
              href="62004"
              className="btn"
              style={{
                ...styles.btnGo,
                position: "absolute",
                right: "7%",
                top: "40%",
              }}
            >
              前往了解更多
            </a>
            <a
              href="85003"
              className="btn"
              style={{
                ...styles.btnGo,
                position: "absolute",
                right: "7%",
                top: "82%",
              }}
            >
              前往了解更多
            </a>
            <img src="/images/place/62004-85003-race.png" />
          </div>
         
          <div style={{ width: "50%", position: "relative",marginBottom:100 }}>
            <a
              href="62005"
              className="btn"
              style={{
                ...styles.btnGo,
                position: "absolute",
                right: "7%",
                top: "40%",
              }}
            >
              前往了解更多
            </a>
            <a
              href="85004"
              className="btn"
              style={{
                ...styles.btnGo,
                position: "absolute",
                right: "7%",
                top: "82%",
              }}
            >
              前往了解更多
            </a>
            <img src="/images/place/62005-85004-race.png" />
          </div>

        </div>
      </div>
    );
  }
}

class RaceVideo extends React.Component {
  state = {
    visible: false,
  };

  close = () => {
    this.setState({
      visible: false,
    });
  };
  open = () => {
    this.setState({
      visible: true,
    });
  };

  render() {
    const { visible } = this.state;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <img style={{ width: "80%" }} src="/images/place/img6.png" />
        <div
          onClick={this.open}
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            marginTop: -62,
            marginLeft: -62,
          }}
          className="btn-h"
        >
          <img style={{ width: 124 }} src={"/images/bat/bat_play.png"}></img>
        </div>
        <DialogContainer visible={visible} onDissmiss={this.close}>
          {visible ? (
            <video
              autoPlay
              controls="controls"
              style={{ width: 900 }}
              src="//asset.bellrobot.com/thunder/document/video/RA国际机器人大赛.mp4"
            >
              your browser does not support the video tag
            </video>
          ) : null}
        </DialogContainer>
      </div>
    );
  }
}

class RaceSwiper extends Component {
  componentDidMount() {
    const swiper = new window.Swiper(".race-container", {
      loop: true,
      speed: 600,
      grabCursor: true,
      parallax: true,
      autoplay: {
        delay: 3000,
      },
      pagination: {
        el: "#bannerpagination",
        clickable: true,
      },
    });
  }

  render() {
    return (
      <div
        id="banner"
        style={{
          textAlign: "center",
          position: "relative",
          overflow: "hidden",
          backgroundSize: "100% 100%",
          background: "url(images/place/bg_race1.png)",
        }}
      >
        <div class="race-container" style={{ paddingTop: 50 }}>
          <div class="swiper-wrapper">
            <div class="swiper-slide" style={{ position: "relative" }}>
              <a
                href="https://roboa.org/signup_now"
                target="_blank"
                className="btn"
                style={{
                  height: 45,
                  width: 180,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#FF9C00",
                  position: "absolute",
                  top: "68%",
                  left: "27%",
                  color: "#FFF",
                  fontSize: 18,
                }}
              >
                前往了解更多
              </a>
              <img style={{ width: "70%" }} src="/images/place/race2.png" />
            </div>
          
            <div class="swiper-slide">
              <img style={{ width: "70%" }} src="/images/place/race3.png" />
            </div>
            <div class="swiper-slide">
              <img style={{ width: "70%" }} src="/images/place/race4.png" />
            </div>
          </div>
          <div
            style={{ position: "absolute", bottom: 40, zIndex: 999 }}
            id="bannerpagination"
            class="pagination swiper-pagination-clickable swiper-pagination-bullets"
          >
            <span
              class="swiper-pagination-bullet"
              tabindex="0"
              role="button"
              aria-label="Go to slide 1"
            ></span>
            <span
              class="swiper-pagination-bullet"
              tabindex="0"
              role="button"
              aria-label="Go to slide 2"
            ></span>
           
          </div>
        </div>
        <div className="partC" style={{ alignItems: "center" }}>
          <p style={{ ...styles.titleW, position: "absolute", marginTop: 50 }}>
            赛事 如火如荼
          </p>
        </div>
      </div>
    );
  }
}
