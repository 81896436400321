/**
 *作者：lorne
 *时间：2019/9/10
 *功能：
 */
import React, {Component,Fragment} from 'react'

export default class Base extends Component{

    componentDidMount(){
        document.title = this.props.title || '首页'
        this.componentReady()
    }

    componentReady(){

    }

    renderPage(){
        return null
    }

    render(){
        return <Fragment>
            {this.renderPage()}
        </Fragment>
    }

}
