/**
 *作者：lorne
 *时间：2019/9/17
 *功能：
 */
import React,{Component} from 'react'
import '../../css/productpage.css'

export default class ProductPage extends Component{

  componentDidMount(){
    let $ = window.$
    document.title = '产品页面'

  }


  render(){
     return (<div className='ProductPage'>
       <div className="modal fade" id="myModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
         <div className="modal-dialog" role="document">
           <div className="modal-content">
             <div className="modal-body">
               <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                 aria-hidden="true">&times;</span></button>
               <img src="uploads/img/0808方梁类/套件展示案例01.png" alt=""/>
             </div>
           </div>
         </div>
       </div>

       <div className="detail-nav">
         <div className="container">
           <p>STEAM教育套件、灵活拓展包、创客零部件</p>
           <p>不同搭配组合，提供多种选择，满足每个人的无限可能</p>
         </div>
       </div>

       <div className="education-suit-container">
         <a id="steam-suite" name="steam-suite"></a>
         <p><img src="uploads/img/product-suit-icon.png" alt=""/>STEAM教育套件</p>
         <a className="product-suite" href="product">
           <p className="title">83001</p>
           <p className="subtitle">7合1科幻工程车套件</p>
           <p className="content">7种官方形态/14种炫酷构型/指定角度控制/丰富音频表情资源图形化编程/APP操控/配套教程资源</p>
           <p className="btn ">了解更多</p>
         </a>
         {/* <a className="product-suite" href="productdetail">
           <p className="title">83002</p>
           <p className="subtitle">简易小车</p>
           <p className="content">图形化编程/简易搭建/强劲电机/丰富扩展口/百变拓展包</p>
           <p className="btn ">了解更多</p>
         </a>
         <a className="product-suite" href="aliendetail">
           <p className="title">83003</p>
           <p className="subtitle">4合1外星球超级战车套件</p>
           <p className="content">4种官方形态/8种炫酷构型/多种有趣玩法/图形化编程<br/>APP操控/集成多种传感器</p>
           <p className="btn ">了解更多</p>
         </a> */}
         <a className="product-suite" href="83004">
           <p className="title">83004</p>
           <p className="subtitle">模块化声光互动机器人</p>
           <p className="content">易搭建/功能模块化/易于教学互动/图形化编程/蓝牙遥控/拓展性强</p>
           <p className="btn ">了解更多</p>
         </a>
       </div>


       <div className="education-expand-container">
         <a id="expand" name="expand"></a>
         <p><img src="uploads/img/product-expand-icon.png" alt=""/>拓展包及配件</p>
         <div className='pb-part6'>
           <div
             onClick={()=>{
               window.$("#myModal img").attr("src",'uploads/img/suite-04-show.png');
             }}
             className="parts-content" data-toggle="modal" data-target="#myModal">
             <div className="flex1 parts-img-content1">

             </div>
             <div className="flex1">
             <p className="title">61001拓展包</p>
             <a className="btn ">了解更多</a>
             </div>
           </div>
           <div style={{width:'2%'}}/>
           <div
             onClick={()=>{
               window.$("#myModal img").attr("src",'uploads/img/suite-05-show.png');
             }}
             className="parts-content" data-toggle="modal" data-target="#myModal">
             <div className="flex1 parts-img-content2">

             </div>
             <div className="flex1">
             <p className="title">61002拓展包</p>
             <a className="btn ">了解更多</a>
             </div>
           </div>
         </div>
         <div className='pb-part6' style={{marginTop:30}}>
           <a className="parts-content" href="61004">
             <div className="flex1 parts-img-content3">

             </div>
             <div className="flex1">
               <p className="title">61004拓展包</p>
               <a className="btn ">了解更多</a>
             </div>
           </a>
           <div style={{width:'2%'}}/>
           <a className="parts-content" href="tc001">
             <div className="flex1 parts-img-content4">

             </div>
             <div className="flex1">
               <p className="title">TC001蓝牙遥控手柄</p>
               <a className="btn ">了解更多</a>
             </div>
           </a>
         </div>
       </div>

       <div className="education-parts-container" style={{marginTop:50,paddingBottom:100}}>
         <a id="spare-parts" name="spare-parts"></a>
         <p><img src="uploads/img/product-parts-icon.png" alt=""/>创客零部件</p>
         <div className='pb-part6'>
           <a className="parts-content"
              id="part-001" href="partsshow#part-001" target="_blank">
             <div className="flex1 parts-img-content5">

             </div>
             <div className="flex1">
               <p className="title">机械零件</p>
               <p className="content">0808方梁 | 0816方梁 | 孔梁类 | 轴类 | 片材类 <br/>外观装饰类 | 功能类 | 紧固件类 | 齿轮类</p>
               <p className="btn " target="view_window">了解更多</p>
             </div>
           </a>
           <div style={{width:'2%'}}/>
           <a className="parts-content"
              id="part-002" href="partsshow#part-002" target="_blank">
             <div className="flex1 parts-img-content6">

             </div>
             <div className="flex1">
               <p className="title">电子零件</p>
               <p className="content">传感器类 | 显示器类 | 动力类 | 主控类 | 线材类</p>
               <p className="btn " target="view_window">了解更多</p>
             </div>

           </a>
         </div>

       </div>

     </div>)
  }
}
