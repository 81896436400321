import React, { Component } from "react";
import PartList from "./List";
import "./parts.scss";
import SelectBox from "./TreeSelect";
import ShopCart from "./ShopCart";

export default class Parts extends Component {
  render() {
    return (
      <div className="parts_container">
        <div className="parts-banner">
          <div className="parts-banner-text">创意不设限，做你所想!</div>
          <img src="images/parts/banner.png" />
        </div>
        <div className="content">
          <SelectBox />
          <PartList />
          <ShopCart />
        </div>
      </div>
    );
  }
}
